define("shared/components/cluster-template-row/component", ["exports", "shared/components/cluster-template-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'tr',
    classNameBindings: ['isGroup:group-row:main-row'],
    isGroup: false
  });

  _exports.default = _default;
});