define("shared/components/schema/input-storageclass/component", ["exports", "shared/components/schema/input-storageclass/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    allStorageClasses: Ember.inject.service(),
    layout: _template.default,
    field: null,
    value: null,
    selected: null,
    disabled: false,
    storageClassesOptions: Ember.computed.alias('allStorageClasses.list'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var def = Ember.get(this, 'value') || Ember.get(this, 'field.default');

      if (def && !Ember.get(this, 'selected')) {
        var exact = Ember.get(this, 'storageClassesOptions').findBy('id', def);
        Ember.run.next(function () {
          if (exact) {
            Ember.set(_this, 'selected', Ember.get(exact, 'id') || null);
          } else {
            Ember.set(_this, 'selected', null);
          }
        });
      }
    },
    selectedChanged: Ember.observer('selected', function () {
      var id = Ember.get(this, 'selected');
      var str = null;

      if (id) {
        var storageClass = Ember.get(this, 'storageClassesOptions').findBy('id', id);

        if (storageClass) {
          str = Ember.get(storageClass, 'id');
        }
      }

      Ember.set(this, 'value', str);
    })
  });

  _exports.default = _default;
});