define("shared/components/authorized-endpoint/component", ["exports", "shared/components/authorized-endpoint/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,
    cluster: null,
    clusterTemplateRevision: null,
    enableLocalClusterAuthEndpoint: false,
    clusterTemplateCreate: false,
    applyClusterTemplate: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.initClusterAuthEndpoint();
    },
    enableClusterAuthEndpointChanged: Ember.observer('enableLocalClusterAuthEndpoint', function () {
      var cluster = this.cluster,
          enableLocalClusterAuthEndpoint = this.enableLocalClusterAuthEndpoint;

      if (enableLocalClusterAuthEndpoint) {
        if (cluster.localClusterAuthEndpoint) {
          Ember.set(cluster, 'localClusterAuthEndpoint.enabled', true);
        } else {
          this.createLocalClusterAuthEndpoint();
        }
      } else {
        Ember.set(cluster, 'localClusterAuthEndpoint.enabled', false);
      }
    }),
    enableLocalClusterAuthEndpointAvailable: Ember.computed('enableLocalClusterAuthEndpoint', 'clusterTemplateRevision.questions.[]', function () {
      var clusterTemplateRevision = this.clusterTemplateRevision;

      var _ref = clusterTemplateRevision ? clusterTemplateRevision : {},
          _ref$questions = _ref.questions,
          questions = _ref$questions === void 0 ? [] : _ref$questions;

      var match = questions.find(function (question) {
        if ((question.variable || '').includes('localClusterAuthEndpoint')) {
          return question;
        }

        return false;
      });
      return match && match.length > 0;
    }),
    initClusterAuthEndpoint: function initClusterAuthEndpoint() {
      var cluster = this.cluster;

      if (cluster.localClusterAuthEndpoint && typeof cluster.localClusterAuthEndpoint.enabled === 'string' && cluster.localClusterAuthEndpoint.enabled === 'true' || typeof cluster.localClusterAuthEndpoint.enabled === 'boolean' && cluster.localClusterAuthEndpoint.enabled) {
        Ember.set(this, 'enableLocalClusterAuthEndpoint', true);
      } else {
        Ember.set(this, 'enableLocalClusterAuthEndpoint', false);
      }
    },
    createLocalClusterAuthEndpoint: function createLocalClusterAuthEndpoint() {
      var globalStore = this.globalStore;
      var lcae = globalStore.createRecord({
        type: 'localClusterAuthEndpoint',
        enabled: true
      });
      Ember.set(this, 'cluster.localClusterAuthEndpoint', lcae);
    }
  });

  _exports.default = _default;
});