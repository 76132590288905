define("shared/utils/parse-label", ["exports", "@rancher/ember-api-store/utils/validate"], function (_exports, _validate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseKey = parseKey;
  _exports.validateIdentifier = validateIdentifier;
  _exports.validatePrefix = validatePrefix;
  _exports.validateKey = validateKey;
  _exports.validateValue = validateValue;

  function parseKey(str) {
    str = str || '';
    var idx = str.indexOf('/');

    if (idx > 0) {
      var prefix = str.substr(0, idx);
      var key = str.substr(idx + 1);
      return {
        str: str,
        prefix: prefix,
        key: key
      };
    } else {
      return {
        str: str,
        prefix: null,
        key: str
      };
    }
  }

  var MIDDLE_ONLY = ['_', '.', '-'];

  function validateIdentifier(str, displayKey, intl) {
    var errors = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    (0, _validate.validateChars)(str, {
      validChars: 'A-Za-z0-9_.-'
    }, displayKey, intl, errors); // Indentifier cannot begin with a hyphen

    var chr = str.slice(0, 1);

    if (MIDDLE_ONLY.includes(chr)) {
      errors.push(intl.t("validation.k8s.identifier.startLetter", {
        key: displayKey
      }));
    } // Label cannot end with a hyphen


    chr = str.slice(-1);

    if (MIDDLE_ONLY.includes(chr)) {
      errors.push(intl.t("validation.k8s.identifier.endLetter", {
        key: displayKey
      }));
    } // Label must be 1-63 characters


    var min = 1;
    var max = 63;

    if (str.length < min) {
      errors.push(intl.t("validation.k8s.identifier.emptyKey", {
        key: displayKey,
        min: min
      }));
    } else if (str.length > max) {
      errors.push(intl.t("validation.k8s.identifier}.tooLongKey", {
        key: displayKey,
        max: max
      }));
    }
  }

  function validatePrefix(str, displayKey, intl) {
    var errors = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    var intlKey = intl.t('generic.key');
    var min = 1;
    var max = 253;

    if (str.length < min) {
      errors.push(intl.t("validation.k8s.identifier.emptyPrefix", {
        key: displayKey,
        min: min
      }));
    } else if (str.length > max) {
      errors.push(intl.t("validation.k8s.identifier}.tooLongPRefix", {
        key: displayKey,
        max: max
      }));
    } else {
      (0, _validate.validateHostname)(str, intlKey, intl, {
        restricted: false
      }, errors);
    }
  }

  function validateKey(str, intl) {
    var errors = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    var parts = parseKey(str);
    var intlKey = intl.t('generic.key');

    if (parts.prefix) {
      validatePrefix(parts.prefix, intlKey, intl, errors);
    }

    validateIdentifier(parts.key, intlKey, intl, errors);
  }

  function validateValue(str, intl) {
    var errors = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    var intlKey = intl.t('generic.value');
    validateIdentifier(str, intlKey, intl, errors);
  }
});