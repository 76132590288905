define("shared/utils/parse-port", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parsePortSpec = parsePortSpec;
  _exports.portToInt = portToInt;
  _exports.default = void 0;

  // hostIp:hostPort:containerPort
  // hostIp::containerPort
  // hostPort:containerPort
  // containerPort
  function parsePortSpec(str) {
    var defaultProtocol = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'tcp';
    str = str.trim();
    var match = str.match(/\/([a-z]+)$/i),
        parts,
        hostIp = '',
        hostPort,
        containerPort,
        protocol; // Protocol

    if (match) {
      protocol = match[1].toLowerCase();
      str = str.substr(0, str.length - match[0].length);
    } else {
      protocol = defaultProtocol;
    } // IPv6


    if (str.indexOf('[') >= 0 && (match = str.match(/^(\[[^]+\]):/))) {
      parts = str.substr(match[0].length).split(':');
      parts.unshift(match[1]);
    } else {
      parts = str.split(':');
    }

    if (parts.length >= 3) {
      hostIp = parts[0];
      hostPort = parts[1];
      containerPort = parts[2];
    } else if (parts.length === 2) {
      hostIp = null;
      hostPort = parts[0];
      containerPort = parts[1];
    } else {
      hostIp = null;
      hostPort = '';
      containerPort = parts[0];
    }

    return Ember.Object.create({
      host: (hostIp ? "".concat(hostIp, ":") : '') + hostPort,
      hostIp: hostIp,
      hostPort: parseInt(hostPort, 10) || null,
      container: parseInt(containerPort, 10) || null,
      protocol: protocol
    });
  }

  function portToInt(str) {
    str = "".concat(str).trim();

    if (str.match(/^\d+$/)) {
      return parseInt(str, 10) || null;
    }

    return null;
  }

  var _default = {
    parsePortSpec: parsePortSpec
  };
  _exports.default = _default;
});