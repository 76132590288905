define("global-admin/clusters/new/select/controller", ["exports", "shared/utils/load-script"], function (_exports, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    needReloadSchema: false,
    reloadingSchema: false,
    schemaReloaded: false,
    disabledAddCluster: false,
    clusterTemplateRevisions: Ember.computed.alias('model.clusterTemplateRevisions'),
    reloadSchema: Ember.observer('needReloadSchema', function () {
      var _this = this;

      if (!this.reloadingSchema && this.needReloadSchema) {
        Ember.set(this, 'reloadingSchema', true);
        this.globalStore.findAll('schema', {
          url: '/v3/schemas',
          forceReload: true
        }).then(function () {
          Ember.setProperties(_this, {
            schemaReloaded: true,
            reloadingSchema: false
          });
        });
      }
    }),
    kontainerDrivers: Ember.computed('model.kontainerDrivers.@each.{id,state}', function () {
      var chinaCloud = ['tencentkubernetesengine', 'aliyunkubernetescontainerservice', 'huaweicontainercloudengine'];
      var nope = ['import', 'rancherkubernetesengine'];
      var kDrivers = Ember.get(this, 'model.kontainerDrivers') || [];
      var builtIn = kDrivers.filter(function (d) {
        return d.state === 'active' && (d.builtIn || chinaCloud.indexOf(d.id) > -1) && !nope.includes(d.id);
      });
      var custom = kDrivers.filter(function (d) {
        return d.state === 'active' && !d.builtIn && d.hasUi;
      });
      return {
        builtIn: builtIn,
        custom: custom
      };
    }),
    providerChoices: Ember.computed('model.nodeDrivers.{id,state}', 'schemaReloaded', 'intl.locale', 'kontainerDrivers.[]', function () {
      var _this2 = this;

      var kontainerDrivers = this.kontainerDrivers,
          intl = this.intl;
      var builtIn = kontainerDrivers.builtIn,
          custom = kontainerDrivers.custom;
      var out = [{
        name: 'googlegke',
        driver: 'googlegke',
        kontainerId: 'googlekubernetesengine'
      }, {
        name: 'amazoneks',
        driver: 'amazoneks',
        kontainerId: 'amazonelasticcontainerservice'
      }, {
        name: 'azureaks',
        driver: 'azureaks',
        kontainerId: 'azurekubernetesservice'
      }, {
        name: 'tencenttke',
        driver: 'tencenttke',
        kontainerId: 'tencentkubernetesengine'
      }, {
        name: 'aliyunkcs',
        driver: 'aliyunkcs',
        kontainerId: 'aliyunkubernetescontainerservice'
      }, {
        name: 'huaweicce',
        driver: 'huaweicce',
        kontainerId: 'huaweicontainercloudengine'
      }];
      out = out.filter(function (o) {
        return builtIn.findBy('id', o.kontainerId);
      });

      if (custom.length > 0) {
        custom.forEach(function (c) {
          var name = c.name;
          var configName = "".concat(name, "EngineConfig");

          var driverEngineSchema = _this2.globalStore.getById('schema', configName.toLowerCase());

          if (driverEngineSchema) {
            var displayName = c.displayName,
                driver = c.name,
                kontainerId = c.id,
                _name = c.name,
                _c$genericIcon = c.genericIcon,
                genericIcon = _c$genericIcon === void 0 ? true : _c$genericIcon;
            out.pushObject({
              displayName: displayName,
              driver: driver,
              kontainerId: kontainerId,
              name: _name,
              genericIcon: genericIcon
            });
          } else {
            Ember.set(_this2, 'needReloadSchema', true);
          }
        });
      }

      Ember.get(this, 'model.nodeDrivers').filterBy('state', 'active').sortBy('name').forEach(function (driver) {
        var name = driver.name,
            hasUi = driver.hasUi,
            hasIcon = driver.hasBuiltinIconOnly,
            uiUrl = driver.uiUrl;
        var configName = "".concat(name, "Config");

        var driverSchema = _this2.globalStore.getById('schema', configName.toLowerCase());

        if (uiUrl) {
          var cssUrl = (0, _loadScript.proxifyUrl)(uiUrl.replace(/\.js$/, '.css'), Ember.get(_this2, 'app.proxyEndpoint'));
          (0, _loadScript.loadStylesheet)(cssUrl, "driver-ui-css-".concat(name));
        }

        if (driverSchema) {
          out.push({
            name: name,
            driver: 'rke',
            genericIcon: !hasUi && !hasIcon,
            nodeComponent: hasUi ? name : 'generic',
            nodeWhich: name
          });
        } else {
          Ember.set(_this2, 'needReloadSchema', true);
        }
      }), out.push({
        name: 'custom',
        driver: 'rke',
        nodeWhich: 'custom',
        preSave: true
      });
      out.push({
        name: 'import',
        driver: 'import',
        preSave: true
      });
      out.forEach(function (driver) {
        var key = "clusterNew.".concat(driver.name, ".label");

        if (!Ember.get(driver, 'displayName') && intl.exists(key)) {
          Ember.set(driver, 'displayName', intl.t(key));
        }
      });
      out.sortBy('name');
      return out;
    }),
    providerGroups: Ember.computed('providerChoices.@each.{name,driver,nodeComponent,nodeWhich,preSave}', function () {
      var choices = Ember.get(this, 'providerChoices');
      var rkeGroup = [];
      var cloudGroup = [];
      var customGroup = [];
      var importGroup = [];
      choices.forEach(function (item) {
        if (Ember.get(item, 'driver') === 'rke' && Ember.get(item, 'name') !== 'custom') {
          rkeGroup.pushObject(item);
        } else if (Ember.get(item, 'driver') === 'import' && Ember.get(item, 'name') !== 'custom') {
          importGroup.pushObject(item);
        } else if (Ember.get(item, 'name') === 'custom') {
          customGroup.pushObject(item);
        } else {
          cloudGroup.pushObject(item);
        }
      });
      return {
        cloudGroup: cloudGroup.sortBy('name'),
        customGroup: customGroup.sortBy('name'),
        importGroup: importGroup.sortBy('name'),
        rkeGroup: rkeGroup.sortBy('name')
      };
    })
  });

  _exports.default = _default;
});