define("shared/mixins/endpoint-ports", ["exports", "ui/utils/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    displayEndpoints: function () {
      var parts = [];
      var endpoints = (Ember.get(this, 'publicEndpoints') || []).sort(_util.default.compareDisplayEndpoint);
      endpoints.forEach(function (endpoint) {
        if (!Ember.get(endpoint, 'isReady')) {
          return;
        }

        if (Ember.get(endpoint, 'isTcpish')) {
          parts.push("".concat('<span>' + '<a target="_blank" rel="noreferrer nofollow noopener" href="').concat(_util.default.escapeHtml(Ember.get(endpoint, 'linkEndpoint')), "\">").concat(_util.default.escapeHtml(Ember.get(endpoint, 'displayEndpoint')), "</a>") + "</span>");
        } else {
          parts.push("<span>".concat(_util.default.escapeHtml(Ember.get(endpoint, 'displayEndpoint')), "</span>"));
        }
      });
      var pub = parts.join(', ');

      if (pub) {
        return pub.htmlSafe();
      } else {
        return '';
      }
    }.property('publicEndpoints.@each.{address,port,protocol}')
  });

  _exports.default = _default;
});