define("shared/components/input-answer-row/component", ["exports", "shared/components/input-answer-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    question: null,
    answer: null,
    namespaceId: '',
    layout: _template.default,
    tagName: 'div',
    namespace: Ember.computed('namespaceId', function () {
      return {
        id: Ember.get(this, 'namespaceId')
      };
    })
  });

  _exports.default = _default;
});