define("shared/components/form-count/component", ["exports", "shared/components/form-count/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function newMax(val, curMax, absoluteMax) {
    return Math.min(absoluteMax, Math.max(curMax, Math.ceil(val / 10) * 10));
  }

  var _default = Ember.Component.extend({
    layout: _template.default,
    initialScale: null,
    min: 1,
    max: 100,
    userInput: null,
    sliderMax: 10,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'userInput', "".concat(Ember.get(this, 'initialScale') || 1));
      Ember.set(this, 'sliderMax', newMax(Ember.get(this, 'asInteger'), Ember.get(this, 'sliderMax'), Ember.get(this, 'max')));
    },
    actions: {
      increase: function increase() {
        Ember.set(this, 'userInput', Math.min(Ember.get(this, 'max'), Ember.get(this, 'asInteger') + 1));
      },
      decrease: function decrease() {
        Ember.set(this, 'userInput', Math.max(Ember.get(this, 'min'), Ember.get(this, 'asInteger') - 1));
      },
      showAdvanced: function showAdvanced() {
        Ember.set(this, 'advancedShown', true);
      }
    },
    scaleChanged: Ember.observer('asInteger', function () {
      var cur = Ember.get(this, 'asInteger');

      if (this.setScale) {
        this.setScale(cur);
      }

      Ember.set(this, 'sliderMax', newMax(cur, Ember.get(this, 'sliderMax'), Ember.get(this, 'max')));
    }),
    asInteger: Ember.computed('userInput', function () {
      return parseInt(Ember.get(this, 'userInput'), 10) || 0;
    })
  });

  _exports.default = _default;
});