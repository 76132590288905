define("shared/components/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1QIsqKc5",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"tooltip-element\",null,[[\"type\",\"model\",\"tooltipTemplate\",\"aria-describedby\",\"inlineBlock\"],[\"tooltip-basic\",[23,[\"tooltipContent\"]],\"tooltip-static\",\"tooltip-base\",false]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"progress\"],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[\"progress-bar \",[21,\"colorClass\"]]]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});