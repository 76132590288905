define("global-admin/components/form-global-roles-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ki0N4Ax4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"label\"],[11,\"class\",\"acc-label\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[23,[\"model\",\"active\"]]]]],false],[0,\" \"],[1,[27,\"t\",[[23,[\"model\",\"translationKey\"]]],null],false],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"help-block\"],[9],[1,[27,\"t\",[[23,[\"model\",\"descriptionTranslationKey\"]]],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/components/form-global-roles-row/template.hbs"
    }
  });

  _exports.default = _default;
});