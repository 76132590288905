define("shared/components/table-labels/component", ["exports", "shared/mixins/manage-labels", "shared/components/table-labels/template"], function (_exports, _manageLabels, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_manageLabels.default, {
    layout: _template.default,
    model: null,
    sortBy: 'kind',
    showKind: true,
    descending: true,
    search: true,
    labelIntlSec: 'labelsSection',
    headers: [{
      name: 'key',
      sort: ['key'],
      translationKey: 'labelsSection.key',
      width: '350'
    }, {
      name: 'value',
      sort: ['value', 'key'],
      translationKey: 'labelsSection.value'
    }],
    labelSource: Ember.computed.alias('model'),
    didReceiveAttrs: function didReceiveAttrs() {
      this.initLabels(this.get('labelSource'));
    },
    labelsObserver: Ember.observer('model', function () {
      this.initLabels(this.get('labelSource'));
    })
  });

  _exports.default = _default;
});