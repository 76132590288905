define("shared/components/hover-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RccvbklO",
    "block": "{\"symbols\":[\"dd\",\"&default\"],\"statements\":[[4,\"basic-dropdown\",null,[[\"renderInPlace\",\"verticalPosition\",\"horizontalPosition\",\"matchTriggerWidth\",\"triggerComponent\",\"contentComponent\",\"registerAPI\",\"calculatePosition\",\"onOpen\",\"onClose\",\"onFocus\"],[[23,[\"renderInPlace\"]],[23,[\"verticalPosition\"]],[23,[\"horizontalPosition\"]],[23,[\"matchTriggerWidth\"]],[23,[\"triggerComponent\"]],[23,[\"contentComponent\"]],[27,\"action\",[[22,0,[]],\"registerAPI\"],null],[27,\"action\",[[22,0,[]],\"calculatePosition\"],null],[23,[\"onOpen\"]],[23,[\"onClose\"]],[23,[\"onFocus\"]]]],{\"statements\":[[0,\"  \"],[14,2,[[27,\"assign\",[[22,1,[]],[27,\"hash\",null,[[\"trigger\",\"content\"],[[27,\"component\",[[22,1,[\"trigger\"]]],[[\"onMouseDown\",\"onMouseEnter\",\"onMouseLeave\"],[[27,\"action\",[[22,0,[]],\"prevent\"],null],[27,\"action\",[[22,0,[]],\"open\",false],null],[27,\"action\",[[22,0,[]],\"close\",false],null]]]],[27,\"component\",[[22,1,[\"content\"]]],[[\"onMouseEnter\",\"onMouseLeave\"],[[27,\"action\",[[22,0,[]],\"open\",true],null],[27,\"action\",[[22,0,[]],\"close\",true],null]]]]]]]],null]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/hover-dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});