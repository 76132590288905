define("shared/components/api-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C1bG6A66",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[11,\"class\",\"acc-label\"],[9],[0,\"\\n  \"],[1,[23,[\"fieldDef\",\"name\"]],false],[1,[27,\"if\",[[23,[\"fieldDef\",\"required\"]],\"*\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"specialField\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"schema/input-multiline\",null,[[\"classNames\",\"value\",\"placeholder\",\"rows\"],[\"form-control\",[23,[\"value\"]],[23,[\"placeholder\"]],1]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"fieldType\"]],\"boolean\"],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"input\",null,[[\"type\",\"checked\",\"classNames\"],[\"checkbox\",[23,[\"value\"]],\"m-10\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"fieldType\"]],\"float\"],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"classNames\",\"placeholder\",\"min\",\"max\"],[\"number\",[23,[\"value\"]],\"form-control\",[23,[\"placeholder\"]],[23,[\"fieldDef\",\"min\"]],[23,[\"fieldDef\",\"max\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"fieldType\"]],\"int\"],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"input-integer\",null,[[\"value\",\"classNames\",\"placeholder\",\"min\",\"max\"],[[23,[\"value\"]],\"form-control\",[23,[\"placeholder\"]],[23,[\"fieldDef\",\"min\"]],[23,[\"fieldDef\",\"max\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"classNames\",\"placeholder\"],[\"text\",[23,[\"value\"]],\"form-control\",[23,[\"fieldDef\",\"placeholder\"]]]]],false],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"text-info\"],[9],[0,\"\\n  \"],[1,[23,[\"fieldDef\",\"description\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/api-field/template.hbs"
    }
  });

  _exports.default = _default;
});