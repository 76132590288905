define("shared/helpers/to-json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params
    /* , options*/
    ) {
      return JSON.stringify(params[0], undefined, 2);
    }
  });

  _exports.default = _default;
});