define("shared/components/schema/input-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MOj6pQXO",
    "block": "{\"symbols\":[\"list\",\"group\",\"svc\"],\"statements\":[[7,\"select\"],[12,\"class\",[28,[[21,\"selectClass\"]]]],[12,\"onchange\",[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"selected\"]]],null]],[[\"value\"],[\"target.value\"]]]],[9],[0,\"\\n  \"],[7,\"option\"],[12,\"selected\",[27,\"eq\",[[23,[\"selected\"]],null],null]],[9],[1,[27,\"t\",[\"schema.inputContainer.prompt\"],null],false],[10],[0,\"\\n\"],[4,\"each\",[[27,\"-each-in\",[[23,[\"grouped\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"optgroup\"],[12,\"label\",[22,2,[]]],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[]]],null,{\"statements\":[[0,\"        \"],[7,\"option\"],[12,\"selected\",[27,\"eq\",[[23,[\"selected\"]],[22,3,[\"id\"]]],null]],[12,\"value\",[22,3,[\"id\"]]],[9],[1,[22,3,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/schema/input-container/template.hbs"
    }
  });

  _exports.default = _default;
});