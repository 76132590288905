define("shared/user-theme/service", ["exports", "shared/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    prefs: Ember.inject.service(),
    session: Ember.inject.service(),
    language: Ember.inject.service('user-language'),
    currentTheme: null,
    updateTimer: null,
    app: Ember.inject.service(),
    setupTheme: function setupTheme() {
      var userTheme = this.get("prefs.".concat(_constants.default.PREFS.THEME));
      var defaultTheme = this.get('session').get(_constants.default.PREFS.THEME);

      if (userTheme) {
        if (userTheme !== defaultTheme) {
          if (defaultTheme !== 'ui-auto') {
            this.setTheme(userTheme);
          } else {
            this.setAutoUpdate();
          }

          this.get('session').set(_constants.default.PREFS.THEME, userTheme);
        }
      } else {
        // no user pref'd theme
        this.set("prefs.".concat(_constants.default.PREFS.THEME), defaultTheme);
      }
    },
    setTheme: function setTheme(newTheme) {
      var save = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      if (save) {
        this.set("prefs.".concat(_constants.default.PREFS.THEME), newTheme);
      }

      if (this.get('updateTimer')) {
        Ember.run.cancel(this.get('updateTimer'));
      }

      if (newTheme === 'ui-auto') {
        this.setAutoUpdate();
      } else {
        this.set('currentTheme', newTheme);
        this.writeStyleNode();
        this.get('session').set(_constants.default.PREFS.THEME, newTheme);
      }
    },
    getTheme: function getTheme() {
      return this.get("prefs.".concat(_constants.default.PREFS.THEME));
    },
    setAutoUpdate: function setAutoUpdate() {
      var _this = this;

      var hour = new Date().getHours();
      var newTheme = 'ui-light';

      var nextHalfHour = _constants.default.THEME.AUTO_UPDATE_TIMER - Math.round(new Date().getTime()) % _constants.default.THEME.AUTO_UPDATE_TIMER;

      var userTheme = this.get('currentTheme');

      if (hour < _constants.default.THEME.START_HOUR || hour >= _constants.default.THEME.END_HOUR) {
        newTheme = 'ui-dark';
      }

      if (userTheme !== newTheme) {
        this.set('currentTheme', newTheme);
        this.writeStyleNode();
        this.get('session').set(_constants.default.PREFS.THEME, newTheme);
      }

      this.set('updateTimer', Ember.run.later(function () {
        return _this.setAutoUpdate();
      }, nextHalfHour));
    },
    writeStyleNode: function writeStyleNode() {
      var application = this.get('app');
      var $body = Ember.$('BODY');
      var theme = this.get('currentTheme');
      var lang = this.get("session.".concat(_constants.default.SESSION.LANGUAGE));
      var direction = '';
      var assets = application.get('baseAssets');
      var version = application.get('version');

      if (!theme || !lang) {
        return;
      }

      $body.attr('class').split(/\s+/).forEach(function (cls) {
        if (cls.indexOf('theme-') === 0) {
          $body.removeClass(cls);
        }
      });
      $body.addClass("theme-".concat(theme));

      if (this.get('language').isRtl(lang)) {
        direction = '.rtl';
      }

      updateHref('#theme', "".concat(assets, "assets/").concat(theme).concat(direction, ".css?").concat(version));
      updateHref('#vendor', "".concat(assets, "assets/vendor").concat(direction, ".css?").concat(version));

      function updateHref(node, neu) {
        var elem = Ember.$(node);
        var cur = elem.attr('href');

        if (cur !== neu) {
          elem.attr('href', neu);
        }
      }
    }
  });

  _exports.default = _default;
});