enifed("ember-template-compiler/lib/plugins/assert-reserved-named-arguments", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display"], function (_exports, _debug, _calculateLocationDisplay) {
  "use strict";

  _exports.default = assertReservedNamedArguments;
  const RESERVED = ['@arguments', '@args', '@block', '@else'];
  let isReserved, assertMessage;

  function assertReservedNamedArguments(env) {
    let {
      moduleName
    } = env.meta;
    return {
      name: 'assert-reserved-named-arguments',
      visitor: {
        PathExpression({
          original,
          loc
        }) {
          if (isReserved(original)) {
            true && !false && (0, _debug.assert)(`${assertMessage(original)} ${(0, _calculateLocationDisplay.default)(moduleName, loc)}`);
          }
        }

      }
    };
  }

  if (true
  /* EMBER_GLIMMER_NAMED_ARGUMENTS */
  ) {
      isReserved = name => RESERVED.indexOf(name) !== -1 || Boolean(name.match(/^@[^a-z]/));

      assertMessage = name => `'${name}' is reserved.`;
    } else {
    isReserved = name => name[0] === '@';

    assertMessage = name => `'${name}' is not a valid path.`;
  }
});