define("shared/mixins/volume-source", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    // Inputs from component caller
    volume: null,
    editing: null,
    sourceStore: null,
    // set to clusterStore for cluster volumes
    // From the volume plugin
    field: null,
    // the name of the field on the volume
    config: Ember.computed('field', function () {
      var volume = Ember.get(this, 'volume');
      var field = Ember.get(this, 'field');
      var config = Ember.get(volume, field);

      if (!config) {
        config = this.configForNew();
        Ember.set(volume, field, config);
      }

      return config;
    }),
    configForNew: function configForNew() {
      // Override to provide a custom empty config
      var store = Ember.get(this, 'sourceStore') || Ember.get(this, 'store');
      var index = Ember.get(this, 'volume.type').lastIndexOf('/') + 1;
      var voluemType = Ember.get(this, 'volume.type').substr(index).toLowerCase();
      var volumeSchema = store.getById('schema', voluemType);
      var type = Ember.get(volumeSchema, "resourceFields.".concat(Ember.get(this, 'field'), ".type")).toLowerCase();
      var config = store.createRecord({
        type: type
      });
      var schema = store.getById('schema', type);

      if (schema && schema.typeifyFields) {
        if ((schema.typeifyFields || []).indexOf('secretRef') > -1) {
          Ember.get(config, 'secretRef') || Ember.set(config, 'secretRef', {});
        }
      }

      if (Ember.get(this, 'initValue')) {
        Ember.setProperties(config, Ember.get(this, 'initValue'));
      }

      return config;
    }
  });

  _exports.default = _default;
});