define("shared/components/storage-class/provisioner-azure-disk/component", ["exports", "shared/components/storage-class/provisioner-azure-disk/template", "shared/mixins/storage-class-provisioner"], function (_exports, _template, _storageClassProvisioner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var KIND_OPTIONS = [{
    value: 'shared',
    translationKey: 'cruStorageClass.azure-disk.kind.shared'
  }, {
    value: 'dedicated',
    translationKey: 'cruStorageClass.azure-disk.kind.dedicated'
  }, {
    value: 'managed',
    translationKey: 'cruStorageClass.azure-disk.kind.managed'
  }];

  var _default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,
    kindOptions: KIND_OPTIONS,
    skuName: null,
    location: null,
    storageAccount: null,
    storageaccounttype: null,
    kind: 'shared',
    provisioner: 'azure-disk',
    didReceiveAttrs: function didReceiveAttrs() {
      var changes = {};
      var _this$parameters = this.parameters,
          parameters = _this$parameters === void 0 ? {} : _this$parameters;
      var _parameters$storageac = parameters.storageaccounttype,
          storageaccounttype = _parameters$storageac === void 0 ? '' : _parameters$storageac,
          _parameters$kind = parameters.kind,
          kind = _parameters$kind === void 0 ? 'shared' : _parameters$kind;
      changes['storageaccounttype'] = storageaccounttype;
      changes['kind'] = kind;
      Ember.setProperties(this, changes);
    },
    // registered in the StorageClassProvisioner mixin
    updateParams: function updateParams() {
      var storageaccounttype = this.storageaccounttype,
          kind = this.kind,
          _this$out = this.out,
          out = _this$out === void 0 ? {} : _this$out;

      if (storageaccounttype) {
        out['storageaccounttype'] = storageaccounttype;
      }

      if (kind) {
        out['kind'] = kind;
      }

      Ember.set(this, 'parameters', out);
    }
  });

  _exports.default = _default;
});