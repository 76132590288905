define("shared/components/schema/input-password/component", ["exports", "ui/utils/util", "shared/components/schema/input-password/template"], function (_exports, _util, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['input-group'],
    value: '',
    question: null,
    actions: {
      generate: function generate() {
        var randomStr;

        if (Ember.get(this, 'question.maxLength') !== 0 && Ember.get(this, 'question.maxLength') >= Ember.get(this, 'question.minLength') && Ember.get(this, 'question.validChars.length') > 0) {
          randomStr = _util.default.randomStr(Ember.get(this, 'question.minLength'), Ember.get(this, 'question.maxLength'), Ember.get(this, 'question.validChars'));
        } else {
          randomStr = _util.default.randomStr(16, 16, 'password');
        }

        Ember.set(this, 'value', randomStr);
        var $field = this.$('INPUT');
        $field.attr('type', 'text');
        setTimeout(function () {
          $field[0].focus();
          $field[0].select();
        }, 50);

        if (this.generated) {
          this.generated();
        }
      }
    }
  });

  _exports.default = _default;
});