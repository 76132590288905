define("shared/components/advanced-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kEMp8mIc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[[27,\"unless\",[[23,[\"advanced\"]],\"hide\"],null]]]],[9],[0,\"\\n  \"],[14,1,[[23,[\"advanced\"]]]],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"unless\",[[23,[\"advanced\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"text-right text-small\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn bg-transparent p-0\"],[9],[1,[27,\"t\",[[27,\"if\",[[23,[\"advanced\"]],\"advancedSection.hideText\",\"advancedSection.showText\"],null]],null],false],[3,\"action\",[[22,0,[]],\"toggle\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/advanced-section/template.hbs"
    }
  });

  _exports.default = _default;
});