define("shared/components/confirm-delete/component", ["exports", "ui/utils/platform", "ui/mixins/modal-base", "shared/components/confirm-delete/template", "async", "ui/utils/constants"], function (_exports, _platform, _modalBase, _template, _async, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function hasSomeOfResourceType(resourceType) {
    console.log(Ember.get(this, 'resources'));
    return Ember.get(this, 'resources').some(function (resource) {
      return Ember.get(resource, 'type') === resourceType;
    });
  }

  var _default = Ember.Component.extend(_modalBase.default, {
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['medium-modal'],
    alternateLabel: _platform.alternateLabel,
    forceDelete: false,
    resources: Ember.computed.alias('modalService.modalOpts.resources'),
    didRender: function didRender() {
      var _this = this;

      setTimeout(function () {
        try {
          _this.$('BUTTON')[0].focus();
        } catch (e) {}
      }, 500);
    },
    actions: {
      confirm: function confirm() {
        var _this2 = this;

        var resources = Ember.get(this, 'resources').slice().reverse();
        (0, _async.eachLimit)(resources, 5, function (resource, cb) {
          if (!resource) {
            return cb();
          }

          if (resource.cb) {
            var out = resource.cb();

            if (out && out.finally) {
              out.finally(cb);
            } else {
              cb();
            }

            return;
          } else {
            if (Ember.get(_this2, 'forceDelete')) {
              resource.delete({
                url: "".concat(resource.linkFor('remove'), "?gracePeriodSeconds=0")
              }).finally(cb);
            } else {
              resource.delete().finally(cb);
            }
          }
        });
        this.send('cancel');
      }
    },
    showProtip: Ember.computed('modalService.modalOpts.showProtip', function () {
      var show = Ember.get(this, 'modalService.modalOpts.showProtip');

      if (show === undefined) {
        show = true;
      }

      return show;
    }),
    isEnvironment: Ember.computed('resources', function () {
      return !!Ember.get(this, 'resources').findBy('type', 'project');
    }),
    isCluster: Ember.computed('resources', function () {
      return !!Ember.get(this, 'resources').findBy('type', 'cluster');
    }),
    isPod: Ember.computed('resources', function () {
      return !!Ember.get(this, 'resources').findBy('type', 'pod');
    }),
    isClusterRoleTemplateBinding: Ember.computed('resources', function () {
      return !!Ember.get(this, 'resources').findBy('type', 'clusterRoleTemplateBinding');
    }),
    isSystemProject: Ember.computed('resources', function () {
      var project = Ember.get(this, 'resources').findBy('type', 'project');
      return project && Ember.get(project, 'isSystemProject');
    }),
    isSystemChart: Ember.computed('resources', function () {
      var app = Ember.get(this, 'resources').findBy('type', 'app') || {};
      return app && _constants.default.SYSTEM_CHART_APPS.includes(Ember.get(app, 'displayName'));
    }),
    hasSystemProjectNamespace: Ember.computed('resources', function () {
      var namespaces = Ember.get(this, 'resources').filter(function (resource) {
        return Ember.get(resource, 'type') === 'namespace' && Ember.get(resource, 'project.isSystemProject');
      });
      return Ember.get(namespaces, 'length') > 0;
    }),
    hasNamespaceResourceType: Ember.computed('resources', function () {
      return hasSomeOfResourceType.call(this, _constants.default.RESOURCE_TYPES.NAMESPACE);
    }),
    hasProjectResourceType: Ember.computed('resources', function () {
      return hasSomeOfResourceType.call(this, _constants.default.RESOURCE_TYPES.PROJECT);
    }),
    hasClusterResourceType: Ember.computed('resources', function () {
      return hasSomeOfResourceType.call(this, _constants.default.RESOURCE_TYPES.CLUSTER);
    }),
    resourceType: Ember.computed('resources', function () {
      if (Ember.get(this, 'hasNamespaceResourceType')) {
        return _constants.default.RESOURCE_TYPES.NAMESPACE;
      }

      if (Ember.get(this, 'hasProjectResourceType')) {
        return _constants.default.RESOURCE_TYPES.PROJECT;
      }

      if (Ember.get(this, 'hasClusterResourceType')) {
        return _constants.default.RESOURCE_TYPES.CLUSTER;
      }

      return null;
    })
  });

  _exports.default = _default;
});