define("shared/components/error-sub-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g1TAl4Y9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"tr\"],[11,\"class\",\"sub-row no-top auto-height\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"leftColspan\"]]],null,{\"statements\":[[0,\"    \"],[7,\"td\"],[12,\"colspan\",[28,[[21,\"leftColspan\"]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"td\"],[11,\"class\",\"pb-5\"],[12,\"colspan\",[28,[[21,\"mainColspan\"]]]],[9],[0,\"\\n    \"],[7,\"small\"],[12,\"class\",[28,[[23,[\"model\",\"stateColor\"]]]]],[9],[1,[27,\"uc-first\",[[23,[\"model\",\"transitioningMessage\"]]],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"rightColspan\"]]],null,{\"statements\":[[0,\"    \"],[7,\"td\"],[12,\"colspan\",[28,[[21,\"rightColspan\"]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/error-sub-row/template.hbs"
    }
  });

  _exports.default = _default;
});