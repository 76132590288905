define("global-admin/settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JER4IvZX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"header has-tabs clearfix p-0\"],[9],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"tab-nav\"],[9],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"settings.advanced\"],null,{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"settingsPage.tabs.settings\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"settings.features\"],null,{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"settingsPage.tabs.feature\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/settings/template.hbs"
    }
  });

  _exports.default = _default;
});