enifed("ember-template-compiler/index", ["exports", "@ember/-internals/environment", "@ember/canary-features", "ember/version", "ember-template-compiler/lib/compat", "ember-template-compiler/lib/system/precompile", "ember-template-compiler/lib/system/compile", "ember-template-compiler/lib/system/compile-options", "ember-template-compiler/lib/plugins/index", "ember-template-compiler/lib/system/bootstrap", "ember-template-compiler/lib/system/initializer"], function (_exports, _environment, _canaryFeatures, _version, _compat, _precompile, _compile, _compileOptions, _index, _bootstrap, _initializer) {
  "use strict";

  Object.defineProperty(_exports, "precompile", {
    enumerable: true,
    get: function () {
      return _precompile.default;
    }
  });
  Object.defineProperty(_exports, "compile", {
    enumerable: true,
    get: function () {
      return _compile.default;
    }
  });
  Object.defineProperty(_exports, "compileOptions", {
    enumerable: true,
    get: function () {
      return _compileOptions.default;
    }
  });
  Object.defineProperty(_exports, "registerPlugin", {
    enumerable: true,
    get: function () {
      return _compileOptions.registerPlugin;
    }
  });
  Object.defineProperty(_exports, "unregisterPlugin", {
    enumerable: true,
    get: function () {
      return _compileOptions.unregisterPlugin;
    }
  });
  Object.defineProperty(_exports, "defaultPlugins", {
    enumerable: true,
    get: function () {
      return _index.default;
    }
  });
  _exports._Ember = void 0;

  const _Ember = typeof _environment.context.imports.Ember === 'object' && _environment.context.imports.Ember || {}; // private API used by ember-cli-htmlbars to setup ENV and FEATURES


  _exports._Ember = _Ember;

  if (!_Ember.ENV) {
    _Ember.ENV = _environment.ENV;
  }

  if (!_Ember.FEATURES) {
    _Ember.FEATURES = _canaryFeatures.FEATURES;
  }

  if (!_Ember.VERSION) {
    _Ember.VERSION = _version.default;
  } // used for adding Ember.Handlebars.compile for backwards compat


  (0, _compat.default)(_Ember);
});