define("shared/components/tooltip-warning/component", ["exports", "shared/mixins/tooltip", "ui/utils/constants", "shared/components/tooltip-warning/template"], function (_exports, _tooltip, _constants, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tooltip.default, {
    prefs: Ember.inject.service(),
    layout: _template.default,
    classNames: ['tooltip-warning-container'],
    actions: {
      hideAccessWarning: function hideAccessWarning() {
        this.set("prefs.".concat(_constants.default.PREFS.ACCESS_WARNING), false);
        this.destroyTooltip();
      }
    }
  });

  _exports.default = _default;
});