define("shared/utils/subscribe-project", ["exports", "shared/mixins/subscribe"], function (_exports, _subscribe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_subscribe.default, {
    scope: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'endpoint', Ember.get(this, 'app.projectSubscribeEndpoint'));
    },
    validate: function validate() {
      var socket = Ember.get(this, 'subscribeSocket');
      var metadata = socket.getMetadata();
      var socketProjectId = Ember.get(metadata, 'projectId');
      var currentProjectId = Ember.get(this, 'scope.currentProject.id');

      if (!currentProjectId || currentProjectId === socketProjectId) {
        return true;
      } else {
        console.error("".concat(this.label, " Subscribe ignoring message, current=").concat(currentProjectId, " socket=").concat(socketProjectId, " ").concat(this.forStr()));
        return false;
      }
    }
  });

  _exports.default = _default;
});