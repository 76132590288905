define("shared/components/error-sub-row/component", ["exports", "shared/components/error-sub-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    model: null,
    fullColspan: null,
    leftColspan: 1,
    rightColspan: 1,
    tagName: '',
    mainColspan: Ember.computed('fullColspan', function () {
      return (this.get('fullColspan') || 2) - this.get('leftColspan') - this.get('rightColspan');
    })
  });

  _exports.default = _default;
});