define("nodes/components/driver-other/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E93Vz2YA",
    "block": "{\"symbols\":[\"al\",\"expandFn\",\"value\",\"key\"],\"statements\":[[4,\"accordion-list\",null,[[\"showExpandAll\"],[false]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"over-hr\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[21,\"driverOptionsTitle\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"each\",[[27,\"-each-in\",[[23,[\"schema\",\"resourceFields\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"api-field\",null,[[\"resource\",\"resourceType\",\"field\",\"value\",\"schema\",\"typeDocs\"],[[23,[\"config\"]],[23,[\"driverName\"]],[22,4,[]],[27,\"get\",[[23,[\"config\"]],[22,4,[]]],null],[23,[\"schema\"]],[23,[\"typeDocumentations\"]]]]],false],[0,\"\\n\"]],\"parameters\":[3,4]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"over-hr\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[21,\"templateOptionsTitle\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[27,\"form-name-description\",null,[[\"model\",\"nameRequired\",\"rowClass\"],[[23,[\"model\"]],true,\"row mb-10\"]]],false],[0,\"\\n\\n  \"],[1,[27,\"form-user-labels\",null,[[\"initialLabels\",\"setLabels\"],[[23,[\"labelResource\",\"labels\"]],[27,\"action\",[[22,0,[]],\"setLabels\"],null]]]],false],[0,\"\\n\\n  \"],[1,[27,\"form-node-taints\",null,[[\"model\",\"expand\"],[[23,[\"model\"]],[27,\"action\",[[22,0,[]],[22,2,[]]],null]]]],false],[0,\"\\n\\n  \"],[1,[27,\"form-engine-opts\",null,[[\"machine\",\"showEngineUrl\"],[[23,[\"model\"]],[23,[\"showEngineUrl\"]]]]],false],[0,\"\\n\\n  \"],[1,[27,\"top-errors\",null,[[\"errors\"],[[23,[\"errors\"]]]]],false],[0,\"\\n  \"],[1,[27,\"save-cancel\",null,[[\"save\",\"cancel\",\"editing\"],[[27,\"action\",[[22,0,[]],\"save\"],null],[27,\"action\",[[22,0,[]],\"cancel\"],null],[23,[\"editing\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nodes/components/driver-other/template.hbs"
    }
  });

  _exports.default = _default;
});