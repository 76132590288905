define("shared/utils/headers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VOLUMES = [{
    name: 'serviceName',
    sort: ['instance.service.displayName:desc', 'instanceId:desc'],
    translationKey: 'volumesPage.mounts.table.instance'
  }, {
    name: 'instanceName',
    sort: ['instanceName:desc', 'instanceId:desc'],
    translationKey: 'volumesPage.mounts.table.instance'
  }, {
    name: 'path',
    sort: ['path'],
    translationKey: 'volumesPage.mounts.table.path'
  }, {
    name: 'permission',
    sort: ['permission'],
    translationKey: 'volumesPage.mounts.table.permission'
  }];
  var _default = {
    volumes: VOLUMES
  };
  _exports.default = _default;
});