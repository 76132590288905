enifed("@ember/-internals/views/lib/system/action_manager", ["exports"], function (_exports) {
  "use strict";

  _exports.default = ActionManager;

  /**
  @module ember
  */
  function ActionManager() {}
  /**
    Global action id hash.
  
    @private
    @property registeredActions
    @type Object
  */


  ActionManager.registeredActions = {};
});