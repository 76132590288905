define("shared/components/modal-add-custom-roles/component", ["exports", "shared/mixins/modal-base", "shared/components/modal-add-custom-roles/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['small-modal'],
    mode: null,
    errors: null,
    type: Ember.computed.alias('modalOpts.type'),
    current: Ember.computed.alias('modalOpts.current'),
    isCurrentCustom: Ember.computed.alias('modalOpts.isCustom'),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.setProperties(this, {
        mode: 'custom',
        cTyped: Ember.get(this, 'type').capitalize()
      });
    },
    actions: {
      save: function save(cb) {
        var customroles = Ember.get(this, 'filteredRoles').filter(function (c) {
          return Ember.get(c, 'active');
        }).map(function (r) {
          return Ember.get(r, 'role.id');
        });

        if (Ember.get(this, 'mode') !== 'custom') {
          customroles.push(Ember.get(this, 'mode')); // from custom to non
        }

        if (customroles.length > 0) {
          Ember.set(this, 'errors', null);
          Ember.get(this, 'modalOpts.done')(customroles);
          this.get('modalService').toggleModal();
        } else {
          Ember.set(this, 'errors', ['You must choose a role for this memeber before closing the modal.']);
          return cb(false);
        }
      },
      completed: function completed() {
        this.get('modalService').toggleModal();
      },
      goBack: function goBack() {
        Ember.get(this, 'modalOpts.modalCanceled')();
        this.get('modalService').toggleModal();
      },
      toggle: function toggle(e) {
        var $target = $(e.target); // eslint-disable-line

        var $row = $target.closest('.input-group');
        var check = $('input[type=checkbox]', $row)[0]; // eslint-disable-line

        if (check && e.target !== check && e.target.tagName !== 'LABEL') {
          check.checked = !check.checked;
        }
      }
    },
    filteredRoles: Ember.computed('modalOpts.roles', function () {
      var _this = this;

      return Ember.get(this, 'modalOpts.roles').filterBy('isCustom').map(function (role) {
        var binding = null;

        if (Ember.get(_this, 'modalOpts.current.length')) {
          binding = Ember.get(_this, 'modalOpts.current').includes(Ember.get(role, 'id')) ? role : null;
        }

        return {
          role: role,
          active: !!binding,
          existing: binding
        };
      });
    }),
    roleTemplateId: Ember.computed({
      get: function get(key) {
        if (Ember.get(this, 'mode') === 'custom') {
          return 'custom';
        } else {
          return "".concat(Ember.get(this, 'type'), "-").concat(key);
        }
      },
      set: function set(key, value) {
        if (value === 'custom') {
          return Ember.set(this, 'mode', 'custom');
        } else {
          Ember.get(this, 'filteredRoles').forEach(function (c) {
            return Ember.set(c, 'active', false);
          });
          return Ember.set(this, 'mode', "".concat(Ember.get(this, 'type'), "-").concat(value));
        }
      }
    })
  });

  _exports.default = _default;
});