define('ember-cli-clipboard/helpers/is-clipboard-supported', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isClipboardSupported = exports.isClipboardSupported = window && window.ClipboardJS ? window.ClipboardJS.isSupported : function () {
    return false;
  };
  exports.default = Ember.Helper.helper(isClipboardSupported);
});