define("shared/mixins/view-new-edit", ["exports", "shared/mixins/new-or-edit"], function (_exports, _newOrEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EDIT = _exports.NEW = _exports.VIEW = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var VIEW = 'view';
  _exports.VIEW = VIEW;
  var NEW = 'new';
  _exports.NEW = NEW;
  var EDIT = 'edit';
  _exports.EDIT = EDIT;

  var _default = Ember.Mixin.create(_newOrEdit.default, {
    intl: Ember.inject.service(),
    titleKey: null,
    inlineEdit: false,
    isView: Ember.computed.equal('mode', VIEW),
    isNew: Ember.computed.equal('mode', NEW),
    isEdit: Ember.computed.equal('mode', EDIT),
    notView: Ember.computed.or('isNew', 'isEdit'),
    editing: Ember.computed.alias('notView'),
    actions: {
      inlineEdit: function inlineEdit() {
        Ember.set(this, 'mode', EDIT);
        Ember.set(this, 'inlineEdit', true);
      },
      viewEditCancel: function viewEditCancel() {
        if (Ember.get(this, 'inlineEdit')) {
          Ember.set(this, 'inlineEdit', false);
          Ember.set(this, 'mode', VIEW);
        } else {
          if (this.cancel) {
            this.cancel();
          }
        }
      }
    },
    isClone: Ember.computed('mode', 'primaryResource.{id,name}', function () {
      var pr = Ember.get(this, 'primaryResource');
      var created = pr.hasOwnProperty('created');

      var tpof = _typeof(pr.created);

      var isClone = false;

      if (created && (tpof !== null || tpof !== undefined)) {
        isClone = true;
      }

      return isClone;
    }),
    title: Ember.computed('mode', 'primaryResource.displayName', 'titleKey', function () {
      var prefix = Ember.get(this, 'titleKey');
      var mode = Ember.get(this, 'mode');
      var intl = Ember.get(this, 'intl');
      var name = Ember.get(this, 'originalModel.displayName') || Ember.get(this, 'primaryResource.displayName') || '';
      return intl.t("".concat(prefix, ".").concat(mode), {
        name: name
      });
    }),
    doneSaving: function doneSaving() {
      if (this.done) {
        this.done();
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});