define("ember-intl/-private/normalize-locale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * @private
   * @hide
   */
  function _default(localeName) {
    if (typeof localeName === 'string') {
      return localeName.replace(/_/g, '-').toLowerCase();
    }
  }
});