define("shared/utils/parse-unit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatSi = formatSi;
  _exports.exponentNeeded = exponentNeeded;
  _exports.parseSi = parseSi;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var UNITS = ['', 'K', 'M', 'G', 'T', 'P'];
  var FRACTIONAL = ['', 'm', 'u', 'n', 'p', 'f']; // milli micro nano pico femto

  function formatSi(inValue) {
    var increment = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1000;
    var suffix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    var firstSuffix = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    var startingExponent = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
    var minExponent = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 0;
    var maxPrecision = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : 2;
    var val = inValue;
    var exp = startingExponent;

    while (val >= increment && exp + 1 < UNITS.length || exp < minExponent) {
      val = val / increment;
      exp++;
    }

    var out = '';

    if (val < 10 && maxPrecision >= 2) {
      out = Math.round(val * 100) / 100;
    } else if (val < 100 && maxPrecision >= 1) {
      out = Math.round(val * 10) / 10;
    } else {
      out = Math.round(val);
    }

    if (exp === 0 && firstSuffix !== null) {
      out += " ".concat(firstSuffix);
    } else {
      out += " ".concat(UNITS[exp]).concat(suffix) || '';
    }

    return out;
  }

  function exponentNeeded(val) {
    var increment = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1000;
    var exp = 0;

    while (val >= increment) {
      val = val / increment;
      exp++;
    }

    return exp;
  }

  function parseSi(inValue) {
    var increment = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var allowFractional = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

    if (!inValue || typeof inValue !== 'string' || !inValue.length) {
      return NaN;
    }

    inValue = inValue.replace(/,/g, '');

    var _inValue$match = inValue.match(/^([0-9.-]+)\s*([^0-9.-]?)([^0-9.-]?)/),
        _inValue$match2 = _slicedToArray(_inValue$match, 4),
        valStr = _inValue$match2[1],
        unit = _inValue$match2[2],
        incStr = _inValue$match2[3];

    var val = parseFloat(valStr);

    if (!unit) {
      return val;
    } // micro "mu" symbol -> u


    if (unit.charCodeAt(0) === 181) {
      unit = 'u';
    }

    var divide = FRACTIONAL.includes(unit);
    var multiply = UNITS.includes(unit.toUpperCase());

    if (increment === null) {
      // Automatically handle 1 KB = 1000B, 1 KiB = 1024B if no increment set
      if ((multiply || divide) && incStr === 'i') {
        increment = 1024;
      } else {
        increment = 1000;
      }
    }

    if (divide && allowFractional) {
      var exp = FRACTIONAL.indexOf(unit);
      return val / Math.pow(increment, exp);
    }

    if (multiply) {
      var _exp = UNITS.indexOf(unit.toUpperCase());

      return val * Math.pow(increment, _exp);
    } // Unrecognized unit character


    return val;
  }
});