define("shared/components/modal-istio-yaml/component", ["exports", "json2yaml", "shared/mixins/modal-base", "shared/utils/fetch-yaml", "shared/components/modal-istio-yaml/template"], function (_exports, _json2yaml, _modalBase, _fetchYaml, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VIRTUAL_SERVICES = 'virtualservices';
  var SERVICE_ENTRIES = 'serviceentries';
  var DESTINATION_RULES = 'destinationrules';
  var GATEWAYS = 'gateways';
  var ENVOY_FILTERS = 'envoyfilters';
  var APP_ID = 'io.cattle.field/appId';
  var OPTIONS = [VIRTUAL_SERVICES, DESTINATION_RULES, GATEWAYS, SERVICE_ENTRIES, ENVOY_FILTERS];

  var _default = Ember.Component.extend(_modalBase.default, {
    scope: Ember.inject.service(),
    growl: Ember.inject.service(),
    classNames: ['modal-container', 'large-modal', 'fullscreen-modal', 'modal-shell', 'alert'],
    layout: _template.default,
    loading: null,
    selectedType: VIRTUAL_SERVICES,
    options: OPTIONS,
    name: Ember.computed.alias('modalService.modalOpts.name'),
    appId: Ember.computed.alias('modalService.modalOpts.appId'),
    namespace: Ember.computed.alias('modalService.modalOpts.namespace'),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'options', OPTIONS.map(function (opt) {
        return {
          label: opt,
          value: opt
        };
      }));
    },
    selectedTypeDidChange: Ember.on('init', Ember.observer('selectedType', function () {
      this.loadIstioResources(Ember.get(this, 'selectedType'));
    })),
    filename: Ember.computed('selectedType', function () {
      return "".concat(Ember.get(this, 'selectedType'), ".yaml");
    }),
    loadIstioResources: function loadIstioResources(type) {
      var _this = this;

      var appId = Ember.get(this, 'appId');
      var namespace = Ember.get(this, 'namespace');
      var yamlLink = "/k8s/clusters/".concat(Ember.get(this, 'scope.currentCluster.id'), "/apis/networking.istio.io/v1alpha3/namespaces/").concat(namespace, "/").concat(type);
      Ember.set(this, 'loading', true);
      return (0, _fetchYaml.default)(yamlLink, false).then(function (res) {
        var out = [];
        var data = JSON.parse(res) || {};
        (Ember.get(data, 'items') || []).forEach(function (item) {
          var labels = Ember.get(item, 'metadata.labels') || {};

          if (labels[APP_ID] === appId) {
            out.push(item);
          }
        });
        var yaml = out.length > 0 ? _json2yaml.default.stringify(out) : '';
        Ember.set(_this, 'yaml', yaml);
      }).catch(function (error) {
        Ember.get(_this, 'growl').fromError(Ember.get(error, 'message') || Ember.get(error, 'xhr.responseJSON.message'));
      }).finally(function () {
        Ember.set(_this, 'loading', false);
      });
    }
  });

  _exports.default = _default;
});