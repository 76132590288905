define("shared/components/btn-toggle/component", ["exports", "shared/components/btn-toggle/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['button-toggle'],
    classNameBindings: ['small', 'large'],
    small: true,
    large: false,
    checked: false,
    checkedChanged: Ember.observer('checked', function () {
      this.toggled(this.checked);
    }),
    toggled: function toggled() {
      throw new Error('toggled action is required!');
    }
  });

  _exports.default = _default;
});