define("@rancher/ember-api-store/models/schema", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/normalize"], function (_exports, _resource, _normalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SCHEMA = void 0;
  var SCHEMA = {
    SIMPLE: ['string', 'password', 'masked', 'multiline', 'float', 'int', 'date', 'blob', 'boolean', 'enum', 'reference', 'json', 'dnsLabel', 'hostname', 'dnsLabelRestricted'] //  NESTED: ['array','map'],

  };
  _exports.SCHEMA = SCHEMA;

  function parseType(type) {
    return type.replace(/]/g, '').split('[');
  }

  var Schema = _resource.default.extend({
    getFieldNames: function getFieldNames() {
      return Object.keys(Ember.get(this, 'resourceFields'));
    },
    typeifyFields: Ember.computed('id', function () {
      // Schemas are special..
      if (Ember.get(this, 'id') === 'schema') {
        return [];
      }

      var fields = Ember.get(this, 'resourceFields') || {};
      var keys = Object.keys(fields);
      var out = keys.filter(function (k) {
        var parts = parseType(fields[k].type);

        for (var i = 0; i < parts.length; i++) {
          if (SCHEMA.SIMPLE.includes(parts[i])) {
            return false;
          }
        }

        return true;
      });
      return out;
    }),
    getLoadDefaults: function getLoadDefaults(record) {
      var forceOverwrite = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var fields = Ember.get(this, 'resourceFields');
      Object.keys(fields).forEach(function (key) {
        var field = fields[key];
        var def = field['default'];

        if (field.create && def !== null && typeof def !== 'undefined') {
          if (forceOverwrite) {
            Ember.set(record, key, JSON.parse(JSON.stringify(def)));
          } else {
            if (!record[key]) {
              Ember.set(record, key, JSON.parse(JSON.stringify(def)));
            }
          }
        }
      });
      return record;
    },
    getCreateDefaults: function getCreateDefaults(more) {
      var out = {};
      var fields = Ember.get(this, 'resourceFields');
      Object.keys(fields).forEach(function (key) {
        var field = fields[key];
        var def = field['default'];

        if (field.create && def !== null) {
          if (typeof def !== 'undefined') {
            Ember.set(out, key, JSON.parse(JSON.stringify(def)));
          }
        }
      });

      if (more) {
        Object.keys(more).forEach(function (key) {
          Ember.set(out, key, Ember.get(more, key));
        });
      }

      return out;
    },
    optionsFor: function optionsFor(field) {
      var obj = Ember.get(this, 'resourceFields')[field];

      if (obj && obj.options) {
        return (obj.options || []).slice();
      }

      return [];
    },
    typesFor: function typesFor(fieldName) {
      var field = Ember.get(this, 'resourceFields')[fieldName];

      if (!field || !field.type) {
        return [];
      }

      return field.type.replace(/\]/g, '').split('[');
    },
    primaryTypeFor: function primaryTypeFor(field) {
      var types = this.typesFor(field);

      if (types) {
        return types[0];
      }
    },
    subTypeFor: function subTypeFor(field) {
      var types = this.typesFor(field);

      if (types.length < 2) {
        return null;
      } else if (types.length === 2) {
        return types[1];
      } else {
        var out = types[types.length - 1];

        for (var i = types.length - 2; i >= 1; i--) {
          out = types[i] + '[' + out + ']';
        }

        return out;
      }
    },
    referencedTypeFor: function referencedTypeFor(field) {
      var obj = Ember.get(this, 'resourceFields')[field];
      var type = obj.type;
      var match = type.match(/^reference\[([^\]]*)\]$/);

      if (match) {
        return match[1];
      }
    }
  });

  Schema.reopenClass({
    mangleIn: function mangleIn(data) {
      // Pass IDs through the type normalizer so they will match the case in other places like store.find('schema',normalizeType('thing'))
      data._id = data.id;
      data.id = (0, _normalize.normalizeType)(data.id);
      return data;
    }
  });
  var _default = Schema;
  _exports.default = _default;
});