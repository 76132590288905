define("global-admin/cluster-templates/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eUss3gcQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"header clearfix\"],[9],[0,\"\\n  \"],[7,\"h1\"],[11,\"class\",\"pull-left\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"clusterTemplatesPage.index.header\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"vertical-middle\"],[9],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"right-buttons\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"cluster-templates.new\"],[[\"class\",\"disabled\"],[\"btn btn-sm bg-primary\",[27,\"rbac-prevents\",null,[[\"resource\",\"scope\",\"permission\"],[\"cluster\",\"global\",\"create\"]]]]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"clusterTemplatesPage.index.newTemplate\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"cluster-templates-index-container\"],[9],[0,\"\\n  \"],[5,\"cluster-templates-table\",[],[[\"@clusterTemplates\",\"@clusterTemplateRevisions\"],[[23,[\"model\",\"clusterTemplates\"]],[23,[\"model\",\"clusterTemplateRevisions\"]]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/cluster-templates/index/template.hbs"
    }
  });

  _exports.default = _default;
});