define("shared/components/link-to-as-attrs/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  // This is a link-to where models (path components) and query-params can be set as attribtues instead of positional params
  var _default = Ember.LinkComponent.extend({
    attributeBindings: ['role', 'aria-haspopup', 'aria-expanded'],
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, 'params', [Ember.get(this, 'attrs.ourRoute.value')].concat(_toConsumableArray(Ember.get(this, 'attrs.ctx.value') || [])));

      if (Ember.get(this, 'attrs.qp.value')) {
        Ember.get(this, 'params').pushObject(Ember.get(this, 'attrs.qp.value'));
      }

      this._super.apply(this, arguments);
    },
    'current-when': Ember.computed('onlyCurrentWhen', function () {
      var base = Ember.get(this, 'qualifiedRouteName');

      if (Ember.get(this, 'onlyCurrentWhen.length')) {
        return Ember.get(this, 'onlyCurrentWhen').concat(base).join(' ');
      }
    }),
    queryParams: Ember.computed('attrs.qp.value', function () {
      return {
        isQueryParams: true,
        values: Ember.get(this, 'attrs.qp.value') || {}
      };
    })
  });

  _exports.default = _default;
});