define("global-admin/security/authentication/keycloak/controller", ["exports", "global-admin/mixins/authentication", "global-admin/mixins/saml-auth"], function (_exports, _authentication, _samlAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_authentication.default, _samlAuth.default, {
    createLabel: 'authPage.keycloak.buttonText.pre',
    saveLabel: 'authPage.keycloak.buttonText.post',
    providerName: 'keycloak',
    providerNamePath: 'authPage.saml.providerName.keycloak'
  });

  _exports.default = _default;
});