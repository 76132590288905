define('ember-href-to/helpers/href-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function hrefTo(context, params) {
    var routing = Ember.getOwner(context).lookup('service:-routing');
    return routing.generateURL.apply(routing, _toConsumableArray(getParamsForGenerateURL(params)));
  }

  function getParamsForGenerateURL(params) {
    params = params.slice(); // create a copy
    var targetRouteName = params.shift(); // the first param is always the target route name
    var lastParam = params[params.length - 1]; // the last param might be queryParams
    var queryParams = void 0;
    if (lastParam && lastParam.isQueryParams) {
      queryParams = params.pop().values;
    } else {
      queryParams = {};
    }
    var models = params; // the remainder are the models
    return [targetRouteName, models, queryParams];
  }

  exports.default = Ember.Helper.extend({
    compute: function compute(params, namedArgs) {
      if (namedArgs.params) {
        return hrefTo(this, namedArgs.params);
      } else {
        return hrefTo(this, params);
      }
    }
  });
});