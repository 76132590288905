define("global-admin/security/authentication/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    access: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    lastRoute: 'global-admin.security.authentication.github',
    drivers: Ember.computed(function () {
      return [{
        route: 'security.authentication.activedirectory',
        label: 'Active Directory',
        css: 'activedirectory',
        available: this.hasRecord('activedirectoryconfig'),
        providerType: null
      }, {
        route: 'security.authentication.azuread',
        label: 'Azure AD',
        css: 'azuread',
        available: this.hasRecord('azureadconfig'),
        providerType: null
      }, {
        route: 'security.authentication.github',
        label: 'GitHub',
        css: 'github',
        available: this.hasRecord('githubconfig'),
        providerType: null
      }, {
        route: 'security.authentication.ping',
        label: 'Ping',
        css: 'ping',
        available: this.hasRecord('pingconfig'),
        providerType: 'saml'
      }, {
        route: 'security.authentication.keycloak',
        label: 'Keycloak',
        css: 'keycloak',
        available: this.hasRecord('keycloakconfig'),
        providerType: 'saml'
      }, {
        route: 'security.authentication.adfs',
        label: 'AD FS',
        css: 'adfs',
        available: this.hasRecord('adfsconfig'),
        providerType: 'saml'
      }, {
        route: 'security.authentication.okta',
        label: 'Okta',
        css: 'okta',
        available: this.hasRecord('oktaconfig'),
        providerType: 'saml'
      }, {
        route: 'security.authentication.freeipa',
        label: 'FreeIPA',
        css: 'freeipa',
        available: this.hasRecord('freeipaconfig'),
        providerType: 'ldap'
      }, {
        route: 'security.authentication.openldap',
        label: 'OpenLDAP',
        css: 'openldap',
        available: this.hasRecord('openldapconfig'),
        providerType: 'ldap'
      }, {
        route: 'security.authentication.googleoauth',
        label: 'Google',
        css: 'googleoauth',
        available: this.hasRecord('googleoauthconfig'),
        providerType: null
      }];
    }),
    filteredDrivers: Ember.computed(function () {
      // this is a soft disable of allowing multiple auth configs being active, we need to disable it right now but it will come back post 2.1
      // when it does just itterate over the drivers again and remove filteredDrivers
      var drivers = this.drivers;
      var providers = Ember.get(this, 'globalStore').all('authconfig').filterBy('enabled');

      if (providers.length === 1) {
        // all drivers available
        return drivers;
      } else if (providers.length === 2) {
        // local +1, only enabled drivers, can not enable new drivers
        drivers.forEach(function (driver) {
          var provider = providers.findBy('id', driver.css);

          if (!provider || driver.css !== provider.id) {
            Ember.set(driver, 'available', false);
          }
        });
      } else if (providers.length > 2) {
        // local + n, can't enable new but can edit or disable
        drivers.forEach(function (driver) {
          var provider = providers.findBy('id', driver.css);

          if (!provider || driver.css !== provider.id) {
            Ember.set(driver, 'available', false);
          }
        });
      }

      return drivers.filterBy('available');
    }),
    hasRecord: function hasRecord(record) {
      var type = 'schema';
      var store = this.get('globalStore');
      return store.hasRecordFor(type, record);
    }
  });

  _exports.default = _default;
});