define("shared/components/modal-move-namespace/component", ["exports", "shared/mixins/modal-base", "shared/components/modal-move-namespace/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    scope: Ember.inject.service(),
    classNames: ['medium-modal'],
    layout: _template.default,
    model: null,
    allProjects: null,
    projectId: null,
    saving: false,
    originalModel: Ember.computed.alias('modalService.modalOpts'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('allProjects', this.get('globalStore').all('project').filterBy('clusterId', this.get('scope.currentCluster.id')));
      var list = Ember.get(this, 'originalModel');

      if (!Ember.isArray(list)) {
        list = [list];
      }

      Ember.set(this, 'model', list);
    },
    actions: {
      save: function save() {
        var _this = this;

        var promises = [];
        var list = Ember.get(this, 'model');

        if (!Ember.isArray(list)) {
          list = [list];
        }

        list.forEach(function (ns) {
          promises.push(ns.doAction('move', {
            projectId: Ember.get(_this, 'projectId')
          }));
        });
        Ember.RSVP.all(promises).then(function () {
          window.location.reload();
        }).catch(function ()
        /* err */
        {
          // actual error is handled by the growl plugin
          Ember.set(_this, 'saving', false);
        });
      }
    }
  });

  _exports.default = _default;
});