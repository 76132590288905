enifed("ember-template-compiler/tests/plugins/assert-input-helper-without-block-test", ["ember-template-compiler/index", "internal-test-helpers"], function (_index, _internalTestHelpers) {
  "use strict";

  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: assert-input-helper-without-block', class extends _internalTestHelpers.AbstractTestCase {
    ['@test Using {{#input}}{{/input}} is not valid']() {
      let expectedMessage = `The {{input}} helper cannot be used in block form. ('baz/foo-bar' @ L1:C0) `;
      expectAssertion(() => {
        (0, _index.compile)('{{#input value="123"}}Completely invalid{{/input}}', {
          moduleName: 'baz/foo-bar'
        });
      }, expectedMessage);
    }

  });
});