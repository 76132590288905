define("shared/components/form-node-taints/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LFukCzRg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"accordion-list-item\",null,[[\"title\",\"detail\",\"expandAll\",\"expand\",\"expandOnInit\"],[[27,\"t\",[\"formNodeTaints.title\"],null],[27,\"t\",[\"formNodeTaints.detail\"],null],[23,[\"expandAll\"]],[27,\"action\",[[22,0,[]],[23,[\"expandFn\"]]],null],[23,[\"expandOnInit\"]]]],{\"statements\":[[0,\"  \"],[1,[27,\"node-taints\",null,[[\"isNode\",\"nodeTemplateTaints\",\"model\"],[[23,[\"isNode\"]],[23,[\"nodeTemplateTaints\"]],[23,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/form-node-taints/template.hbs"
    }
  });

  _exports.default = _default;
});