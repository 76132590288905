define("global-admin/clusters/new/select/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    access: Ember.inject.service(),
    settings: Ember.inject.service(),
    model: function model() {
      return this.modelFor('clusters.new');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var _this$access$me$hasAd = this.access.me.hasAdmin,
          globalAdmin = _this$access$me$hasAd === void 0 ? false : _this$access$me$hasAd;
      var _model$clusterTemplat = model.clusterTemplates,
          clusterTemplates = _model$clusterTemplat === void 0 ? [] : _model$clusterTemplat;
      var _this$settings$cluste = this.settings.clusterTemplateEnforcement,
          clusterTemplateEnforcement = _this$settings$cluste === void 0 ? false : _this$settings$cluste; // setting is string value

      if (clusterTemplateEnforcement === 'true') {
        clusterTemplateEnforcement = true;
      } else {
        clusterTemplateEnforcement = false;
      }

      if (!globalAdmin && clusterTemplateEnforcement) {
        if (clusterTemplates.length <= 0) {
          controller.set('disabledAddCluster', true);
        }
      }
    }
  });

  _exports.default = _default;
});