define("shared/components/pretty-json/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Se2aiWcj",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"json\"]]],null,{\"statements\":[[0,\"  \"],[7,\"pre\"],[11,\"class\",\"bg-pre-light\"],[9],[7,\"code\"],[9],[1,[21,\"json\"],false],[10],[10],[0,\"\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[21,\"value\"],false],[0,\"\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/pretty-json/template.hbs"
    }
  });

  _exports.default = _default;
});