define("global-admin/multi-cluster-apps/catalog/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    resetController: function resetController(controller, isExiting
    /* , transition*/
    ) {
      if (isExiting) {
        controller.set('search', '');
      }
    }
  });

  _exports.default = _default;
});