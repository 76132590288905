define("shared/components/modal-edit-catalog/component", ["exports", "shared/mixins/modal-base", "shared/mixins/new-or-edit", "shared/components/modal-edit-catalog/template"], function (_exports, _modalBase, _newOrEdit, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var kindChoices = [{
    translationKey: 'catalogSettings.more.kind.helm',
    value: 'helm'
  }];
  var scopeChoices = [{
    scope: 'global'
  }, {
    scope: 'cluster'
  }, {
    scope: 'project'
  }];

  var _default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    scopeService: Ember.inject.service('scope'),
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['medium-modal'],
    model: null,
    allNamespaces: null,
    allProjects: null,
    selectedScope: null,
    allScopes: null,
    isPrivate: false,
    kindChoices: kindChoices,
    originalModel: Ember.computed.alias('modalService.modalOpts.model'),
    editing: Ember.computed.notEmpty('originalModel.id'),
    scope: Ember.computed.alias('modalService.modalOpts.scope'),
    init: function init() {
      this._super.apply(this, arguments);

      var orig = this.get('originalModel');
      var clone = orig.clone();

      this._initScope();

      Ember.set(clone, 'kind', 'helm');
      Ember.setProperties(this, {
        model: clone,
        username: Ember.get(clone, 'username'),
        isPrivate: Ember.get(clone, 'username') ? true : false
      });
    },
    watchScope: Ember.observer('selectedScope', function () {
      Ember.set(this, 'model', this._initModel(Ember.get(this, 'selectedScope')));
    }),
    willSave: function willSave() {
      var isPrivate = Ember.get(this, 'isPrivate');
      var pr = Ember.get(this, 'primaryResource');

      if (isPrivate) {
        Ember.setProperties(pr, {
          username: Ember.get(this, 'username'),
          password: Ember.get(this, 'password')
        });
      } else {
        Ember.setProperties(pr, {
          username: null,
          password: null
        });
      }

      return this._super.apply(this, arguments);
    },
    validate: function validate() {
      this._super.apply(this, arguments);

      var errors = Ember.get(this, 'errors') || [];
      var isPrivate = Ember.get(this, 'isPrivate');

      if (isPrivate) {
        if (!Ember.get(this, 'username')) {
          errors.push(Ember.get(this, 'intl').t('catalogSettings.more.username.required'));
        }

        if (!Ember.get(this, 'password')) {
          errors.push(Ember.get(this, 'intl').t('catalogSettings.more.password.required'));
        }
      }

      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    },
    doneSaving: function doneSaving() {
      this.send('cancel');
    },
    _initScope: function _initScope() {
      var scope = this.scope;
      var choices = scopeChoices;

      switch (scope) {
        case 'cluster':
          choices = scopeChoices.filter(function (s) {
            return s.scope !== 'project';
          });
          break;

        case 'global':
          choices = scopeChoices.filter(function (s) {
            return s.scope === 'global';
          });
          break;

        case 'project':
        default:
          break;
      }

      Ember.setProperties(this, {
        allScopes: choices,
        selectedScope: scope
      });
    },
    _initModel: function _initModel() {
      var scope = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'global';
      var current = Ember.get(this, 'model');
      var record = {
        type: 'catalog',
        kind: 'helm',
        branch: 'master'
      };

      switch (scope) {
        case 'cluster':
          Ember.setProperties(record, {
            type: 'clusterCatalog',
            clusterId: Ember.get(this, 'scopeService.currentCluster.id')
          });
          break;

        case 'project':
          Ember.setProperties(record, {
            type: 'projectCatalog',
            projectId: Ember.get(this, 'scopeService.currentProject.id')
          });
          break;

        case 'global':
        default:
          break;
      }

      if (current) {
        Ember.setProperties(record, {
          branch: Ember.get(current, 'branch'),
          url: Ember.get(current, 'url'),
          name: Ember.get(current, 'name'),
          username: Ember.get(current, 'username')
        });
      }

      if (Ember.get(current, 'username')) {
        Ember.set(this, 'isPrivate', true);
      } else {
        Ember.set(this, 'isPrivate', false);
      }

      return Ember.get(this, 'globalStore').createRecord(record);
    }
  });

  _exports.default = _default;
});