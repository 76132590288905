define("shared/components/cru-cluster-template-questions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HyG2vAfW",
    "block": "{\"symbols\":[\"sortable\",\"kind\",\"question\",\"dt\"],\"statements\":[[5,\"sortable-table\",[[11,\"classNames\",\"grid sortable-table\"]],[[\"@body\",\"@bulkActions\",\"@descending\",\"@headers\",\"@pagingLabel\",\"@searchText\",\"@sortBy\",\"@stickyHeader\"],[[21,\"rows\"],[21,\"bulkActions\"],[21,\"descending\"],[21,\"questionsHeaders\"],\"pagination.member\",[21,\"searchText\"],[21,\"sortBy\"],true]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[22,2,[]],\"row\"],null]],null,{\"statements\":[[0,\"    \"],[5,\"cluster-template-question-row\",[],[[\"@questions\",\"@question\",\"@removeQuestion\",\"@applyClusterTemplate\",\"@clusterTemplateCreate\"],[[21,\"schemaQuestions\"],[22,3,[]],[21,\"removeQuestion\"],[21,\"applyClusterTemplate\"],[21,\"clusterTemplateCreate\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,2,[]],\"nomatch\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"td\"],[11,\"colspan\",\"5\"],[11,\"class\",\"no-match\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"clusterTemplateQuestions.table.noMatch\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,2,[]],\"norows\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"td\"],[11,\"colspan\",\"5\"],[11,\"class\",\"no-data\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"clusterTemplateQuestions.table.noData\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1,2,3,4]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/cru-cluster-template-questions/template.hbs"
    }
  });

  _exports.default = _default;
});