define("shared/components/field-required/component", ["exports", "shared/components/field-required/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'span',
    classNames: ['field-required'],
    classNameBindings: ['hide'],
    editing: null,
    hide: Ember.computed('editing', function () {
      return Ember.get(this, 'editing') === false;
    })
  });

  _exports.default = _default;
});