define("shared/helpers/format-mib", ["exports", "ui/utils/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatMib = formatMib;
  _exports.default = void 0;

  function formatMib(params
  /* , options*/
  ) {
    return _util.default.formatMib(params[0]);
  }

  var _default = Ember.Helper.helper(formatMib);

  _exports.default = _default;
});