define("shared/components/percent-gauge/component", ["exports", "shared/mixins/throttled-resize", "ui/utils/percent-gauge", "shared/components/percent-gauge/template"], function (_exports, _throttledResize, _percentGauge, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_throttledResize.default, {
    layout: _template.default,
    tagName: 'div',
    classNames: ['percent-gauge'],
    value: null,
    title: null,
    subtitle: null,
    ticks: null,
    svg: null,
    ready: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('svg', (0, _percentGauge.default)({
        el: this.$()[0],
        value: Ember.get(this, 'value'),
        title: Ember.get(this, 'title'),
        subtitle: Ember.get(this, 'subtitle'),
        ticks: Ember.get(this, 'ticks'),
        mode: Ember.get(this, 'mode')
      }));
      Ember.run.next(this, function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        Ember.set(_this, 'ready', true);
      });
    },
    updateTitle: Ember.observer('title', function () {
      Ember.get(this, 'svg').updateTitle(Ember.get(this, 'title'));
    }),
    updateSubTitle: Ember.observer('subtitle', function () {
      Ember.get(this, 'svg').updateSubTitle(Ember.get(this, 'subtitle'));
    }),
    updateValue: Ember.observer('value', function () {
      Ember.get(this, 'svg').updateValue(Ember.get(this, 'value'));
    }),
    updateTicks: Ember.observer('ticks.@each.{label,value}', function () {
      Ember.get(this, 'svg').updateTicks(Ember.get(this, 'ticks'));
    }),
    onResize: function onResize() {
      if (Ember.get(this, 'svg') && Ember.get(this, 'ready')) {
        Ember.get(this, 'svg').fit();
      }
    }
  });

  _exports.default = _default;
});