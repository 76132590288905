define("shared/helpers/linkify", ["exports", "url-regex"], function (_exports, _urlRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.linkify = linkify;
  _exports.default = void 0;
  var MAX_LENGTH = 1000;

  function convertToLink(match) {
    var url;
    var displayUrl = match.trim();

    if (displayUrl.startsWith('https://') || displayUrl.startsWith('http://') || displayUrl.startsWith('//')) {
      url = displayUrl;
    } else {
      url = "//".concat(displayUrl);
    }

    return "<a href=\"".concat(url, "\" target=\"_blank\">").concat(displayUrl, "</a>");
  }

  function linkify(params) {
    var content = params[0] || '';

    if (content.length > MAX_LENGTH) {
      return content;
    }

    content = Ember.Handlebars.Utils.escapeExpression(content);
    content = content.replace((0, _urlRegex.default)({
      strict: false
    }), convertToLink);
    return Ember.String.htmlSafe(content);
  }

  var _default = Ember.Helper.helper(linkify);

  _exports.default = _default;
});