define("shared/components/input-float/component", ["exports", "shared/components/input-number/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    _elementValueDidChange: function _elementValueDidChange() {
      var val = this.element.value;
      var cur = val;
      val = this.sanitize(val);
      var num = parseFloat(val);
      var max = parseFloat(this.get('max'));

      if (!isNaN(num) && !isNaN(max) && num > max) {
        val = "".concat(max);
      }

      if (cur !== val) {
        this.element.value = val;
      }

      this.set('value', val);
    },
    sanitize: function sanitize(val) {
      val = "".concat(val).trim().replace(/[^0-9.-]/g, '');
      val = val.substr(0, 1) + val.substr(1).replace('-', '');
      var idx = val.indexOf('.');

      if (idx >= 0) {
        var idx2 = val.indexOf('.', idx + 1);

        if (idx2 >= 0) {
          val = val.substr(0, idx2);
        }

        val = val.substr(0, idx + 1 + Ember.get(this, 'precision'));
      }

      if (idx === 0) {
        val = "0".concat(val);
      }

      return val;
    }
  });

  _exports.default = _default;
});