define("shared/mixins/input-answers", ["exports", "shared/utils/flat-map", "js-yaml"], function (_exports, _flatMap, _jsYaml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Mixin.create({
    actions: {
      upload: function upload() {
        this.$('INPUT[type=file]')[0].click();
      },
      showPaste: function showPaste() {
        Ember.set(this, 'pasteOrUpload', true);
      },
      cancel: function cancel() {
        this.parseYamlAnswers();
      }
    },
    parseYamlAnswers: function parseYamlAnswers() {
      var _this = this;

      var valuesYaml = this.valuesYaml,
          questions = this.questions;
      var parsedYaml = null;
      var missing = [];
      var answersMap = {};

      function pushMissing(key, value) {
        var out = {
          lostKey: key,
          lostValue: value
        };
        missing.push(out);
      }

      function basicMatch(match) {
        return match && match.type !== 'enum' && match.type !== 'storageclass';
      }

      function enumAndFoundAnswerInOptionsMatch(match, answer) {
        return match.type === 'enum' && match.options.find(function (i) {
          return i === answer;
        });
      }

      function showIfFoundandShownIfMatch(showIfKey, showIfValue, showIfMatchValue, yaml) {
        return yaml.hasOwnProperty(showIfKey) && showIfMatchValue && showIfMatchValue === !!showIfValue;
      }

      try {
        parsedYaml = _jsYaml.default.safeLoad(valuesYaml);
      } catch (err) {
        Ember.set(this, 'yamlErrors', ["YAML Parse Error: ".concat(err.snippet, " - ").concat(err.message)]);
      }

      if (parsedYaml) {
        var flatParsedYaml = (0, _flatMap.default)(parsedYaml); // loop over the flat map yaml

        Object.keys(flatParsedYaml).forEach(function (fp) {
          var answerFromParsedYaml = flatParsedYaml[fp];

          if (questions && !Ember.isEmpty(answerFromParsedYaml)) {
            // find the matching question
            var match = (_this.questions || []).findBy('variable', fp); // no match

            if (Ember.isEmpty(match)) {
              pushMissing(fp, answerFromParsedYaml);
            } else {
              // type != enum || storageclass
              if (basicMatch(match)) {
                Ember.set(match, 'answer', answerFromParsedYaml);
              } else {
                // type == enum and we found the answer from the yaml in the default options
                if (enumAndFoundAnswerInOptionsMatch(match, answerFromParsedYaml)) {
                  Ember.set(match, 'answer', answerFromParsedYaml);
                } else {
                  // dependent question
                  if (match.showIf) {
                    var _match$showIf$split = match.showIf.split('='),
                        _match$showIf$split2 = _slicedToArray(_match$showIf$split, 2),
                        showIfKey = _match$showIf$split2[0],
                        showIfValue = _match$showIf$split2[1];

                    var showIfMatchValue = flatParsedYaml[showIfKey]; // is it in the yaml & is the answer provided by the true and thats the show if value the same as the match value

                    if (showIfFoundandShownIfMatch(showIfKey, showIfValue, showIfMatchValue, flatParsedYaml)) {
                      if (answerFromParsedYaml) {
                        // storage class is weird, we represent the storage class as an enum in the UI but its from dynamiclly loaded data
                        // even if we have an answer it could not exist in the server data so we should alert the user that it could still cause a problem
                        if (match.type === 'storageclass') {
                          pushMissing(fp, answerFromParsedYaml);
                        }

                        Ember.set(match, 'answer', answerFromParsedYaml);
                      } else {
                        pushMissing(fp, answerFromParsedYaml);
                      }
                    }
                  } else {
                    pushMissing(fp, answerFromParsedYaml);
                  }
                }
              }
            }
          } else {
            if (Ember.isEmpty(answerFromParsedYaml)) {
              answersMap[fp] = answerFromParsedYaml;
            }
          }
        });

        if (missing.length >= 1) {
          this.modalService.toggleModal('modal-confirm-yaml-switch', {
            finish: this.finishBackToForm.bind(this),
            propertiesGoingToBeLost: missing
          });
        } else {
          if (Object.keys(answersMap).length >= 1) {
            Ember.set(this, 'app.answers', answersMap);
          }

          this.finishBackToForm();
        }
      }
    },
    finishBackToForm: function finishBackToForm(cbToCloseModal) {
      var canceled = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (cbToCloseModal) {
        cbToCloseModal();
      }

      if (!canceled) {
        Ember.setProperties(this, {
          yamlErrors: [],
          valuesYaml: '',
          pasteOrUpload: false
        });
      }
    }
  });

  _exports.default = _default;
});