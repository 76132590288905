define("shared/utils/sort", ["exports", "shared/utils/parse-version"], function (_exports, _parseVersion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.insensitiveCompare = insensitiveCompare;
  _exports.sortInsensitiveBy = sortInsensitiveBy;
  _exports.sortVersions = sortVersions;
  var LOCALE_SAFE = /^[\w-.\s,]*$/;

  function lcStr(x) {
    if (typeof x === 'string') {
      return x.toLowerCase();
    } else {
      return String(x).toLowerCase();
    }
  }

  function insensitiveCompare(a, b) {
    if (typeof a === 'string' || typeof b === 'string') {
      a = lcStr(a);
      b = lcStr(b);
    } else {
      return a > b ? 1 : a < b ? -1 : 0;
    }

    if (LOCALE_SAFE.test(a + b)) {
      return a > b ? 1 : a < b ? -1 : 0;
    } else {
      return a.localeCompare(b, {
        sensitivity: 'base'
      });
    }
  }

  function sortInsensitiveBy(ary) {
    for (var _len = arguments.length, fields = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      fields[_key - 1] = arguments[_key];
    }

    // sortInsensitiveBy(ary, field1, field2) or sortInsensitiveBy(ary, [field1, field2])
    if (fields.length === 1 && Ember.isArray(fields[0])) {
      fields = fields[0];
    }

    return ary.sort(function (a, b) {
      var out = 0;

      for (var i = 0; i < fields.length; i++) {
        var aa = Ember.get(a, fields[i]);
        var bb = Ember.get(b, fields[i]);
        out = insensitiveCompare(aa, bb);

        if (out !== 0) {
          break;
        }
      }

      return out;
    });
  }

  function sortVersions(ary, key) {
    var aa, bb;
    return ary.sort(function (a, b) {
      if (key) {
        aa = Ember.get(a, key);
        bb = Ember.get(b, key);
      } else {
        aa = a;
        bb = b;
      }

      return (0, _parseVersion.compare)(aa, bb);
    });
  }
});