define("shared/helpers/uc-first", ["exports", "ui/utils/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ucFirst = ucFirst;
  _exports.default = void 0;

  function ucFirst(params) {
    return _util.default.ucFirst(params[0]);
  }

  var _default = Ember.Helper.helper(ucFirst);

  _exports.default = _default;
});