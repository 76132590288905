define("shared/components/save-cancel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PKuNS93F",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"saving\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[12,\"class\",[28,[\"btn btn-disabled \",[21,\"savingColor\"]]]],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"icon icon-spinner icon-spin\"],[9],[10],[0,\"\\n    \"],[1,[27,\"t\",[[23,[\"savingLabel\"]]],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"doNothing\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\"],[12,\"disabled\",[21,\"saveDisabled\"]],[12,\"class\",[28,[\"btn \",[27,\"if\",[[23,[\"saved\"]],[23,[\"savedColor\"]],[23,[\"saveColor\"]]],null]]]],[11,\"type\",\"submit\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[[23,[\"btnLabel\"]]],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"save\"]],[10],[0,\"\\n\"]],\"parameters\":[]}],[14,1],[0,\"\\n\"],[4,\"unless\",[[23,[\"cancelDisabled\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[12,\"class\",[28,[\"btn \",[21,\"cancelColor\"]]]],[9],[0,\"\\n    \"],[1,[27,\"t\",[[23,[\"cancelLabel\"]]],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/save-cancel/template.hbs"
    }
  });

  _exports.default = _default;
});