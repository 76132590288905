define("shared/components/banner-message/component", ["exports", "shared/components/banner-message/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['banner'],
    classNameBindings: ['color'],
    color: 'bg-default',
    icon: 'icon-info',
    title: null,
    titleWidth: null,
    message: '',
    showClose: false,
    actions: {
      close: function close() {
        if (this.close) {
          this.close();
        }
      }
    },
    showIcon: Ember.computed('title', function () {
      var title = this.get('title');
      return title === null || title === undefined;
    }),
    titleStr: Ember.computed('title', function () {
      var title = this.get('title');

      if (typeof title === 'number') {
        title = "".concat(title);
      }

      return title;
    }),
    titleStyle: Ember.computed('width', function () {
      var width = this.get('titleWidth');

      if (width) {
        return Ember.String.htmlSafe("width: ".concat(width, "px"));
      }
    })
  });

  _exports.default = _default;
});