define("shared/utils/add-view-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addAction = addAction;
  _exports.default = void 0;

  function addAction(action, selector) {
    return function () {
      if (Ember.Component.detectInstance(this)) {
        this._super();
      } else {
        this.get('controller').send(action);
      }

      Ember.run.next(this, function () {
        var matches = this.$(selector);

        if (matches) {
          var last = matches.last();

          if (last) {
            last.focus();
          }
        }
      });
    };
  }

  var _default = {
    addAction: addAction
  };
  _exports.default = _default;
});