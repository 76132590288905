enifed("ember-template-compiler/lib/plugins/assert-splattribute-expression", ["exports", "@ember/debug", "ember-template-compiler/lib/system/calculate-location-display"], function (_exports, _debug, _calculateLocationDisplay) {
  "use strict";

  _exports.default = assertSplattributeExpressions;

  function assertSplattributeExpressions(env) {
    let {
      moduleName
    } = env.meta;
    return {
      name: 'assert-splattribute-expressions',
      visitor: {
        AttrNode({
          name,
          loc
        }) {
          if (!true
          /* EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION */
          && name === '...attributes') {
            true && !false && (0, _debug.assert)(`${errorMessage()} ${(0, _calculateLocationDisplay.default)(moduleName, loc)}`);
          }
        },

        PathExpression({
          original,
          loc
        }) {
          if (original === '...attributes') {
            true && !false && (0, _debug.assert)(`${errorMessage()} ${(0, _calculateLocationDisplay.default)(moduleName, loc)}`);
          }
        }

      }
    };
  }

  function errorMessage() {
    if (true
    /* EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION */
    ) {
        return `Using "...attributes" can only be used in the element position e.g. <div ...attributes />. It cannot be used as a path.`;
      }

    return `...attributes is an invalid path`;
  }
});