define("nodes/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    // Define your engine's route map here
    this.route('custom-drivers', {
      path: '/drivers'
    }, function () {
      this.route('node-drivers', {
        path: '/node'
      });
      this.route('cluster-drivers', {
        path: '/cluster'
      });
    });
    this.route('node-templates');
  });

  _exports.default = _default;
});