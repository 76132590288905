define("@rancher/ember-api-store/utils/normalize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.normalizeType = normalizeType;

  function normalizeType(type, store) {
    type = (type || '').toLowerCase();

    if (type.startsWith('/') && store) {
      var prefix = store.baseUrl + '/schemas/';

      if (type.startsWith(prefix)) {
        type = type.substr(prefix.length);
      }
    }

    return type;
  }
});