define('ember-math-helpers/helpers/atan', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.atan = atan;
  function atan(params) {
    return Math.atan(params[0]);
  }

  exports.default = Ember.Helper.helper(atan);
});