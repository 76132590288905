define("shared/features/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    globalStore: Ember.inject.service(),
    isFeatureEnabled: function isFeatureEnabled(name) {
      return Ember.get(this, 'globalStore').all('feature').filterBy('name', name).get('firstObject.value');
    }
  });

  _exports.default = _default;
});