define("shared/helpers/parse-camelcase", ["exports", "ui/utils/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseCamelcase = parseCamelcase;
  _exports.default = void 0;

  function parseCamelcase(params) {
    return new Ember.String.htmlSafe(_util.default.parseCamelcase(params[0]));
  }

  var _default = Ember.Helper.helper(parseCamelcase);

  _exports.default = _default;
});