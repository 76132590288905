define("shared/tab-session/service", ["exports", "ui/utils/browser-storage"], function (_exports, _browserStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @@TODO@@ - 10-27-17 - move to addon
  var _default = Ember.Service.extend(_browserStorage.default, {
    backing: window.sessionStorage,
    app: Ember.inject.service()
  });

  _exports.default = _default;
});