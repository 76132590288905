define("shared/components/storage-class/provisioner-azure-disk/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bj3F7952",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row mb-20\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col span-4\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"acc-label\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[[27,\"concat\",[\"cruStorageClass.\",[23,[\"provisioner\"]],\".storageaccounttype.label\"],null]],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"input-or-display\",null,[[\"editable\",\"value\"],[[23,[\"editing\"]],[23,[\"storageaccounttype\"]]]],{\"statements\":[[0,\"      \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"classNames\",\"placeholder\"],[\"text\",[23,[\"storageaccounttype\"]],\"form-control\",[27,\"t\",[[27,\"concat\",[\"cruStorageClass.\",[23,[\"provisioner\"]],\".storageaccounttype.placeholder\"],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col span-4\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"acc-label\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[[27,\"concat\",[\"cruStorageClass.\",[23,[\"provisioner\"]],\".kind.label\"],null]],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"editing\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"new-select\",null,[[\"classNames\",\"content\",\"value\",\"optionLabelPath\",\"localizedLabel\"],[\"form-control\",[23,[\"kindOptions\"]],[23,[\"kind\"]],\"translationKey\",true]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"text-muted\"],[9],[0,\"\\n        \"],[1,[21,\"kind\"],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/storage-class/provisioner-azure-disk/template.hbs"
    }
  });

  _exports.default = _default;
});