define("shared/utils/platform", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isAlternate = isAlternate;
  _exports.isMore = isMore;
  _exports.isRange = isRange;
  _exports.version = version;
  _exports.xhrConcur = _exports.isMobile = _exports.isSafari = _exports.isWebKit = _exports.isBlink = _exports.isGecko = _exports.userAgent = _exports.rangeLabel = _exports.rangeKey = _exports.moreLabel = _exports.moreKey = _exports.alternateLabel = _exports.alternateKey = _exports.isWin = _exports.isMac = _exports.isLinuxy = _exports.platform = void 0;
  var platform = (navigator.platform || '').toLowerCase();
  _exports.platform = platform;
  var isLinuxy = platform.indexOf('linux') >= 0 || platform.indexOf('unix') >= 0;
  _exports.isLinuxy = isLinuxy;
  var isMac = platform.indexOf('mac') >= 0;
  _exports.isMac = isMac;
  var isWin = platform.indexOf('win') >= 0;
  _exports.isWin = isWin;
  var alternateKey = 'ctrlKey';
  _exports.alternateKey = alternateKey;
  var alternateLabel = 'Control';
  _exports.alternateLabel = alternateLabel;
  var moreKey = 'ctrlKey';
  _exports.moreKey = moreKey;
  var moreLabel = 'Control';
  _exports.moreLabel = moreLabel;
  var rangeKey = 'shiftKey';
  _exports.rangeKey = rangeKey;
  var rangeLabel = 'Shift';
  _exports.rangeLabel = rangeLabel;

  if (isMac) {
    _exports.alternateKey = alternateKey = 'metaKey';
    _exports.alternateLabel = alternateLabel = 'Command';
    _exports.moreKey = moreKey = 'metaKey';
    _exports.moreLabel = moreLabel = 'Command';
  }

  function isAlternate(event) {
    return !!event[alternateKey];
  }

  function isMore(event) {
    return !!event[moreKey];
  }

  function isRange(event) {
    return !!event[rangeKey];
  } // Only intended to work for Mobile Safari at the moment...


  function version() {
    var match = userAgent.match(/\s+Version\/([0-9.]+)/);

    if (match) {
      return parseFloat(match[1]);
    }

    return null;
  }

  var userAgent = navigator.userAgent;
  _exports.userAgent = userAgent;
  var isGecko = userAgent.indexOf('Gecko/') >= 0;
  _exports.isGecko = isGecko;
  var isBlink = userAgent.indexOf('Chrome/') >= 0;
  _exports.isBlink = isBlink;
  var isWebKit = !isBlink && userAgent.indexOf('AppleWebKit/') >= 0;
  _exports.isWebKit = isWebKit;
  var isSafari = !isBlink && userAgent.indexOf('Safari/') >= 0;
  _exports.isSafari = isSafari;
  var isMobile = /Android|webOS|iPhone|iPad|iPod|IEMobile/i.test(userAgent);
  _exports.isMobile = isMobile;
  var xhrConcur = 99;
  _exports.xhrConcur = xhrConcur;

  if (isSafari) {
    if (version() && version() < 10) {
      // Safari for iOS9 has problems with multiple simultaneous requests
      _exports.xhrConcur = xhrConcur = 1;
    }
  }
});