define("shared/components/modal-shell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v6M6GpIv",
    "block": "{\"symbols\":[],\"statements\":[[4,\"container-shell\",null,[[\"instance\",\"containerName\",\"cols\",\"rows\",\"dismiss\",\"disconncted\"],[[23,[\"originalModel\"]],[23,[\"containerName\"]],80,24,[27,\"action\",[[22,0,[]],\"cancel\"],null],[27,\"action\",[[22,0,[]],\"cancel\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"footer-actions\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn bg-primary\"],[9],[1,[27,\"t\",[\"generic.closeModal\"],null],false],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/modal-shell/template.hbs"
    }
  });

  _exports.default = _default;
});