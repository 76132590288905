define("shared/components/form-ingress-backends/component", ["exports", "shared/components/form-ingress-backends/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _template.default,
    ingress: null,
    rule: null,
    isDefault: null,
    editing: true,
    pathArray: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.initPathArray();
    },
    didInsertElement: function didInsertElement() {
      if (Ember.get(this, 'editing') && Ember.get(this, 'pathArray.length') === 0) {
        this.send('addPath', 'workload');
      }
    },
    actions: {
      addPath: function addPath(backendType) {
        Ember.get(this, 'pathArray').pushObject({
          backendType: backendType,
          targetPort: '',
          serviceId: '',
          path: ''
        });
      },
      removePath: function removePath(path) {
        Ember.get(this, 'pathArray').removeObject(path);
      }
    },
    pathsChanged: Ember.observer('pathArray.@each.{path,backendType,targetPort,serviceId}', 'isDefault', function () {
      if (Ember.get(this, 'isDefault')) {
        this.setDefaultBackend();
      } else {
        this.setPaths();
      }
    }),
    servicesDidChange: Ember.observer('pathArray.@each.{service}', function () {
      var pathArray = Ember.get(this, 'pathArray');
      pathArray.forEach(function (path) {
        var backendType = Ember.get(path, 'backendType');
        var targetPort = Ember.get(path, 'targetPort');
        var availablePorts = Ember.get(path, 'service.availablePorts') || [];
        var hasPorts = Ember.get(path, 'service.availablePorts.length') > 0;

        if (backendType === 'service' && hasPorts && !availablePorts.find(function (p) {
          return p.port === targetPort;
        })) {
          Ember.set(path, 'targetPort', Ember.get(path, 'service.availablePorts.firstObject.port'));
        }
      });
    }),
    hasServiceTargets: Ember.computed('pathArray.@each.backendType}', function () {
      return !!Ember.get(this, 'pathArray').findBy('backendType', 'service');
    }),
    initPathArray: function initPathArray() {
      var _this = this;

      var pathArray = [];
      var paths = Ember.get(this, 'rule.paths') || [];
      paths.forEach(function (path) {
        if (Ember.get(path, 'serviceId')) {
          pathArray.pushObject(Ember.get(_this, 'store').createRecord({
            type: 'httpingresspath',
            backendType: 'service',
            targetPort: "".concat(Ember.get(path, 'targetPort') || ''),
            serviceId: Ember.get(path, 'serviceId').replace('/', ':'),
            path: Ember.get(path, 'path')
          }));
        } else if (Ember.get(path, 'workloadIds')) {
          Ember.get(path, 'workloadIds').forEach(function (workload) {
            pathArray.pushObject({
              backendType: 'workload',
              targetPort: Ember.get(path, 'targetPort'),
              serviceId: workload,
              path: Ember.get(path, 'path')
            });
          });
        }
      });
      Ember.set(this, 'pathArray', pathArray);
    },
    setDefaultBackend: function setDefaultBackend() {
      var pathArray = Ember.get(this, 'pathArray');
      var defaultBackend = {
        workloadIds: []
      };
      pathArray.forEach(function (path) {
        var backendType = Ember.get(path, 'backendType');
        var serviceId = Ember.get(path, 'serviceId');
        var targetPort = Ember.get(path, 'targetPort');

        if (backendType === 'service') {
          defaultBackend.serviceId = serviceId;
        } else if (backendType === 'workload') {
          defaultBackend.workloadIds.pushObject(serviceId);
        }

        defaultBackend.targetPort = targetPort;
      });
      Ember.set(this, 'ingress.defaultBackend', defaultBackend);
    },
    setPaths: function setPaths() {
      var pathArray = Ember.get(this, 'pathArray');
      var paths = [];
      pathArray.forEach(function (item) {
        var backendType = Ember.get(item, 'backendType');
        var path = Ember.get(item, 'path');
        var serviceId = Ember.get(item, 'serviceId');
        var targetPort = Ember.get(item, 'targetPort');

        if (backendType === 'service') {
          paths.pushObject({
            path: path,
            serviceId: serviceId,
            targetPort: targetPort
          });
        } else if (backendType === 'workload') {
          paths.pushObject({
            path: path,
            workloadIds: [serviceId],
            targetPort: targetPort
          });
        }
      });
      Ember.set(this, 'rule.paths', paths);
    }
  });

  _exports.default = _default;
});