define("shared/components/modal-save-rke-template/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+/ZmzsRJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"header\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"modalSaveRkeTemplate.title\"],[[\"clusterName\"],[[23,[\"cluster\",\"displayName\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"help-block\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"modalSaveRkeTemplate.subtitle\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"section\"],[11,\"class\",\"horizontal-form container-fluid\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row inline-form\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col span-6\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"for\",\"save-rke-template-cluster-template-name\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"modalSaveRkeTemplate.name\"],null],false],[1,[21,\"field-required\"],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[9],[0,\"\\n        \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"id\"],[\"text\",[23,[\"clusterTemplateName\"]],\"save-rke-template-cluster-template-name\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"footer-actions\"],[9],[0,\"\\n  \"],[5,\"save-cancel\",[],[[\"@save\",\"@cancel\",\"@saveDisabled\",\"@cancelLabel\"],[[27,\"action\",[[22,0,[]],\"save\"],null],[27,\"action\",[[22,0,[]],\"cancel\"],null],[21,\"saveDisabled\"],\"generic.closeModal\"]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/modal-save-rke-template/template.hbs"
    }
  });

  _exports.default = _default;
});