define('ember-math-helpers/helpers/asin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.asin = asin;
  function asin(params) {
    return Math.asin(params[0]);
  }

  exports.default = Ember.Helper.helper(asin);
});