define("shared/components/sortable-thead/component", ["exports", "shared/components/sortable-thead/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'TH',
    classNames: ['sortable'],
    classNameBindings: ['header.classNames'],
    ariaRole: ['columnheader'],
    attributeBindings: ['width'],
    sortable: null,
    header: null,
    current: Ember.computed.alias('sortable.sortBy'),
    descending: Ember.computed.alias('sortable.descending'),
    activeAscending: Ember.computed('header.name', 'current', 'descending', function () {
      return !this.get('descending') && this.get('current') === this.get('header.name');
    }),
    activeDescending: Ember.computed('header.name', 'current', 'descending', function () {
      return this.get('descending') && this.get('current') === this.get('header.name');
    }),
    click: function click() {
      if (this.get('header.sort')) {
        if (this.action) {
          this.action(this.header.name);
        }
      }
    }
  });

  _exports.default = _default;
});