define("shared/components/tooltip-expiring/component", ["exports", "shared/components/tooltip-expiring/template", "shared/mixins/tooltip"], function (_exports, _template, _tooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tooltip.default, {
    layout: _template.default,
    model: Ember.computed.alias('tooltipService.tooltipOpts.model'),
    actions: {
      rotateCertificates: function rotateCertificates() {
        this.model.send('rotateCertificates');
        this.tooltipService.hide();
      }
    }
  });

  _exports.default = _default;
});