define("shared/components/form-node-taints/component", ["exports", "shared/components/form-node-taints/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['accordion-wrapper'],
    expandAll: null,
    expandOnInit: false,
    didReceiveAttrs: function didReceiveAttrs() {
      if (!Ember.get(this, 'expandFn')) {
        Ember.set(this, 'expandFn', function (item) {
          item.toggleProperty('expanded');
        });
      }
    }
  });

  _exports.default = _default;
});