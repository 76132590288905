define("nodes/components/node-template-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M/JUXwnf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"class\",\"row-check\"],[11,\"valign\",\"middle\"],[11,\"style\",\"padding-top: 2px;\"],[9],[0,\"\\n  \"],[1,[27,\"check-box\",null,[[\"nodeId\"],[[23,[\"model\",\"id\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[23,[\"dt\",\"state\"]]]]],[11,\"class\",\"state\"],[9],[0,\"\\n  \"],[1,[27,\"badge-state\",null,[[\"model\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[23,[\"dt\",\"name\"]]]]],[11,\"class\",\"clip\"],[9],[0,\"\\n  \"],[1,[23,[\"model\",\"displayName\"]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"showTransitioningMessage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[\"clip text-small \",[23,[\"model\",\"stateColor\"]]]]],[9],[1,[27,\"uc-first\",[[23,[\"model\",\"transitioningMessage\"]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[23,[\"dt\",\"owner\"]]]]],[11,\"class\",\"clip\"],[9],[0,\"\\n  \"],[1,[23,[\"model\",\"creator\",\"displayName\"]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[23,[\"dt\",\"provider\"]]]]],[11,\"class\",\"clip\"],[9],[0,\"\\n  \"],[1,[23,[\"model\",\"displayProvider\"]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[23,[\"dt\",\"location\"]]]]],[11,\"class\",\"clip\"],[9],[0,\"\\n  \"],[1,[23,[\"model\",\"displayLocation\"]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[23,[\"dt\",\"size\"]]]]],[11,\"class\",\"clip\"],[9],[0,\"\\n  \"],[1,[23,[\"model\",\"displaySize\"]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[23,[\"dt\",\"actions\"]]]]],[11,\"class\",\"actions\"],[9],[0,\"\\n  \"],[1,[27,\"action-menu\",null,[[\"model\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nodes/components/node-template-row/template.hbs"
    }
  });

  _exports.default = _default;
});