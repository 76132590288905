define('ember-math-helpers/helpers/acos', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.acos = acos;
  function acos(params) {
    return Math.acos(params[0]);
  }

  exports.default = Ember.Helper.helper(acos);
});