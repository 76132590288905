define('ember-math-helpers/helpers/log-e', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.logE = logE;
  function logE(params) {
    return Math.log(params[0]);
  }

  exports.default = Ember.Helper.helper(logE);
});