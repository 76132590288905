define("shared/utils/download-files", ["exports", "shared/utils/fetch-yaml", "shared/utils/util", "jszip", "file-saver"], function (_exports, _fetchYaml, _util, _jszip, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.downloadFile = downloadFile;
  _exports.generateZip = generateZip;
  _exports.downloadResourceYaml = downloadResourceYaml;

  function downloadFile(fileName, content) {
    var contentType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'text/plain;charset=utf-8';
    var blob = new Blob([content], {
      type: contentType
    });
    (0, _fileSaver.saveAs)(blob, fileName);
  } // [{name: 'file1', file: 'data'}, {name: 'file2', file: 'data2'}]


  function generateZip(files) {
    var zip = new _jszip.default();

    for (var i = 0; i < files.length; i++) {
      var file = files[i];
      zip.file(file.name, file.file);
    }

    return zip.generateAsync({
      type: 'blob'
    }).then(function (contents) {
      return contents;
    });
  }

  function downloadResourceYaml(resources) {
    if (!resources.length) {
      return;
    }

    if (resources.length <= 1) {
      var resource = resources[0];
      var yamlLink = resource.links.yaml;

      if (yamlLink) {
        yamlLink = (0, _util.addQueryParam)(yamlLink, 'export', 'true');
        (0, _fetchYaml.default)(yamlLink).then(function (yaml) {
          downloadFile("".concat(resource.name, ".yaml"), yaml);
        });
      }
    } else {
      var hashRequest = [];

      for (var i = 0; i < resources.length; i++) {
        var _resource = resources[i];
        var _yamlLink = _resource.links.yaml;

        if (_yamlLink) {
          _yamlLink = (0, _util.addQueryParam)(_yamlLink, 'export', 'true');
          hashRequest.push((0, _fetchYaml.default)(_yamlLink));
        }
      }

      Ember.RSVP.all(hashRequest).then(function (data) {
        var files = data.map(function (ele, index) {
          return {
            name: "".concat(resources[index].name, ".yaml"),
            file: ele
          };
        });
        generateZip(files).then(function (zip) {
          downloadFile("".concat(resources[0].type, ".zip"), zip, zip.type);
        });
      });
    }
  }
});