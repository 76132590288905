define('ember-math-helpers/helpers/sign', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sign = sign;
  function sign(params) {
    return Math.sign(params[0]);
  }

  exports.default = Ember.Helper.helper(sign);
});