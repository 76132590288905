define("shared/components/input-number/component", ["exports", "ui/utils/platform", "shared/components/input-number/template"], function (_exports, _platform, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function sanitize(val) {
    val = "".concat(val).trim().replace(/[^0-9.-]/g, '');
    val = val.substr(0, 1) + val.substr(1).replace('-', '');
    var idx = val.indexOf('.');

    if (idx >= 0) {
      var idx2 = val.indexOf('.', idx + 1);

      if (idx2 >= 0) {
        val = val.substr(0, idx2);
      }
    }

    if (idx === 0) {
      val = "0".concat(val);
    }

    return val;
  }

  var _default = Ember.TextField.extend({
    layout: _template.default,
    attributeBindings: ['pattern', 'inputmode'],
    pattern: '[0-9]*(\.[0-9]*)?',
    inputmode: 'numeric',
    type: Ember.computed(function () {
      return _platform.isMobile ? 'number' : 'text';
    }),
    _elementValueDidChange: function _elementValueDidChange() {
      var val = this.element.value;
      var cur = val;
      val = sanitize(val);
      var num = parseFloat(val);
      var max = parseFloat(this.get('max'));

      if (!isNaN(num) && !isNaN(max) && num > max) {
        val = "".concat(max);
      }

      if (cur !== val) {
        this.element.value = val;
      }

      this.set('value', val);
    },
    focusOut: function focusOut() {
      this._super.apply(this, arguments);

      var val = this.element.value;
      var cur = val;
      val = sanitize(val);
      var num = parseFloat(val);
      var min = parseFloat(this.get('min'));

      if (!isNaN(num) && !isNaN(min) && num < min) {
        val = "".concat(min);
      }

      if (cur !== val) {
        this.element.value = val;
        this.set('value', val);
      }
    }
  });

  _exports.default = _default;
});