define("shared/helpers/nl-to-br", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nlToBr = nlToBr;
  _exports.default = void 0;

  function nlToBr(params) {
    var val = Ember.Handlebars.Utils.escapeExpression(params[0] || '');
    return new Ember.String.htmlSafe(val.replace(/\n/g, '<br/>\n'));
  }

  var _default = Ember.Helper.helper(nlToBr);

  _exports.default = _default;
});