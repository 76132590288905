define("shared/components/principal-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f9OXcPGf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\"],[12,\"oninput\",[27,\"perform\",[[23,[\"search\"]]],[[\"value\"],[\"target.value\"]]]],[12,\"placeholder\",[27,\"t\",[[27,\"if\",[[27,\"eq\",[[23,[\"access\",\"provider\"]],\"githubconfig\"],null],\"inputIdentity.placeholder.generic\",\"inputIdentity.placeholder.github\"],null]],null]],[11,\"class\",\"form-control\"],[11,\"type\",\"text\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/principal-search/template.hbs"
    }
  });

  _exports.default = _default;
});