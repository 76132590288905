define("shared/mixins/promise-to-cb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    toCb: function toCb(name_or_fn) {
      var _this = this;

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return function (results, cb) {
        if (typeof results === 'function') {
          cb = results;
          results = null;
        }

        var promise;

        if (typeof name_or_fn === 'string') {
          promise = _this[name_or_fn].apply(_this, args.concat([results]));
        } else {
          promise = name_or_fn.apply(void 0, args.concat([results]));
        }

        promise.then(function (res) {
          cb(null, res);
        }).catch(function (err) {
          cb(err, null);
        });
      };
    }
  });

  _exports.default = _default;
});