define("shared/components/copy-to-clipboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m35cEcXc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"isSupported\"]]],null,{\"statements\":[[4,\"tooltip-element\",null,[[\"type\",\"model\",\"tooltipTemplate\",\"class\",\"textChangedEvent\",\"baseClass\",\"isCopyTo\",\"tooltipFor\"],[\"tooltip-basic\",[27,\"t\",[[27,\"if\",[[23,[\"model\"]],[23,[\"model\",\"tooltipText\"]],\"generic.emptyString\"],null]],null],\"tooltip-static\",\"copy-button-container bg-transparent\",[23,[\"textChangedEvent\"]],\"copy-clipboard\",true,\"copyToClipboard\"]],{\"statements\":[[4,\"copy-button\",null,[[\"class\",\"clipboardTarget\",\"clipboardText\",\"success\"],[[23,[\"buttonClasses\"]],[23,[\"target\"]],[23,[\"clipboardText\"]],\"alertSuccess\"]],{\"statements\":[[0,\"      \"],[14,1],[4,\"if\",[[23,[\"icon\"]]],null,{\"statements\":[[0,\" \"],[7,\"i\"],[12,\"class\",[28,[\"icon \",[21,\"icon\"]]]],[9],[10]],\"parameters\":[]},null],[4,\"if\",[[23,[\"buttonText\"]]],null,{\"statements\":[[0,\" \"],[1,[27,\"t\",[[23,[\"buttonText\"]]],null],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/copy-to-clipboard/template.hbs"
    }
  });

  _exports.default = _default;
});