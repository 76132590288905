define("nodes/custom-drivers/cluster-drivers/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        drivers: this.get('globalStore').findAll('kontainerDriver')
      });
    }
  });

  _exports.default = _default;
});