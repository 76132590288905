define("shared/components/storage-class/provisioner-portworx-volume/component", ["exports", "shared/components/storage-class/provisioner-portworx-volume/template", "shared/mixins/storage-class-provisioner"], function (_exports, _template, _storageClassProvisioner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FIELDS = ['fs', 'block_size', 'repl', 'io_priority', 'snap_interval', 'aggregation_level', 'ephemeral'];

  var _default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,
    provisioner: 'portworx-volume',
    fields: FIELDS
  });

  _exports.default = _default;
});