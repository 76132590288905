define("global-admin/multi-cluster-apps/catalog/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['category'],
    category: ''
  });

  _exports.default = _default;
});