define("shared/components/modal-container-stop/component", ["exports", "shared/mixins/modal-base", "ui/utils/platform", "shared/components/modal-container-stop/template"], function (_exports, _modalBase, _platform, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['medium-modal'],
    alternateLabel: _platform.alternateLabel,
    resources: Ember.computed.alias('modalService.modalOpts.model'),
    actions: {
      stop: function stop() {
        var _this = this;

        this.get('resources').forEach(function (resource) {
          resource.send('stop');
        });
        Ember.run.next(function () {
          _this.send('cancel');
        });
      }
    }
  });

  _exports.default = _default;
});