define("shared/components/schema/input-relative-service/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T9mC9F/z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"input-group\"],[11,\"style\",\"width: 100%\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"custom\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"input\",null,[[\"value\",\"classNames\",\"placeholder\"],[[23,[\"value\"]],[23,[\"inputClass\"]],\"[stack-name/]service-name\"]]],false],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"input-group-addon bg-default p-0\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"input-group-btn p-0\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn bg-transparent text-info pl-10 pr-10\"],[9],[7,\"i\"],[11,\"class\",\"icon icon-close\"],[9],[10],[3,\"action\",[[22,0,[]],\"standard\"]],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"new-select\",null,[[\"classNames\",\"value\",\"content\",\"optionValuePath\",\"optionLabelPath\",\"optionGroupPath\",\"prompt\",\"localizedPrompt\"],[[23,[\"inputClass\"]],[23,[\"value\"]],[23,[\"list\"]],\"value\",\"name\",\"group\",\"schema.inputService.prompt\",true]]],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/schema/input-relative-service/template.hbs"
    }
  });

  _exports.default = _default;
});