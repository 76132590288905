define("global-admin/components/form-global-roles/component", ["exports", "global-admin/components/form-global-roles/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var USER = 'user';
  var ADMIN = 'admin';
  var BASE = 'user-base';
  var LOGIN_ACCESS_ROLES = [{
    name: USER,
    translationKey: 'formGlobalRoles.mode.user.label',
    descriptionTranslationKey: 'formGlobalRoles.mode.user.detail'
  }, {
    name: ADMIN,
    translationKey: 'formGlobalRoles.mode.admin.label',
    descriptionTranslationKey: 'formGlobalRoles.mode.admin.detail'
  }, {
    name: BASE,
    translationKey: 'formGlobalRoles.mode.userBase.label',
    descriptionTranslationKey: 'formGlobalRoles.mode.userBase.detail'
  }];

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    layout: _template.default,
    user: null,
    mode: null,
    allRoles: null,
    _boundSave: null,
    _boundValidator: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'allRoles', this.globalStore.all('globalRole'));
      this.initRoles();
      Ember.setProperties(this, {
        '_boundSave': this.save.bind(this),
        '_boundValidator': this.confirmUserCanLogIn.bind(this)
      });
      this.setSave(this._boundSave);
      this.setGRValidator(this._boundValidator);
    },
    actions: {
      toggle: function toggle(e) {
        var $target = Ember.$(e.target);
        var $row = $target.closest('.input-group');
        var check = Ember.$('input[type =checkbox]', $row)[0];

        if (check && e.target !== check && e.target.tagName !== 'LABEL') {
          check.click();
        }
      }
    },
    make: function make(role) {
      return this.globalStore.createRecord({
        type: 'globalRoleBinding',
        globalRoleId: Ember.get(role, 'id'),
        userId: Ember.get(this, 'user.id'),
        subjectKind: 'User'
      });
    },
    save: function save() {
      var _this = this;

      Ember.set(this, 'errors', null);
      var allRoles = [].concat(_toConsumableArray(this.baseRoles), _toConsumableArray(this.additionalRoles)); // all active non-existant roles remapped to an array of the role resources for the save

      var add = allRoles.filterBy('active').filterBy('existing', false).map(function (r) {
        return _this.make(r.role);
      }); // all inactive existing roles remapped to an array of the role resources for the remove save

      var remove = allRoles.filterBy('active', false).filterBy('existing').map(function (r) {
        return r.existing;
      });
      return Ember.RSVP.all(add.map(function (x) {
        return x.save();
      })).then(function () {
        return Ember.RSVP.all(remove.map(function (x) {
          return x.delete();
        })).then(function () {
          return true;
        });
      });
    },
    initRoles: function initRoles() {
      var allRoles = this.allRoles,
          user = this.user;
      var _user$globalRoleBindi = user.globalRoleBindings,
          usersGlobalRoleBindings = _user$globalRoleBindi === void 0 ? [] : _user$globalRoleBindi;
      var visibleRoles = allRoles.filterBy('isHidden', false);
      var baseRoles = [ADMIN, USER, BASE].map(function (r) {
        return allRoles.findBy('id', r);
      });
      Ember.setProperties(this, {
        baseRoles: baseRoles.map(roleMapper),
        additionalRoles: visibleRoles.map(roleMapper)
      });

      if (this.mode === 'new') {
        this.populateDefaultRoles();
      }

      function roleMapper(role) {
        var binding = usersGlobalRoleBindings.findBy('globalRole', role) || false;
        var translationKey = null;
        var descriptionTranslationKey = null;

        if (baseRoles.findBy('id', role.id)) {
          var roleMatch = LOGIN_ACCESS_ROLES.findBy('name', role.id);
          translationKey = roleMatch.translationKey;
          descriptionTranslationKey = roleMatch.descriptionTranslationKey;
        }

        return {
          role: role,
          translationKey: translationKey,
          descriptionTranslationKey: descriptionTranslationKey,
          active: !!binding,
          existing: binding
        };
      }
    },
    confirmUserCanLogIn: function confirmUserCanLogIn() {
      var rolesThatCanLogIn = [ADMIN, USER, BASE];
      var allRoles = [].concat(_toConsumableArray(this.baseRoles), _toConsumableArray(this.additionalRoles));
      var roles = allRoles.filterBy('active').map(function (r) {
        return r.role;
      });
      return roles.any(function (role) {
        return rolesThatCanLogIn.includes(role.id);
      });
    },
    populateDefaultRoles: function populateDefaultRoles() {
      this.baseRoles.forEach(function (r) {
        if (r.role && r.role.id === 'user') {
          Ember.set(r, 'active', true);
        }
      });
      this.additionalRoles.forEach(function (r) {
        if (r.role && r.role.newUserDefault) {
          Ember.set(r, 'active', true);
        }
      });
    }
  });

  _exports.default = _default;
});