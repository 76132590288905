define("global-admin/security/authentication/googleoauth/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    model: function model() {
      var gs = Ember.get(this, 'globalStore');
      return Ember.RSVP.hash({
        googleConfig: gs.find('authconfig', 'googleoauth', {
          forceReload: true
        }),
        principals: gs.all('principal')
      }).then(function (_ref) {
        var googleConfig = _ref.googleConfig,
            principals = _ref.principals;
        return {
          googleConfig: googleConfig,
          originalConfig: googleConfig.clone(),
          principals: principals
        };
      }).catch(function (e) {
        return e;
      });
    },
    setupController: function setupController(controller, model) {
      Ember.setProperties(controller, {
        model: model,
        confirmDisable: false,
        testing: false,
        organizations: Ember.get(this, 'session.orgs') || [],
        errors: null
      });
      Ember.set(controller, 'saved', true);
    },
    resetController: function resetController(controller) {
      Ember.set(controller, 'editing', false);
    }
  });

  _exports.default = _default;
});