define("global-admin/security/policies/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      return this.get('globalStore').find('podSecurityPolicyTemplate').then(function (policies) {
        var policy = policies.findBy('id', params.policy_id);

        if (!policy) {
          _this.replaceWith('security.policies.index');
        }

        return {
          policy: policy
        };
      });
    }
  });

  _exports.default = _default;
});