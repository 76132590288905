define("shared/components/input-int/component", ["exports", "shared/components/input-int/template", "shared/components/input-integer/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    _elementValueDidChange: function _elementValueDidChange() {
      var val = this.element.value;
      var cur = val;
      val = (0, _component.sanitize)(val);
      var num = parseInt(val, 10);
      var max = parseInt(Ember.get(this, 'max'), 10);
      var min = parseInt(Ember.get(this, 'min'), 10);

      if (!isNaN(num) && !isNaN(max) && num > max) {
        val = "".concat(max);
      }

      if (!isNaN(min) && (isNaN(num) || num < min)) {
        val = "".concat(min);
      }

      if (cur !== val) {
        this.element.value = val;
      }

      Ember.set(this, 'value', val && parseInt(val, 10));
    }
  });

  _exports.default = _default;
});