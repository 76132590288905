define("global-admin/clusters/new/launch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zZeaMTLw",
    "block": "{\"symbols\":[],\"statements\":[[5,\"cru-cluster\",[],[[\"@applyClusterTemplate\",\"@model\",\"@provider\",\"@mode\",\"@close\",\"@clusterDriverError\",\"@clusterTemplateRevisionId\"],[[27,\"not\",[[27,\"is-empty\",[[23,[\"clusterTemplateRevision\"]]],null]],null],[21,\"model\"],[23,[\"model\",\"provider\"]],\"new\",[27,\"action\",[[22,0,[]],\"close\"],null],[27,\"action\",[[22,0,[]],\"clusterDriverErrorAndTransition\"],null],[27,\"mut\",[[23,[\"clusterTemplateRevision\"]]],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/clusters/new/launch/template.hbs"
    }
  });

  _exports.default = _default;
});