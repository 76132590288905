define("shared/mixins/intl-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    attributeBindings: ['i18nPlaceholder:placeholder'],
    i18nPlaceholder: function () {
      return this.get('intl').t(this.get('placeholder'));
    }.property('placeholder', 'intl.locale')
  });

  _exports.default = _default;
});