define("global-admin/global-registry/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NAME = 'global-registry';

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    model: function model() {
      var store = Ember.get(this, 'globalStore');
      var clusters = store.all('cluster');
      var cluster = clusters.findBy('id', 'local');
      var globalRegistryEnabled = store.all('setting').findBy('id', 'global-registry-enabled');

      if (!cluster) {
        return {
          showForm: false,
          globalRegistryEnabled: globalRegistryEnabled
        };
      }

      var project = Ember.get(cluster, 'systemProject');
      var apps = project.followLink('apps');
      var namespaces = cluster.followLink('namespaces');
      var storageClasses = cluster.followLink('storageClasses');
      var persistentVolumeClaims = project.followLink('persistentVolumeClaims');
      return Ember.RSVP.all([apps, namespaces, storageClasses, persistentVolumeClaims]).then(function (data) {
        var apps = data[0] || [];
        var storageClasses = data[2] || [];
        var persistentVolumeClaims = data[3] || [];
        return {
          app: apps.findBy('name', NAME),
          nsExists: true,
          cluster: cluster,
          project: project,
          storageClasses: storageClasses,
          persistentVolumeClaims: persistentVolumeClaims.filter(function (p) {
            return p.namespaceId === 'cattle-system' && p.state === 'bound';
          }),
          showForm: true
        };
      });
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});