define("shared/components/storage-class/provisioner-cinder/component", ["exports", "shared/components/storage-class/provisioner-cinder/template", "shared/mixins/storage-class-provisioner"], function (_exports, _template, _storageClassProvisioner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,
    provisioner: 'cinder',
    type: null,
    availability: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var changes = {};
      changes['type'] = Ember.get(this, 'parameters.type') || '';
      var zone = Ember.get(this, 'parameters.availability');

      if (zone) {
        changes['zoneAutomatic'] = false;
        changes['availability'] = zone;
      } else {
        changes['zoneAutomatic'] = true;
        changes['availability'] = '';
      }

      Ember.setProperties(this, changes);
    },
    updateParams: function updateParams() {
      var type = Ember.get(this, 'type');
      var out = {};

      if (type) {
        out['type'] = type;
      }

      var zoneAutomatic = Ember.get(this, 'zoneAutomatic');

      if (!zoneAutomatic) {
        var zones = (Ember.get(this, 'availability') || '').trim();

        if (zones) {
          out['availability'] = zones;
        }
      }

      Ember.set(this, 'parameters', out);
    }
  });

  _exports.default = _default;
});