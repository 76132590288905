define("global-admin/components/global-dns-entry-row/component", ["exports", "global-admin/components/global-dns-entry-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'tr',
    classNames: ['main-row'],
    entry: null
  });

  _exports.default = _default;
});