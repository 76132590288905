define("ember-credit-card/templates/components/credit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5tNCwjYm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"showCard\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\"],[11,\"class\",\"card-wrapper\"],[9],[10],[0,\"\\n\\t\"],[7,\"br\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"form\"],[11,\"id\",\"ember-credit-card-form\"],[9],[0,\"\\n\\t\\t\"],[1,[27,\"input\",null,[[\"type\",\"name\",\"value\",\"placeholder\"],[\"text\",\"number\",[23,[\"number\"]],\"Credit Card Number\"]]],false],[0,\"\\n\\t\\t\"],[1,[27,\"input\",null,[[\"type\",\"name\",\"value\",\"placeholder\"],[\"text\",\"name\",[23,[\"name\"]],\"Name on Credit Card\"]]],false],[0,\"\\n\\t\\t\"],[1,[27,\"input\",null,[[\"type\",\"name\",\"value\"],[\"text\",\"expiry\",[23,[\"expiry\"]]]]],false],[0,\"\\n\\t\\t\"],[1,[27,\"input\",null,[[\"type\",\"name\",\"value\"],[\"text\",\"cvc\",[23,[\"cvc\"]]]]],false],[0,\"\\n\\t\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-credit-card/templates/components/credit-card.hbs"
    }
  });

  _exports.default = _default;
});