define("shared/shibboleth-auth/service", ["exports", "shared/utils/constants", "shared/utils/util", "shared/settings/service"], function (_exports, _constants, _util, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    globalStore: Ember.inject.service(),
    session: Ember.inject.service(),
    access: Ember.inject.service(),
    app: Ember.inject.service(),
    hasToken: null,
    parseIdentity: Ember.observer('hasToken', function () {
      var locToken = this.get('hasToken');

      if (locToken) {
        var userIdent = locToken.userIdentity;
        this.set("session.".concat(_constants.default.SESSION.IDENTITY), userIdent);
      }
    }),
    getToken: function getToken() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.get('globalStore').rawRequest({
          url: 'token'
        }).then(function (xhr) {
          resolve(xhr.body.data[0]);
          return;
        }).catch(function (err) {
          reject(err);
        });
      });
    },
    buildRedirectUrl: function buildRedirectUrl(url) {
      var test = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var redirect = url;
      var qp = {
        redirectBackBase: window.location.origin,
        redirectBackPath: '/login/shibboleth-auth/'
      };

      if (test) {
        qp.redirectBackPath = "".concat(qp.redirectBackPath, "?shibbolethTest=1");
      }

      return _util.default.addQueryParams(redirect, qp);
    },
    waitAndRefresh: function waitAndRefresh(url) {
      $('#loading-underlay, #loading-overlay').removeClass('hide').show(); // eslint-disable-line

      setTimeout(function () {
        window.location.href = url || window.location.href;
      }, 1000);
    },
    authorizeRedirect: function authorizeRedirect(url) {
      window.location.href = url;
    },
    authenticationSucceeded: function authenticationSucceeded(model) {
      var _this2 = this;

      var url = window.location.href;
      model = model.clone();
      model.setProperties({
        'enabled': true,
        'accessMode': 'restricted',
        'allowedIdentities': []
      });
      model.save().then(function () {
        // for some reason we can't get past here because we've set auth true?
        return _this2.get('globalStore').find('setting', (0, _service.denormalizeName)(_constants.default.SETTING.API_HOST)).then(function (setting) {
          if (setting.get('value')) {
            _this2.waitAndRefresh(url);
          } else {
            // Default the api.host so the user won't have to set it in most cases
            if (window.location.hostname === 'localhost') {
              _this2.waitAndRefresh(url);
            } else {
              setting.set('value', window.location.origin);
              return setting.save().then(function () {
                _this2.waitAndRefresh(url);
              });
            }
          }
        });
      }).catch(function () {
        _this2.set('access.enabled', false);
      });
    }
  });

  _exports.default = _default;
});