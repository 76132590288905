define("shared/components/badge-state/component", ["exports", "shared/components/badge-state/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'SPAN',
    classNames: ['badge-state', 'vertical-middle'],
    classNameBindings: ['model.stateBackground', 'capitalizeText:text-capitalize'],
    capitalizeText: true
  });

  _exports.default = _default;
});