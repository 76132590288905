define("shared/components/modal-delete-eks-cluster/component", ["exports", "shared/components/modal-delete-eks-cluster/template", "shared/mixins/new-or-edit", "shared/mixins/modal-base"], function (_exports, _template, _newOrEdit, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    modalService: Ember.inject.service('modal'),
    intl: Ember.inject.service(),
    classNames: ['medium-modal'],
    clone: null,
    errors: null,
    updateKeys: true,
    layout: _template.default,
    originalModel: Ember.computed.alias('modalService.modalOpts.model'),
    primaryResource: Ember.computed.alias('originalModel'),
    actions: {
      confirmKeys: function confirmKeys(cb) {
        var _this = this;

        var errors = [];

        if (this.isValid()) {
          this.canListEksClusters().then(function () {
            Ember.get(_this, 'primaryResource').save().then(function () {
              Ember.get(_this, 'primaryResource').delete().then(function () {
                cb();

                _this.send('cancel');
              }).catch(function (e) {
                errors.push(e);
                Ember.set(_this, 'errors', errors);
                cb(false, e);
              });
            }).catch(function (e) {
              errors.push(e);
              Ember.set(_this, 'errors', errors);
              cb(false, e);
            });
          }).catch(function (e) {
            errors.push(e);
            Ember.set(_this, 'errors', errors);
            cb(false, e);
          });
        } else {
          cb(false);
        }
      }
    },
    canListEksClusters: function canListEksClusters() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var eks = new AWS.EKS(_this2.authCreds());
        eks.listClusters({}, function (err, clusters) {
          if (err) {
            return reject("".concat(Ember.get(err, 'statusCode'), " ").concat(Ember.get(err, 'code'), ": ").concat(Ember.get(err, 'message')));
          }

          return resolve({
            clusters: clusters.clusters
          });
        });
      });
    },
    isValid: function isValid() {
      var config = Ember.get(this, 'primaryResource.amazonElasticContainerServiceConfig');
      var errors = [];
      var accessKey = config.accessKey,
          secretKey = config.secretKey,
          sessionToken = config.sessionToken;

      if (!accessKey) {
        errors.push(Ember.get(this, 'intl').t('deleteEksCluster.error.accessKey'));
      }

      if (!secretKey) {
        errors.push(Ember.get(this, 'intl').t('deleteEksCluster.error.secretKey'));
      }

      if (!sessionToken) {
        errors.push(Ember.get(this, 'intl').t('deleteEksCluster.error.sessionToken'));
      }

      Ember.set(this, 'errors', errors);
      return errors.length > 0 ? false : true;
    },
    authCreds: function authCreds() {
      var config = Ember.get(this, 'primaryResource.amazonElasticContainerServiceConfig');
      var accessKey = config.accessKey,
          secretKey = config.secretKey,
          region = config.region,
          sessionToken = config.sessionToken;
      accessKey = accessKey.trim();
      secretKey = secretKey.trim();
      Ember.setProperties(config, {
        accessKey: accessKey,
        secretKey: secretKey
      });
      var auth = {
        region: region,
        accessKeyId: accessKey,
        secretAccessKey: secretKey
      };

      if (sessionToken) {
        Ember.set(auth, 'sessionToken', sessionToken);
      }

      return auth;
    }
  });

  _exports.default = _default;
});