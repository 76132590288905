define("shared/components/schema/input-hostname/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "henLdFa5",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"settings\",\"ingress-ip-domain\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"radio\"],[9],[0,\"\\n    \"],[7,\"label\"],[9],[1,[27,\"radio-button\",null,[[\"selection\",\"value\"],[[23,[\"mode\"]],\"automatic\"]]],false],[0,\" \"],[1,[27,\"t\",[\"formIngress.hostMode.automatic\"],[[\"domain\",\"htmlSafe\"],[[23,[\"settings\",\"ingress-ip-domain\"]],true]]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"radio\"],[9],[0,\"\\n    \"],[7,\"label\"],[9],[0,\"\\n      \"],[1,[27,\"radio-button\",null,[[\"selection\",\"value\"],[[23,[\"mode\"]],\"manual\"]]],false],[0,\" \\n      \"],[1,[27,\"t\",[\"formIngress.hostMode.manual\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"mode\"]],\"manual\"],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"schema/input-string\",null,[[\"value\"],[[23,[\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"schema/input-string\",null,[[\"value\"],[[23,[\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/schema/input-hostname/template.hbs"
    }
  });

  _exports.default = _default;
});