define("shared/components/schema/input-enum/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gG6fqPfG",
    "block": "{\"symbols\":[\"choice\"],\"statements\":[[7,\"select\"],[11,\"name\",\"\"],[11,\"class\",\"form-control mb-10\"],[12,\"onChange\",[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"value\"]]],null]],[[\"value\"],[\"target.value\"]]]],[9],[0,\"\\n  \"],[7,\"option\"],[11,\"value\",\"\"],[9],[1,[27,\"t\",[\"schema.inputEnum.option\"],null],false],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"field\",\"options\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[12,\"value\",[22,1,[]]],[12,\"selected\",[27,\"eq\",[[22,1,[]],[23,[\"value\"]]],null]],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[22,1,[]],\"LoadBalancer\"],null]],null,{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"model.service.displayKind.loadBalancer\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[22,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/schema/input-enum/template.hbs"
    }
  });

  _exports.default = _default;
});