define("global-admin/cluster-templates/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    model: function model() {
      var gs = this.globalStore;
      return Ember.RSVP.hash({
        clusterTemplates: gs.findAll('clustertemplate'),
        clusterTemplateRevisions: gs.findAll('clustertemplaterevision')
      });
    }
  });

  _exports.default = _default;
});