define("global-admin/clusters/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    shortcuts: {
      'g': 'toggleGrouping'
    },
    afterModel: function afterModel() {
      return Ember.RSVP.hash(Ember.get(this, 'globalStore').findAll('clusterTemplateRevision'), Ember.get(this, 'globalStore').findAll('clusterTemplate'));
    },
    actions: {
      toggleGrouping: function toggleGrouping() {
        var _this = this;

        var choices = ['list', 'grouped'];
        var cur = this.get('controller.mode');
        var neu = choices[(choices.indexOf(cur) + 1) % choices.length];
        Ember.run.next(function () {
          _this.set('controller.mode', neu);
        });
      }
    }
  });

  _exports.default = _default;
});