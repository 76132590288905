define("global-admin/components/modal-add-cloud-credential/component", ["exports", "shared/mixins/modal-base", "global-admin/components/modal-add-cloud-credential/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['large-modal', 'alert'],
    mode: Ember.computed.alias('modalOpts.mode'),
    actions: {
      errorHandler: function errorHandler(err) {
        var shouldClearPreviousErrors = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var errors = this.errors;

        if (shouldClearPreviousErrors) {
          errors = Ember.set(this, 'errors', []);
        }

        if (errors) {
          if (Ember.isArray(err)) {
            errors.pushObjects(err);
          } else {
            errors.pushObject(err);
          }
        } else {
          errors = [err];
        }

        Ember.set(this, 'errors', errors);
      }
    }
  });

  _exports.default = _default;
});