define("shared/components/form-ingress-rule/component", ["exports", "shared/components/form-ingress-rule/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _template.default,
    ingress: null,
    rules: null,
    editing: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.initRules();
    },
    didInsertElement: function didInsertElement() {
      if (Ember.get(this, 'rules.length') === 0) {
        this.send('addRule');
      }
    },
    actions: {
      addRule: function addRule() {
        var rule = Ember.Object.create({
          host: '',
          new: true,
          paths: []
        });
        Ember.get(this, 'rules').pushObject(rule);
      },
      removeRule: function removeRule(rule) {
        Ember.get(this, 'rules').removeObject(rule);
      }
    },
    rulesChanged: Ember.observer('rules.@each.{paths,host,defaultBackend}', function () {
      var rules = Ember.get(this, 'rules');
      Ember.set(this, 'ingress.rules', rules.filter(function (rule) {
        if (rule.defaultBackend) {
          return false;
        }

        if (Object.keys(Ember.get(rule, 'paths')).length === 0) {
          return false;
        }

        return true;
      }));
    }),
    initRules: function initRules() {
      var rules = [];
      (Ember.get(this, 'ingress.rules') || []).forEach(function (rule) {
        rules.push(rule);
      });
      var defaultBackend = Ember.get(this, 'ingress.defaultBackend');

      if (defaultBackend) {
        rules.push({
          defaultBackend: true,
          paths: [defaultBackend]
        });
      }

      Ember.set(this, 'rules', rules);
    }
  });

  _exports.default = _default;
});