define("shared/components/progress-bar/component", ["exports", "shared/components/progress-bar/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    color: '',
    min: 0,
    value: 0,
    max: 100,
    zIndex: null,
    didInsertElement: function didInsertElement() {
      this.percentDidChange();
      this.zIndexDidChange();
    },
    percentDidChange: Ember.observer('percent', function () {
      this.$('.progress-bar').css('width', "".concat(Ember.get(this, 'percent'), "%"));
    }),
    zIndexDidChange: Ember.observer('zIndex', function () {
      this.$().css('zIndex', Ember.get(this, 'zIndex') || 'inherit');
    }),
    tooltipContent: Ember.computed('percent', function () {
      return "".concat(Ember.get(this, 'percent'), " %");
    }),
    percent: Ember.computed('min', 'max', 'value', function () {
      var min = Ember.get(this, 'min');
      var max = Ember.get(this, 'max');
      var value = Math.max(min, Math.min(max, Ember.get(this, 'value')));
      var per = value / (max - min) * 100; // Percent 0-100

      per = Math.round(per * 100) / 100; // Round to 2 decimal places

      return per;
    }),
    colorClass: Ember.computed('color', function () {
      var color = Ember.get(this, 'color');

      if (!color) {
        return;
      }

      return "progress-bar-".concat(color.replace(/^progress-bar-/, ''));
    })
  });

  _exports.default = _default;
});