define("shared/helpers/array-includes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    _haystack: null,
    shouldUpdate: Ember.observer('_haystack.[]', function () {
      this.recompute();
    }),
    compute: function compute(params) {
      var haystack = params[0];

      if (!haystack) {
        return;
      }

      var _haystack = this.get('_haystack');

      if (haystack !== _haystack) {
        _haystack = new Ember.A(haystack);
        this.set('_haystack', _haystack);
      }

      var result;

      for (var i = 1; i < params.length; i++) {
        var needle = params[i];

        if (needle) {
          result = _haystack.includes(needle);
        }

        if (!result) {
          return false;
        }
      }

      return result;
    }
  });

  _exports.default = _default;
});