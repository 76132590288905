define("global-admin/settings/features/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    model: function model()
    /* params, transition */
    {
      return this.globalStore.findAll('feature');
    }
  });

  _exports.default = _default;
});