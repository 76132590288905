define("global-admin/clusters/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    provider: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.router.on('routeDidChange', function (transition) {
        if (transition.to && transition.to.params.provider) {
          _this.set('provider', transition.to.params.provider);
        }
      });
    }
  });

  _exports.default = _default;
});