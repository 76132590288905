define("global-admin/components/new-edit-role/component", ["exports", "ui/mixins/new-or-edit", "ui/utils/constants", "global-admin/components/new-edit-role/template"], function (_exports, _newOrEdit, _constants, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ruleVerbs = _constants.default.RULE_VERBS.map(function (verb) {
    return "rolesPage.new.form.allow.".concat(verb);
  });

  var BASIC_CONTEXT = [{
    label: 'All',
    value: ''
  }, {
    label: 'Project',
    value: 'project'
  }, {
    label: 'Cluster',
    value: 'cluster'
  }];

  var _default = Ember.Component.extend(_newOrEdit.default, {
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _template.default,
    model: null,
    ruleArray: null,
    roleArray: null,
    readOnly: null,
    roleType: null,
    contexts: BASIC_CONTEXT,
    ruleVerbs: ruleVerbs,
    primaryResource: Ember.computed.alias('model.role'),
    isGlobal: Ember.computed.equal('roleType', 'global'),
    readOnlyBuiltInOrGlobal: Ember.computed.or('readOnly', 'builtIn', 'isGlobal'),
    init: function init() {
      this._super.apply(this, arguments);

      var ruleArray = [];
      var model = Ember.get(this, 'primaryResource');
      (Ember.get(model, 'rules') || []).filter(function (r) {
        return r.resources || r.nonResourceURLs;
      }).forEach(function (rule) {
        ruleArray.push(rule);
      });
      var roleArray = (Ember.get(model, 'roleTemplateIds') || []).map(function (id) {
        return {
          roleId: id
        };
      });
      this.setProperties({
        ruleArray: ruleArray,
        roleArray: roleArray
      });
    },
    actions: {
      cancel: function cancel() {
        this.goBack();
      },
      addRule: function addRule() {
        Ember.get(this, 'ruleArray').pushObject({
          apiGroups: ['*'],
          type: 'policyRule',
          resource: null,
          resources: [],
          verbs: []
        });
      },
      removeRule: function removeRule(obj) {
        Ember.get(this, 'ruleArray').removeObject(obj);
      },
      addOtherRole: function addOtherRole() {
        Ember.get(this, 'roleArray').pushObject({
          roleId: null
        });
      },
      removeOtherRole: function removeOtherRole(obj) {
        Ember.get(this, 'roleArray').removeObject(obj);
      }
    },
    readableRole: Ember.computed('roleType', function () {
      return Ember.get(this, 'roleType').capitalize();
    }),
    isDefault: Ember.computed('roleType', {
      get: function get()
      /* key */
      {
        var model = Ember.get(this, 'model.role');
        var type = Ember.get(this, 'roleType');
        var field = this.getDefaultField(type);
        return Ember.get(model, field);
      },
      set: function set(key, value) {
        var model = Ember.get(this, 'model.role');
        var type = Ember.get(this, 'roleType');
        var field = this.getDefaultField(type);
        return model.set(field, value);
      }
    }),
    builtIn: Ember.computed('model.role.builtin', function () {
      return Ember.get(this, 'model.role.builtin') === true;
    }),
    otherRoles: Ember.computed('model.role.id', 'model.roles.@each.id', function () {
      var _this = this;

      return Ember.get(this, 'model.roles').filter(function (role) {
        return Ember.get(_this, 'model.role.id') !== role.id;
      });
    }),
    getDefaultField: function getDefaultField(type) {
      var out = '';

      switch (type) {
        case 'global':
          out = 'newUserDefault';
          break;

        case 'cluster':
          out = 'clusterCreatorDefault';
          break;

        case 'project':
          out = 'projectCreatorDefault';
          break;

        default:
          break;
      }

      return out;
    },
    goBack: function goBack() {
      Ember.get(this, 'router').transitionTo('global-admin.security.roles.index', {
        queryParams: {
          context: Ember.get(this, 'roleType')
        }
      });
    },
    validate: function validate() {
      this._super();

      var errors = Ember.get(this, 'errors') || [];

      if ((Ember.get(this, 'primaryResource.name') || '').trim().length === 0) {
        errors.push(Ember.get(this, 'intl').findTranslationByKey('rolesPage.new.errors.nameReq'));
      }

      Ember.set(this, 'errors', errors);
      return Ember.get(this, 'errors.length') === 0;
    },
    willSave: function willSave() {
      var role = Ember.get(this, 'model.role');
      var actualRules = []; // Create the actual rules

      var rules = Ember.get(this, 'ruleArray');
      var obj;

      for (var i = rules.length - 1; i >= 0; i--) {
        obj = rules[i];

        if (obj.resources && obj.resources.length && obj.verbs.length) {
          (function () {
            var resources = [];
            obj.resources.forEach(function (r) {
              resources.pushObjects((r || '').split(',').filter(function (r) {
                return r;
              }));
            });
            actualRules.push({
              type: obj.type,
              apiGroups: obj.apiGroups.slice(),
              verbs: obj.verbs.slice(),
              resources: resources
            });
          })();
        } else {
          rules.removeObject(obj);
        }
      }

      role.set('rules', actualRules); // Add roles

      var roles = Ember.get(this, 'roleArray');

      for (var _i = roles.length - 1; _i >= 0; _i--) {
        obj = roles[_i];

        if (!obj.roleId) {
          roles.removeObject(obj);
        }
      }

      role.set('roleTemplateIds', roles.map(function (obj) {
        return obj.roleId;
      }));
      return this._super.apply(this, arguments);
    },
    doneSaving: function doneSaving() {
      this.goBack();
    }
  });

  _exports.default = _default;
});