define("shared/helpers/date-str", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateStr = dateStr;
  _exports.default = void 0;

  function dateStr(params, options) {
    var format = 'MMM DD, YYYY hh:mm:ss A';

    if (options && options.format) {
      format = options.format;
    }

    return (0, _moment.default)(params[0]).format(format);
  }

  var _default = Ember.Helper.helper(dateStr);

  _exports.default = _default;
});