define("shared/components/schema/input-hostname/component", ["exports", "shared/components/schema/input-hostname/template", "shared/utils/constants"], function (_exports, _template, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    layout: _template.default,
    value: '',
    mode: 'automatic',
    init: function init() {
      this._super.apply(this, arguments);

      var xip = Ember.get(this, "settings.".concat(_constants.default.SETTING.INGRESS_IP_DOMAIN));
      var host = Ember.get(this, 'value');

      if (host && host === xip) {
        Ember.set(this, 'mode', 'automatic');
      } else {
        Ember.set(this, 'mode', 'manual');
      }
    },
    modeChanged: Ember.observer('mode', function () {
      var mode = Ember.get(this, 'mode');
      var xip = Ember.get(this, "settings.".concat(_constants.default.SETTING.INGRESS_IP_DOMAIN));

      if (mode === 'automatic') {
        Ember.set(this, 'value', xip);
      } else {
        if (Ember.get(this, 'value') === xip) {
          Ember.set(this, 'value', '');
        }
      }
    })
  });

  _exports.default = _default;
});