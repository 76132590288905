enifed("ember-template-compiler/tests/plugins/transform-input-type-syntax-test", ["ember-template-compiler/index", "internal-test-helpers"], function (_index, _internalTestHelpers) {
  "use strict";

  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: input type syntax', class extends _internalTestHelpers.AbstractTestCase {
    ['@test Can compile an {{input}} helper that has a sub-expression value as its type'](assert) {
      assert.expect(0);
      (0, _index.compile)(`{{input type=(if true 'password' 'text')}}`);
    }

    ['@test Can compile an {{input}} helper with a string literal type'](assert) {
      assert.expect(0);
      (0, _index.compile)(`{{input type='text'}}`);
    }

    ['@test Can compile an {{input}} helper with a type stored in a var'](assert) {
      assert.expect(0);
      (0, _index.compile)(`{{input type=_type}}`);
    }

  });
});