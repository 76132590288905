define("shared/endpoint/service", ["exports", "shared/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    'tab-session': Ember.inject.service('tab-session'),
    settings: Ember.inject.service(),
    app: Ember.inject.service(),
    absolute: Ember.computed("settings.".concat(_constants.default.SETTING.API_HOST), 'app.apiServer', function () {
      var setting = this.get("settings.".concat(_constants.default.SETTING.API_HOST));

      if (setting && setting.indexOf('http') !== 0) {
        setting = "http://".concat(setting);
      }

      var url = setting || this.get('app.apiServer'); // If the URL is relative, add on the current base URL from the browser

      if (url.indexOf('http') !== 0) {
        url = "".concat(window.location.origin, "/").concat(url.replace(/^\/+/, ''));
      } // URL must end in a single slash


      url = "".concat(url.replace(/\/+$/, ''), "/");
      return url;
    }),
    host: Ember.computed('absolute', function () {
      var a = document.createElement('a');
      a.href = this.get('absolute');
      return a.host;
    }),
    origin: Ember.computed('absolute', function () {
      var a = document.createElement('a');
      a.href = this.get('absolute');
      return a.origin;
    }),
    api: Ember.computed('absolute', 'app.{apiEndpoint}', function () {
      // Strip trailing slash off of the absoluteEndpoint
      var base = this.get('absolute').replace(/\/+$/, ''); // Add a single slash

      base += '/';
      var current = this.get('app.apiEndpoint').replace(/^\/+/, '');
      var authBase = "".concat(window.location.origin, "/");
      return {
        auth: {
          current: authBase + current
        },
        display: {
          current: base + current
        }
      };
    })
  });

  _exports.default = _default;
});