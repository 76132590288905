define("shared/components/catalog-box/component", ["exports", "shared/components/catalog-box/template", "shared/mixins/lazy-icon"], function (_exports, _template, _lazyIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_lazyIcon.default, {
    settings: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _template.default,
    tagName: 'a',
    classNames: ['catalog-box', 'p-0'],
    classNameBindings: ['active::inactive', 'model.certifiedClass'],
    model: null,
    showIcon: true,
    showDescription: true,
    active: true,
    launchAction: null,
    didRender: function didRender() {
      this.initAppIcon();
    }
  });

  _exports.default = _default;
});