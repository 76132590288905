define("global-admin/global-dns/entries/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    model: function model(params) {
      var globaldns = null;
      var providers = this.globalStore.findAll('globaldnsprovider');
      var multiClusterApps = this.globalStore.findAll('multiclusterapp');
      var allProjects = this.scope.getAllProjects();
      var allClusters = this.scope.getAllClusters();

      if (Ember.get(params, 'id')) {
        return this.globalStore.find('globaldns', params.id).then(function (resp) {
          var match = resp;

          if (match) {
            globaldns = match.clone();
            return Ember.RSVP.hash({
              globaldns: globaldns,
              providers: providers,
              multiClusterApps: multiClusterApps,
              allProjects: allProjects,
              allClusters: allClusters
            });
          } else {
            return Ember.RSVP.reject('Global DNS Entry Not Found');
          }
        });
      } else {
        globaldns = this.globalStore.createRecord({
          type: 'globaldns',
          name: '',
          projectIds: []
        });
        return Ember.RSVP.hash({
          globaldns: globaldns,
          providers: providers,
          multiClusterApps: multiClusterApps,
          allProjects: allProjects,
          allClusters: allClusters
        });
      }
    },
    afterModel: function afterModel(model
    /* , transition */
    ) {
      var providers = model.providers;
      var members = model.globaldns.members;

      if (Ember.get(providers, 'length') === 1 && !Ember.get(model, 'globaldns.providerId')) {
        this.initProviderId(model, providers);
      }

      if (members) {
        return this.fetchMembers(model);
      }

      return model;
    },
    setupController: function setupController(controller, model) {
      var recordType = 'multi';
      var originalModel = null;
      var mode = 'new';

      if (Ember.get(model, 'globaldns.projectIds.length') > 0) {
        recordType = 'project';
      }

      if (Ember.get(model, 'globaldns.id')) {
        mode = 'edit';
        originalModel = Ember.get(model, 'globaldns').clone();
      }

      controller.setProperties({
        projectsToAddOnUpgrade: [],
        projectsToRemoveOnUpgrade: [],
        mode: mode,
        originalModel: originalModel,
        recordType: recordType
      });

      this._super(controller, model);
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting && transition.targetName !== 'error') {
        controller.setProperties({
          projectsToAddOnUpgrade: [],
          projectsToRemoveOnUpgrade: [],
          originalModel: null,
          mode: 'new',
          recordType: 'multi'
        });
      }
    },
    initProviderId: function initProviderId(model, providers) {
      Ember.set(model, 'globaldns.providerId', Ember.get(providers, 'firstObject.id'));
    },
    fetchMembers: function fetchMembers(model) {
      var _this = this;

      var members = model.globaldns.members;

      if (members) {
        var membersPromises = [];
        members.forEach(function (member) {
          if (Ember.get(member, 'userPrincipalId')) {
            membersPromises.push(_this.globalStore.find('principal', member.userPrincipalId));
          } else if (Ember.get(member, 'groupPrincipalId')) {
            membersPromises.push(_this.globalStore.find('principal', member.groupPrincipalId));
          }
        });
        return Ember.RSVP.all(membersPromises);
      }
    },
    queryParams: {
      id: {
        refreshModel: true
      }
    }
  });

  _exports.default = _default;
});