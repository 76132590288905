define("global-admin/settings/index/controller", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    settings: Ember.inject.service(),
    cookies: Ember.inject.service(),
    projectId: Ember.computed.alias("cookies.".concat(_constants.default.COOKIE.PROJECT))
  });

  _exports.default = _default;
});