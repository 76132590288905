define("shared/all-storage-classes/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    clusterStore: Ember.inject.service(),
    _allStorageClasses: null,
    init: function init() {
      this._super.apply(this, arguments);

      var clusterStore = Ember.get(this, 'clusterStore');
      Ember.set(this, '_allStorageClasses', clusterStore.all('storageclass'));
    },
    storageClasses: Ember.computed('_allStorageClasses.[]', function () {
      return Ember.get(this, '_allStorageClasses').sortBy('name');
    }),
    list: Ember.computed.alias('storageClasses'),
    byId: function byId(id) {
      return Ember.get(this, '_allStorageClasses').findBy('id', id);
    }
  });

  _exports.default = _default;
});