define("istio/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _routes.default)(function () {
    this.route('cluster-setting');
    this.route('gateway', function () {
      this.route('new', {
        path: '/add'
      });
      this.route('detail', {
        path: '/:id'
      }, function () {
        this.route('edit');
      });
    });
    this.route('virtual-service', function () {
      this.route('new', {
        path: '/add'
      });
      this.route('detail', {
        path: '/:id'
      }, function () {
        this.route('edit');
      });
    });
    this.route('destination-rule', function () {
      this.route('new', {
        path: '/add'
      });
      this.route('detail', {
        path: '/:id'
      }, function () {
        this.route('edit');
      });
    });
    this.route('project-istio', function () {
      this.route('graph');
      this.route('metrics');
      this.route('rules');
      this.route('rule-detail', {
        path: '/rule-detail/:rule_id'
      });
      this.route('destination-rules', function () {
        this.route('index', {
          path: '/'
        });
      });
      this.route('virtual-services', function () {
        this.route('index', {
          path: '/'
        });
      });
      this.route('gateways', function () {
        this.route('index', {
          path: '/'
        });
      });
    });
  });

  _exports.default = _default;
});