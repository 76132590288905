define("shared/all-workloads/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function convert(obj, intl) {
    var namespace = Ember.get(obj, 'namespace.displayName');
    var name = Ember.get(obj, 'displayName');
    return {
      group: intl.t('allWorkloads.namespace', {
        name: namespace
      }),
      combined: "".concat(namespace, "/").concat(name),
      id: Ember.get(obj, 'id'),
      stackName: namespace,
      name: name,
      kind: Ember.get(obj, 'type'),
      obj: obj
    };
  }

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    app: Ember.inject.service(),
    _allWorkloads: null,
    _allPods: null,
    init: function init() {
      this._super.apply(this, arguments);

      var store = Ember.get(this, 'store');
      Ember.set(this, '_allWorkloads', store.all('workload'));
      Ember.set(this, '_allPods', store.all('pod'));
    },
    workloads: function () {
      var intl = Ember.get(this, 'intl');
      return Ember.get(this, '_allWorkloads').map(function (x) {
        return convert(x, intl);
      }).sortBy('combined');
    }.property('_allWorkloads.@each.{id,namespaceId,displayName,type}'),
    pods: function () {
      var intl = Ember.get(this, 'intl');
      return Ember.get(this, '_allPods').map(function (x) {
        return convert(x, intl);
      }).sortBy('combined');
    }.property('_allPods.@each.{id,namespaceId,displayName,type}'),
    list: Ember.computed.alias('workloads'),
    listWithPods: function () {
      var intl = Ember.get(this, 'intl');
      var out = Ember.get(this, '_allWorkloads').map(function (x) {
        return convert(x, intl);
      });
      out.pushObjects(Ember.get(this, '_allPods').map(function (x) {
        return convert(x, intl);
      }));
      return out.sortBy('combined');
    }.property('workloads', 'pods'),
    grouped: function () {
      return this.group(Ember.get(this, 'list'));
    }.property('list.[]'),
    groupedWithPods: function () {
      return this.group(Ember.get(this, 'listWithPods'));
    }.property('listWithPods.[]'),
    byId: function byId(id) {
      return this.get('_allWorkloads').findBy('id', id);
    },
    group: function group(list) {
      var out = {};
      list.slice().sortBy('group', 'name', 'id').forEach(function (obj) {
        var ary = out[obj.group];

        if (!ary) {
          ary = [];
          out[obj.group] = ary;
        }

        ary.push(obj);
      });
      return out;
    },
    matching: function matching(nameOrCombined, defaultNamespace) {
      var withPods = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      // If the defaultNamespace is an object, convert to a name
      if (defaultNamespace && _typeof(defaultNamespace) === 'object') {
        defaultNamespace = Ember.get(defaultNamespace, 'name');
      } // If the input has no namespace, add the default one


      var combined = nameOrCombined;

      if (defaultNamespace && !nameOrCombined.includes('/')) {
        combined = "".concat(defaultNamespace, "/").concat(nameOrCombined);
      }

      var ary = withPods ? Ember.get(this, 'listWithPods') : Ember.get(this, 'list');
      var match = ary.findBy('combined', combined);

      if (match) {
        return match.obj;
      }
    }
  });

  _exports.default = _default;
});