define("shared/mixins/grouped-instances", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function labelsMatching(ary, key, val) {
    return ary.filter(function (x) {
      return (x.get('labels') || {})[key] === val;
    });
  }

  var _default = Ember.Mixin.create({
    groupedInstances: function () {
      var groups = [];

      function getOrCreateGroup(id, name, isK8s) {
        var group = groups.findBy('id', id);

        if (!group) {
          group = {
            id: id,
            name: name,
            instances: [],
            hasChildren: false,
            kubernetes: isK8s
          };
          groups.push(group);
        }

        return group;
      }

      function getOrCreateUnit(groupId, groupName, deploymentUnit) {
        var group = getOrCreateGroup(groupId, groupName);
        var unit;

        if (deploymentUnit) {
          unit = group.instances.filterBy('unit', deploymentUnit)[0];
        }

        if (!unit) {
          unit = {
            unit: deploymentUnit,
            main: null,
            children: [],
            group: group
          };
          group.instances.push(unit);
        }

        return unit;
      }

      var remaining = this.get('filteredInstances').slice();

      while (remaining.get('length')) {
        var instance = remaining.objectAt(0);
        var labels = instance.get('labels') || {};
        var deploymentUnit = labels[_constants.default.LABEL.DEPLOYMENT_UNIT] || null; //      let version = instance.get('version')||"";

        var k8sName = (instance.get('labels') || {})[_constants.default.LABEL.K8S_POD_NAMESPACE] || '';
        var stackId = instance.get('stack.id') || '';
        var stackName = instance.get('stack.displayName') || '';
        var groupId = void 0,
            groupName = void 0;

        if (k8sName) {
          groupId = "_k8s_".concat(k8sName);
          groupName = k8sName || '';
        } else {
          groupId = stackId || '';
          groupName = stackName || '';
        }

        getOrCreateGroup(groupId, groupName, !!k8sName);
        var orphans = [];

        if (deploymentUnit) {
          var related = labelsMatching(remaining, _constants.default.LABEL.DEPLOYMENT_UNIT, deploymentUnit); //        related = related.filterBy('version', version);

          var primary = labelsMatching(related, _constants.default.LABEL.LAUNCH_CONFIG, _constants.default.LABEL.LAUNCH_CONFIG_PRIMARY).sortBy('createdTS').reverse()[0]; // Normal case, there's a primary service and maybe some sidekicks and/or old primary.

          if (primary) {
            related.removeObject(primary);
            var unit = getOrCreateUnit(groupId, groupName, deploymentUnit);
            unit.group.hasChildren = true;
            unit.main = primary;
            unit.children.pushObjects(related);
            remaining.removeObject(primary);
          } else {
            orphans = related;
          }

          remaining.removeObjects(related);
        } else if (stackId) {
          var _unit = getOrCreateUnit(groupId, groupName, instance.get('id'));

          _unit.group.hasChildren = false;
          _unit.main = instance;
          remaining.removeObject(instance);
        } else {
          orphans = [instance];
        }

        if (orphans.length) {
          for (var i = 0; i < orphans.length; i++) {
            var _unit2 = getOrCreateUnit('', '', null);

            _unit2.main = orphans[i];
          }

          remaining.removeObjects(orphans);
        }

        remaining.removeObject(instance);
      } // Sorting is nice


      groups = groups.sortBy('name'); // Standalone last

      var standalone = getOrCreateGroup('');
      groups.removeObject(standalone);
      groups.push(standalone); // Collapse all

      groups.forEach(function (group) {
        group.collapsed = true;
      });
      return groups;
    }.property('filteredInstances.@each.{name,id}')
  });

  _exports.default = _default;
});