enifed("ember-template-compiler/tests/plugins/assert-if-helper-without-arguments-test", ["ember-template-compiler/index", "internal-test-helpers"], function (_index, _internalTestHelpers) {
  "use strict";

  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: assert-if-helper-without-argument', class extends _internalTestHelpers.AbstractTestCase {
    [`@test block if helper expects one argument`]() {
      expectAssertion(() => {
        (0, _index.compile)(`{{#if}}aVal{{/if}}`, {
          moduleName: 'baz/foo-bar'
        });
      }, `#if requires a single argument. ('baz/foo-bar' @ L1:C0) `);
      expectAssertion(() => {
        (0, _index.compile)(`{{#if val1 val2}}aVal{{/if}}`, {
          moduleName: 'baz/foo-bar'
        });
      }, `#if requires a single argument. ('baz/foo-bar' @ L1:C0) `);
      expectAssertion(() => {
        (0, _index.compile)(`{{#if}}aVal{{/if}}`, {
          moduleName: 'baz/foo-bar'
        });
      }, `#if requires a single argument. ('baz/foo-bar' @ L1:C0) `);
    }

    [`@test inline if helper expects between one and three arguments`]() {
      expectAssertion(() => {
        (0, _index.compile)(`{{if}}`, {
          moduleName: 'baz/foo-bar'
        });
      }, `The inline form of the 'if' helper expects two or three arguments. ('baz/foo-bar' @ L1:C0) `);
      (0, _index.compile)(`{{if foo bar baz}}`, {
        moduleName: 'baz/foo-bar'
      });
    }

    ['@test subexpression if helper expects between one and three arguments']() {
      expectAssertion(() => {
        (0, _index.compile)(`{{input foo=(if)}}`, {
          moduleName: 'baz/foo-bar'
        });
      }, `The inline form of the 'if' helper expects two or three arguments. ('baz/foo-bar' @ L1:C12) `);
      (0, _index.compile)(`{{some-thing foo=(if foo bar baz)}}`, {
        moduleName: 'baz/foo-bar'
      });
    }

  });
});