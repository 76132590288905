define("shared/mixins/tooltip", ["exports", "shared/mixins/throttled-resize"], function (_exports, _throttledResize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_throttledResize.default, {
    tooltipContent: null,
    originalNode: null,
    router: Ember.inject.service(),
    currentRoute: null,
    tooltipService: Ember.inject.service('tooltip'),
    didInsertElement: function didInsertElement() {
      var $el = Ember.$(Ember.get(this, 'element'));
      var markup = "<div class ='tooltip-caret'></div>";
      Ember.$(markup).appendTo($el);
    },
    mouseEnter: function mouseEnter() {
      Ember.get(this, 'tooltipService').cancelTimer();
    },
    mouseLeave: function mouseLeave() {
      this.destroyTooltip();
    },
    routeObserver: Ember.on('init', Ember.observer('router.currentRouteName', function () {
      // On init
      if (!Ember.get(this, 'currentRoute')) {
        Ember.set(this, 'currentRoute', Ember.get(this, 'router.currentRouteName'));
      } // if we change routes tear down the tooltip


      if (Ember.get(this, 'currentRoute') !== Ember.get(this, 'router.currentRouteName')) {
        this.destroyTooltip();
      }
    })),
    tooltipConstructor: Ember.on('init', Ember.observer('tooltipService.tooltipOpts', function () {
      Ember.run.scheduleOnce('afterRender', this, function () {
        if (Ember.get(this, 'tooltipService.tooltipOpts')) {
          this.constructTooltip();
        }
      });
    })),
    constructTooltip: function constructTooltip() {
      var tts = Ember.get(this, 'tooltipService');
      var node = Ember.$(this.element);
      var eventPosition = tts.get('tooltipOpts.eventPosition');
      var position = this.positionTooltip(node, Ember.$().extend({}, eventPosition));
      var css = {
        visibility: 'visible'
      };
      var classes = position.placement;

      if (tts.get('tooltipOpts.isCopyTo')) {
        css.width = position.width + 1;
      }

      if (tts.tooltipOpts.baseClass) {
        classes += " ".concat(tts.tooltipOpts.baseClass);
      }

      node.offset(position).addClass(classes).css(css);

      if (position.caret) {
        node.find('.tooltip-caret').css('left', position.caret);
      }
    },
    destroyTooltip: function destroyTooltip() {
      Ember.get(this, 'tooltipService').startTimer();
    },
    positionTooltip: function positionTooltip(tooltipNode, position) {
      var windowWidth = window.innerWidth;
      var eventNode = Ember.get(this, 'tooltipService.tooltipOpts.originalNode');
      var eventNodeWidth = eventNode.outerWidth();
      var eventNodeHeight = eventNode.outerHeight();
      var tooltipNodeHeight = tooltipNode.outerHeight();
      var tooltipNodeWidth = tooltipNode.outerWidth();
      var overridePlacement = Ember.get(this, 'tooltipService.tooltipOpts.placement');

      if (overridePlacement) {
        position.placement = overridePlacement;
      } else if (tooltipNodeWidth >= position.left) {
        position.placement = 'left';
      } else if (tooltipNodeWidth >= windowWidth - position.left) {
        position.placement = 'right';
      } else if (tooltipNodeHeight >= position.top) {
        position.placement = 'bottom';
      } else {
        position.placement = 'top';
      }

      switch (position.placement) {
        case 'left':
          position.left = horizontalViewport(position.left + eventNodeWidth + 7, position);
          position.top = position.top + eventNodeHeight / 2 - tooltipNodeHeight / 2;
          break;

        case 'right':
          position.left = horizontalViewport(position.left - tooltipNodeWidth - 7, position);
          position.top = position.top + eventNodeHeight / 2 - tooltipNodeHeight / 2;
          break;

        case 'bottom':
          position.left = horizontalViewport(position.left + eventNodeWidth / 2 - tooltipNodeWidth / 2, position);
          position.top = position.top + eventNodeHeight + 7;
          break;

        default:
          position.left = horizontalViewport(position.left + eventNodeWidth / 2 - tooltipNodeWidth / 2, position);
          position.top = position.top - (tooltipNodeHeight + 7);
          break;
      }

      function horizontalViewport(leftEdge2CenterPoint, position) {
        if (leftEdge2CenterPoint < tooltipNodeWidth / 2) {
          var pushFromLeft = 10;
          leftEdge2CenterPoint = Math.max(0, leftEdge2CenterPoint) + pushFromLeft;
          position.caret = position.left - leftEdge2CenterPoint;
        }

        return leftEdge2CenterPoint;
      }

      position.width = tooltipNodeWidth;
      return position;
    }
  });

  _exports.default = _default;
});