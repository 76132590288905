define('ember-drag-drop/mixins/droppable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * Wraps the native drop events to make your components droppable.
   *
   * @mixin Droppable
   */

  var Droppable = Ember.Mixin.create({
    _currentDrag: null,
    classNameBindings: ['accepts-drag', 'self-drop'],

    /**
     * Read-only className property that is set to true when the component is
     * receiving a valid drag event. You can style your element with
     * `.accepts-drag`.
     *
     * @property accepts-drag
     * @private
     */

    'accepts-drag': false,

    /**
     * Will be true when the component is dragged over itself. Can use
     * `.self-drop` in your css to style (or more common, unstyle) the component.
     *
     * @property self-drop
     * @private
     */

    'self-drop': false,

    validateDragEvent: function validateDragEvent() {
      return true;
    },
    acceptDrop: function acceptDrop() {},
    handleDragOver: function handleDragOver() {},
    handleDragOut: function handleDragOut() {},
    dragOver: function dragOver(event) {
      if (this._droppableIsDraggable(event)) {
        this.set('self-drop', true);
      }
      if (this.get('accepts-drag')) {
        return this._allowDrop(event);
      }
      if (this.validateDragEvent(event)) {
        this.set('accepts-drag', true);
        this._allowDrop(event);
      } else {
        this._resetDroppability();
      }
    },
    dragEnter: function dragEnter() {
      return false;
    },
    drop: function drop(event) {
      // have to validate on drop because you may have nested sortables the
      // parent allows the drop but the child receives it, revalidating allows
      // the event to bubble up to the parent to handle it
      if (!this.validateDragEvent(event)) {
        return;
      }
      this.acceptDrop(event);
      this._resetDroppability();
      // TODO: might not need this? I can't remember why its here
      event.stopPropagation();
      return false;
    },
    _allowDrop: function _allowDrop(event) {
      this.handleDragOver(event);
      event.stopPropagation();
      event.preventDefault();
      return false;
    },
    _droppableIsDraggable: function _droppableIsDraggable(event) {
      return Droppable._currentDrag && (Droppable._currentDrag === event.target || Droppable._currentDrag.contains(event.target));
    },
    _resetDroppability: function _resetDroppability(event) {
      this.handleDragOut(event);
      this.set('accepts-drag', false);
      this.set('self-drop', false);
    },
    dragLeave: function dragLeave() {
      this._resetDroppability();
    },
    dragStart: function dragStart(event) {
      this.set('_currentDrag', event.target);
    }
  });

  exports.default = Droppable;
});