define("shared/components/modal-istio-yaml/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bCQy7huU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"modal-yaml\"],[9],[0,\"\\n  \"],[7,\"section\"],[11,\"class\",\"header clearfix\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[1,[27,\"t\",[\"modalYaml.title.view\"],[[\"name\"],[[23,[\"name\"]]]]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[1,[27,\"new-select\",null,[[\"classNames\",\"content\",\"value\"],[\"form-control\",[23,[\"options\"]],[23,[\"selectedType\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[27,\"or\",[[23,[\"yaml\"]],[23,[\"loading\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"input-yaml\",null,[[\"canChangeName\",\"value\",\"loading\",\"autoResize\",\"readOnly\",\"canUpload\",\"showUpload\",\"showDownload\",\"filename\"],[false,[23,[\"yaml\"]],[23,[\"loading\"]],true,true,false,false,false,[23,[\"filename\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"row mt-20\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"text-center text-muted\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"modalYaml.noData\"],[[\"name\"],[[23,[\"selectedType\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[1,[27,\"copy-to-clipboard\",null,[[\"tooltipText\",\"buttonText\",\"clipboardText\",\"class\"],[\"\",\"copyToClipboard.tooltip\",[23,[\"yaml\"]],\"with-clip\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"style\",\"text-align: center\"],[11,\"class\",\"mt-20 text-center\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn bg-transparent\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"generic.cancel\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/modal-istio-yaml/template.hbs"
    }
  });

  _exports.default = _default;
});