enifed("ember-template-compiler/lib/system/calculate-location-display", ["exports"], function (_exports) {
  "use strict";

  _exports.default = calculateLocationDisplay;

  function calculateLocationDisplay(moduleName, loc) {
    let moduleInfo = '';

    if (moduleName) {
      moduleInfo += `'${moduleName}' `;
    }

    if (loc) {
      let {
        column,
        line
      } = loc.start || {
        line: undefined,
        column: undefined
      };

      if (line !== undefined && column !== undefined) {
        if (moduleName) {
          // only prepend @ if the moduleName was present
          moduleInfo += '@ ';
        }

        moduleInfo += `L${line}:C${column}`;
      }
    }

    if (moduleInfo) {
      moduleInfo = `(${moduleInfo}) `;
    }

    return moduleInfo;
  }
});