define("shared/components/input-slider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oVEVExLN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[27,\"concat\",[[23,[\"elementId\"]],\" slider-bar\"],null]],[9],[10],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[27,\"concat\",[[23,[\"elementId\"]],\" slider-handle\"],null]],[11,\"tabindex\",\"0\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/input-slider/template.hbs"
    }
  });

  _exports.default = _default;
});