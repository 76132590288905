define("shared/components/modal-yaml/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RIGTSgGm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"modal-yaml\"],[9],[0,\"\\n  \"],[1,[27,\"input-yaml\",null,[[\"title\",\"filename\",\"canChangeName\",\"value\",\"loading\",\"autoResize\",\"readOnly\",\"canUpload\",\"showUpload\"],[[27,\"if\",[[23,[\"editing\"]],[27,\"t\",[[27,\"if\",[[23,[\"readOnly\"]],\"modalYaml.title.view\",\"modalYaml.title.edit\"],null]],[[\"name\"],[[23,[\"model\",\"resource\",\"displayName\"]]]]],[27,\"t\",[\"modalYaml.title.new\"],[[\"type\"],[[27,\"uc-first\",[[23,[\"model\",\"resource\",\"displayName\"]]],null]]]]],null],[23,[\"filename\"]],false,[23,[\"model\",\"yaml\"]],[23,[\"loading\"]],true,[23,[\"readOnly\"]],[27,\"not\",[[23,[\"readOnly\"]]],null],[27,\"not\",[[23,[\"readOnly\"]]],null]]]],false],[0,\"\\n  \"],[1,[27,\"copy-to-clipboard\",null,[[\"tooltipText\",\"buttonText\",\"clipboardText\",\"class\"],[\"\",\"copyToClipboard.tooltip\",[23,[\"model\",\"yaml\"]],\"with-clip\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"top-errors\",null,[[\"errors\"],[[23,[\"errors\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"readOnly\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"style\",\"text-align: center\"],[11,\"class\",\"mt-20 text-center\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn bg-transparent\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"generic.cancel\"],null],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"save-cancel\",null,[[\"save\",\"cancel\",\"editing\"],[[27,\"action\",[[22,0,[]],\"save\"],null],[27,\"action\",[[22,0,[]],\"cancel\"],null],[23,[\"editing\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/modal-yaml/template.hbs"
    }
  });

  _exports.default = _default;
});