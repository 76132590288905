define("global-admin/clusters/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0RB/pJdr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"header\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"router\",\"currentRoute\",\"localName\"]],\"select\"],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"clustersPage.select.provider.label\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"router\",\"currentRoute\",\"localName\"]],\"cluster-template\"],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"clustersPage.select.template.label\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"clustersPage.launch.new\"],null],false],[0,\" - \"],[1,[27,\"driver-name\",[[23,[\"provider\"]]],null],false],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"section\"],[11,\"class\",\"cluster-launch\"],[9],[0,\"\\n  \"],[1,[21,\"outlet\"],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/clusters/new/template.hbs"
    }
  });

  _exports.default = _default;
});