define("shared/components/settings/settings-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rL8MkWiL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"section\"],[11,\"class\",\"header has-tabs clearfix\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[1,[27,\"t\",[\"nav.admin.settings.tab\"],null],false],[10],[0,\"\\n\\n  \"],[7,\"ul\"],[11,\"class\",\"tab-nav\"],[9],[0,\"\\n    \"],[7,\"li\"],[9],[4,\"link-to\",[\"settings.advanced\"],null,{\"statements\":[[1,[27,\"t\",[\"nav.admin.settings.advanced\"],null],false]],\"parameters\":[]},null],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"right-buttons\"],[9],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/settings/settings-header/template.hbs"
    }
  });

  _exports.default = _default;
});