define("ember-engines/components/link-to-external-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.LinkComponent.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var owner = Ember.getOwner(this);

      if (owner.mountPoint) {
        var targetRouteName = Ember.get(this, 'targetRouteName');

        var externalRoute = owner._getExternalRoute(targetRouteName);

        Ember.set(this, 'targetRouteName', externalRoute);
      }
    }
  });

  _exports.default = _default;
});