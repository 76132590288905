define("shared/components/check-override-allowed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XIlEqjps",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"applyClusterTemplate\"]]],null,{\"statements\":[[4,\"if\",[[27,\"has-override\",null,[[\"questions\",\"paramName\"],[[23,[\"questions\"]],[23,[\"paramName\"]]]]]],null,{\"statements\":[[0,\"    \"],[14,1,[[27,\"hash\",null,[[\"hasOverride\"],[true]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"value\"]]],null,{\"statements\":[[0,\"        \"],[1,[21,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[27,\"get\",[[23,[\"clusterTemplateRevision\"]],[23,[\"paramName\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"and\",[[27,\"eq\",[[23,[\"mode\"]],\"view\"],null],[27,\"get\",[[23,[\"clusterTemplateRevision\"]],[23,[\"paramName\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"get\",[[23,[\"clusterTemplateRevision\"]],[23,[\"paramName\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/check-override-allowed/template.hbs"
    }
  });

  _exports.default = _default;
});