define("shared/components/schema/input-multiline/component", ["exports", "shared/components/schema/input-multiline/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextArea.extend({
    layout: _template.default,
    classNames: ['form-control', 'text-mono'],
    rows: 3
  });

  _exports.default = _default;
});