define("global-admin/global-registry/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s82+l+9P",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"registry-catalog\",null,[[\"app\",\"cluster\",\"project\",\"nsExists\",\"storageClasses\",\"persistentVolumeClaims\",\"showForm\",\"globalRegistryEnabled\",\"cancel\"],[[23,[\"model\",\"app\"]],[23,[\"model\",\"cluster\"]],[23,[\"model\",\"project\"]],[23,[\"model\",\"nsExists\"]],[23,[\"model\",\"storageClasses\"]],[23,[\"model\",\"persistentVolumeClaims\"]],[23,[\"model\",\"showForm\"]],[23,[\"model\",\"globalRegistryEnabled\"]],[27,\"action\",[[22,0,[]],\"cancel\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/global-registry/template.hbs"
    }
  });

  _exports.default = _default;
});