define('ember-href-to/href-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var _class = function () {
    function _class(applicationInstance, event) {
      var target = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : event.target;

      _classCallCheck(this, _class);

      this.applicationInstance = applicationInstance;
      this.event = event;
      this.target = target;
      var hrefAttr = this.target.attributes.href;
      this.url = hrefAttr && hrefAttr.value;
    }

    _createClass(_class, [{
      key: 'maybeHandle',
      value: function maybeHandle() {
        if (this.shouldHandle()) {
          this.handle();
        }
      }
    }, {
      key: 'shouldHandle',
      value: function shouldHandle() {
        return this.isUnmodifiedLeftClick() && this.isNotIgnored() && this.hasNoTargetBlank() && this.hasNoActionHelper() && this.hasNoDownload() && this.isNotLinkComponent() && this.recognizeUrl();
      }
    }, {
      key: 'handle',
      value: function handle() {
        var router = this._getRouter();
        router.transitionTo(this.getUrlWithoutRoot());
        this.event.preventDefault();
      }
    }, {
      key: 'isUnmodifiedLeftClick',
      value: function isUnmodifiedLeftClick() {
        var e = this.event;

        return (e.which === undefined || e.which === 1) && !e.ctrlKey && !e.metaKey;
      }
    }, {
      key: 'hasNoTargetBlank',
      value: function hasNoTargetBlank() {
        var attr = this.target.attributes.target;
        return !attr || attr.value !== '_blank';
      }
    }, {
      key: 'isNotIgnored',
      value: function isNotIgnored() {
        return !this.target.attributes['data-href-to-ignore'];
      }
    }, {
      key: 'hasNoActionHelper',
      value: function hasNoActionHelper() {
        return !this.target.attributes['data-ember-action'];
      }
    }, {
      key: 'hasNoDownload',
      value: function hasNoDownload() {
        return !this.target.attributes.download;
      }
    }, {
      key: 'isNotLinkComponent',
      value: function isNotLinkComponent() {
        var isLinkComponent = false;
        var id = this.target.id;
        if (id) {
          var componentInstance = this.applicationInstance.lookup('-view-registry:main')[id];
          isLinkComponent = componentInstance && componentInstance instanceof Ember.LinkComponent;
        }

        return !isLinkComponent;
      }
    }, {
      key: 'recognizeUrl',
      value: function recognizeUrl() {
        var url = this.url;
        var didRecognize = false;

        if (url) {
          var router = this._getRouter();
          var rootUrl = this._getRootUrl();
          var isInternal = url.indexOf(rootUrl) === 0;
          var urlWithoutRoot = this.getUrlWithoutRoot();
          var routerMicrolib = router._router._routerMicrolib || router._router.router;

          didRecognize = isInternal && routerMicrolib.recognizer.recognize(urlWithoutRoot);
        }

        return didRecognize;
      }
    }, {
      key: 'getUrlWithoutRoot',
      value: function getUrlWithoutRoot() {
        var url = this.url;
        var rootUrl = this._getRootUrl();
        return url.substr(rootUrl.length - 1);
      }
    }, {
      key: '_getRouter',
      value: function _getRouter() {
        return this.applicationInstance.lookup('service:router');
      }
    }, {
      key: '_getRootUrl',
      value: function _getRootUrl() {
        var router = this._getRouter();
        var rootURL = router.get('rootURL');

        if (rootURL.charAt(rootURL.length - 1) !== '/') {
          rootURL = rootURL + '/';
        }

        return rootURL;
      }
    }]);

    return _class;
  }();

  exports.default = _class;
});