define("shared/components/action-menu-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y8450AQb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"pull-left\"],[9],[1,[27,\"t\",[[23,[\"label\"]]],null],false],[10],[0,\"\\n\"],[7,\"i\"],[12,\"class\",[28,[[21,\"prefix\"],\" \",[21,\"icon\"],\" pull-right ml-10\"]]],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/action-menu-item/template.hbs"
    }
  });

  _exports.default = _default;
});