define("shared/components/schema/input-certificate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "113Xe+rs",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"loading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\"],[11,\"class\",\"icon icon-spinner icon-spin\"],[9],[10],[0,\" \"],[1,[27,\"t\",[\"generic.loading\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"new-select\",null,[[\"classNames\",\"content\",\"prompt\",\"localizedPrompt\",\"optionLabelPath\",\"optionValuePath\",\"value\"],[\"form-control\",[23,[\"choices\"]],\"schema.inputCertificate.prompt\",true,\"displayDetailedName\",\"name\",[23,[\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/schema/input-certificate/template.hbs"
    }
  });

  _exports.default = _default;
});