define("shared/components/modal-move-namespace/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hxRtDTOn",
    "block": "{\"symbols\":[\"project\",\"item\"],\"statements\":[[7,\"h2\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"moveNamespace.title\"],[[\"count\",\"name\"],[[23,[\"model\",\"length\"]],[23,[\"model\",\"firstObject\",\"displayName\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"model\",\"length\"]],1],null]],null,{\"statements\":[[0,\"  \"],[7,\"ul\"],[11,\"class\",\"list-unstyled\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[1,[22,2,[\"displayName\"]],false],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"h2\"],[11,\"class\",\"pt-20\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"moveNamespace.to\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"ul\"],[11,\"class\",\"list-unstyled pl-30\"],[9],[0,\"\\n  \"],[7,\"label\"],[9],[0,\"\\n    \"],[1,[27,\"radio-button\",null,[[\"selection\",\"value\"],[[23,[\"projectId\"]],null]]],false],[0,\"\\n    \"],[7,\"b\"],[9],[1,[27,\"t\",[\"generic.none\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"allProjects\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[0,\"\\n      \"],[7,\"label\"],[9],[0,\"\\n        \"],[1,[27,\"radio-button\",null,[[\"selection\",\"value\"],[[23,[\"projectId\"]],[22,1,[\"id\"]]]]],false],[0,\"\\n        \"],[1,[22,1,[\"displayName\"]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[1,[27,\"top-errors\",null,[[\"errors\"],[[23,[\"errors\"]]]]],false],[0,\"\\n\"],[1,[27,\"save-cancel\",null,[[\"editing\",\"saving\",\"editLabel\",\"save\",\"cancel\"],[true,[23,[\"saving\"]],\"moveNamespace.actionLabel\",[27,\"action\",[[22,0,[]],\"save\"],null],[27,\"action\",[[22,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/modal-move-namespace/template.hbs"
    }
  });

  _exports.default = _default;
});