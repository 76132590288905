define("shared/helpers/pretty-cron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Prettycron = window.Prettycron;

  function prettyCronHelper(param) {
    if (!param[0]) {
      return '';
    }

    return Prettycron[param[1]](param[0]);
  }

  var _default = Ember.Helper.helper(prettyCronHelper);

  _exports.default = _default;
});