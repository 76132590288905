define("shared/digital-ocean/service", ["exports", "shared/utils/util", "@rancher/ember-api-store/utils/fetch"], function (_exports, _util, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DIGITALOCEAN_API = 'api.digitalocean.com/v2';

  var _default = Ember.Service.extend({
    app: Ember.inject.service(),
    request: function request() {
      var _this = this;

      var auth = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var command = arguments.length > 1 ? arguments[1] : undefined;
      var opt = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var out = arguments.length > 3 ? arguments[3] : undefined;
      var url = "".concat(Ember.get(this, 'app.proxyEndpoint'), "/");
      var token = Ember.get(auth, 'token');
      var headers = {
        'Accept': 'application/json'
      };

      if (Ember.get(auth, 'type') === 'cloud') {
        Ember.set(headers, 'x-api-cattleauth-header', "Bearer credID=".concat(token, " passwordField=accessToken"));
      } else {
        Ember.set(headers, 'X-Api-Auth-Header', "Bearer ".concat(token));
      }

      if (opt.url) {
        url += opt.url.replace(/^http[s]?\/\//, '');
      } else {
        url += "".concat(DIGITALOCEAN_API, "/").concat(command);
        url = (0, _util.addQueryParam)(url, 'per_page', opt.per_page || 100);
        url = (0, _util.addQueryParams)(url, opt.params || {});
      }

      return (0, _fetch.default)(url, {
        headers: headers
      }).then(function (res) {
        var body = res.body;

        if (out) {
          out[command].pushObjects(body[command]);
        } else {
          out = body;
        } // De-paging


        if (body && body.links && body.links.pages && body.links.pages.next) {
          opt.url = body.links.pages.next;
          return _this.apiRequest(command, opt, out).then(function () {
            return out;
          });
        } else {
          return out;
        }
      }).catch(function (err) {
        return Ember.RSVP.reject(err);
      });
    }
  });

  _exports.default = _default;
});