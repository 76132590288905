define("shared/components/input-answer-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ub+kiO6u",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[11,\"class\",\"acc-label\"],[9],[0,\"\\n  \"],[1,[27,\"or\",[[23,[\"question\",\"label\"]],[23,[\"question\",\"variable\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[23,[\"question\",\"required\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"field-required\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"question\",\"supported\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[23,[\"question\",\"inputComponent\"]]],[[\"field\",\"value\",\"namespaceId\",\"namespace\"],[[23,[\"question\"]],[23,[\"answer\"]],[23,[\"namespaceId\"]],[23,[\"namespace\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"class\"],[\"text\",[23,[\"question\",\"answer\"]],\"form-control\"]]],false],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"inputAnswers.unknownType\"],null],false],[0,\" \"],[1,[23,[\"question\",\"type\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"help-block\"],[9],[0,\"\\n  \"],[1,[23,[\"question\",\"description\"]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/input-answer-row/template.hbs"
    }
  });

  _exports.default = _default;
});