define("shared/components/annotations-section/component", ["exports", "shared/mixins/manage-labels", "shared/components/annotations-section/template"], function (_exports, _manageLabels, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_manageLabels.default, {
    layout: _template.default,
    model: null,
    sortBy: 'key',
    descending: false,
    headers: [{
      name: 'key',
      sort: ['key'],
      translationKey: 'annotationsSection.key'
    }, {
      name: 'value',
      sort: ['value', 'key'],
      translationKey: 'annotationsSection.value'
    }],
    annotationSource: Ember.computed.alias('model.annotations'),
    didReceiveAttrs: function didReceiveAttrs() {
      this.initLabels(this.get('annotationSource'));
    },
    annotationsObserver: Ember.observer('model.annotations', function () {
      this.initLabels(this.get('annotationSource'));
    })
  });

  _exports.default = _default;
});