define("shared/mixins/filter-state", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    filterStates: null,
    filterableContent: Ember.computed.alias('model'),
    init: function init() {
      this._super();

      if (!this.get('filterStates')) {
        this.set('filterStates', _constants.default.REMOVEDISH_STATES.slice());
      }
    },
    filtered: Ember.computed('filterableContent.@each.state', 'filterStates.[]', function () {
      var filterStates = this.get('filterStates');
      return (this.get('filterableContent') || []).filter(function (row) {
        var state = (row.get('state') || '').toLowerCase();
        return filterStates.indexOf(state) === -1;
      });
    })
  });

  _exports.default = _default;
});