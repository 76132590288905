define("shared/components/top-errors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4aBuBU/Z",
    "block": "{\"symbols\":[\"error\",\"line\"],\"statements\":[[7,\"div\"],[11,\"class\",\"banner-icon\"],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"icon icon-alert\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"banner-message\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"errors\"]]],null,{\"statements\":[[4,\"if\",[[27,\"is-array\",[[22,1,[]]],null]],null,{\"statements\":[[4,\"each\",[[22,1,[]]],null,{\"statements\":[[0,\"          \"],[7,\"p\"],[9],[1,[22,2,[]],false],[10],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"p\"],[9],[1,[22,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/top-errors/template.hbs"
    }
  });

  _exports.default = _default;
});