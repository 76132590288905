define("liquid-fire/templates/components/liquid-bind", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u+8Q7oZq",
    "block": "{\"symbols\":[\"container\",\"version\",\"version\",\"&default\"],\"statements\":[[4,\"if\",[[23,[\"containerless\"]]],null,{\"statements\":[[4,\"liquid-versions\",null,[[\"value\",\"use\",\"rules\",\"matchContext\",\"versionEquality\",\"renderWhenFalse\",\"class\"],[[23,[\"value\"]],[23,[\"use\"]],[23,[\"rules\"]],[23,[\"forwardMatchContext\"]],[23,[\"versionEquality\"]],true,[23,[\"class\"]]]],{\"statements\":[[4,\"if\",[[24,4]],null,{\"statements\":[[14,4,[[22,3,[]]]]],\"parameters\":[]},{\"statements\":[[1,[22,3,[]],false]],\"parameters\":[]}]],\"parameters\":[3]},null]],\"parameters\":[]},{\"statements\":[[4,\"liquid-container\",null,[[\"id\",\"class\",\"growDuration\",\"growPixelsPerSecond\",\"growEasing\",\"shrinkDelay\",\"growDelay\",\"enableGrowth\"],[[23,[\"containerId\"]],[23,[\"class\"]],[23,[\"growDuration\"]],[23,[\"growPixelsPerSecond\"]],[23,[\"growEasing\"]],[23,[\"shrinkDelay\"]],[23,[\"growDelay\"]],[23,[\"enableGrowth\"]]]],{\"statements\":[[4,\"liquid-versions\",null,[[\"value\",\"notify\",\"use\",\"rules\",\"matchContext\",\"versionEquality\",\"renderWhenFalse\"],[[23,[\"value\"]],[22,1,[]],[23,[\"use\"]],[23,[\"rules\"]],[23,[\"forwardMatchContext\"]],[23,[\"versionEquality\"]],true]],{\"statements\":[[4,\"if\",[[24,4]],null,{\"statements\":[[14,4,[[22,2,[]]]]],\"parameters\":[]},{\"statements\":[[1,[22,2,[]],false]],\"parameters\":[]}]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "liquid-fire/templates/components/liquid-bind.hbs"
    }
  });

  _exports.default = _default;
});