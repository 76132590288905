define("global-admin/accounts/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    model: function model(params) {
      var store = Ember.get(this, 'globalStore');
      return Ember.RSVP.hash({
        globalRoles: store.findAll('globalrole'),
        user: store.find('user', params.user_id)
      });
    }
  });

  _exports.default = _default;
});