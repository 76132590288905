define("shared/prefs/service", ["exports", "shared/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    globalStore: Ember.inject.service(),
    settings: Ember.inject.service(),
    app: Ember.inject.service(),
    unremoved: Ember.computed('globalStore.generation', function () {
      return this.get('globalStore').all('preference');
    }),
    findByName: function findByName(key) {
      return this.get('unremoved').filterBy('name', key)[0];
    },
    unknownProperty: function unknownProperty(key) {
      var value;
      var existing = this.findByName(key);

      if (existing) {
        try {
          value = JSON.parse(existing.get('value'));
        } catch (e) {
          console.log("Error parsing storage ['".concat(key, "']"));
        }
      }

      return value;
    },
    setUnknownProperty: function setUnknownProperty(key, value) {
      var _this = this;

      if (key !== 'app') {
        var obj = this.findByName(key); // Delete by set to undefined

        if (value === undefined) {
          if (obj) {
            obj.set('value', undefined);
            obj.delete();
            this.notifyPropertyChange(key);
          }

          return;
        }

        if (!obj) {
          obj = this.get('globalStore').createRecord({
            type: 'preference',
            name: key
          });
        }

        var neu = JSON.stringify(value);

        if (!obj.get('id') || obj.get('value') !== neu) {
          obj.set('value', neu);
          obj.save().then(function () {
            Ember.run(function () {
              _this.notifyPropertyChange(key);
            });
          });
        }
      }

      return value;
    },
    clear: function clear() {
      var _this2 = this;

      this.beginPropertyChanges();
      this.get('unremoved').forEach(function (obj) {
        _this2.set(obj.get('name'), undefined);
      });
      this.endPropertyChanges();
    },
    tablePerPage: Ember.computed(_constants.default.PREFS.TABLE_COUNT, function () {
      var out = parseInt(this.get(_constants.default.PREFS.TABLE_COUNT), 10);

      if (!out) {
        out = _constants.default.TABLES.DEFAULT_COUNT;
      }

      return out;
    })
  });

  _exports.default = _default;
});