define("global-admin/security/authentication/ping/controller", ["exports", "global-admin/mixins/authentication", "global-admin/mixins/saml-auth"], function (_exports, _authentication, _samlAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_authentication.default, _samlAuth.default, {
    createLabel: 'authPage.ping.buttonText.pre',
    saveLabel: 'authPage.ping.buttonText.post',
    providerName: 'ping',
    providerNamePath: 'authPage.saml.providerName.ping'
  });

  _exports.default = _default;
});