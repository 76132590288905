define("global-admin/components/modal-revert-setting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n6Rd980D",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"header\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"modalRevertSettings.title\"],[[\"setting\"],[[23,[\"setting\",\"id\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"section\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"modal-content row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col span-12\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"class\",\"acc-label\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"modalRevertSettings.current\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[9],[0,\"\\n        \"],[1,[23,[\"setting\",\"value\"]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col span-12\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"class\",\"acc-label\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"modalRevertSettings.default\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[9],[0,\"\\n        \"],[1,[23,[\"setting\",\"default\"]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"top-errors\",null,[[\"errors\"],[[23,[\"errors\"]]]]],false],[0,\"\\n\"],[1,[27,\"save-cancel\",null,[[\"save\",\"cancel\",\"createLabel\"],[[27,\"action\",[[22,0,[]],\"save\"],null],[27,\"action\",[[22,0,[]],\"done\"],null],\"generic.save\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/components/modal-revert-setting/template.hbs"
    }
  });

  _exports.default = _default;
});