define("shared/components/storage-class/provisioner-gce-pd/component", ["exports", "shared/components/storage-class/provisioner-gce-pd/template", "shared/mixins/storage-class-provisioner"], function (_exports, _template, _storageClassProvisioner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.VOLUME_TYPES = void 0;
  var VOLUME_TYPES = ['pd-standard', 'pd-ssd'];
  _exports.VOLUME_TYPES = VOLUME_TYPES;

  var _default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,
    volumeTypes: VOLUME_TYPES,
    volumeType: null,
    fsType: null,
    replicationType: null,
    zones: null,
    zoneAutomatic: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var changes = {};
      changes['volumeType'] = Ember.get(this, 'parameters.type') || 'pd-standard';
      changes['fsType'] = Ember.get(this, 'parameters.fsType');
      var zone = Ember.get(this, 'parameters.zone');
      var zones = Ember.get(this, 'parameters.zones');

      if (zones && zones.length) {
        changes['zoneAutomatic'] = false;
        changes['zones'] = zones;
      } else if (zone) {
        changes['zoneAutomatic'] = false;
        changes['zones'] = zone;
      } else {
        changes['zoneAutomatic'] = true;
        changes['zones'] = '';
      }

      changes['replicationType'] = Ember.get(this, 'parameters.replication-type') || 'none';
      Ember.setProperties(this, changes);
    },
    updateParams: function updateParams() {
      var type = Ember.get(this, 'volumeType');
      var fsType = Ember.get(this, 'fsType');
      var replicationType = Ember.get(this, 'replicationType');
      var out = {
        type: type
      };

      if (fsType) {
        out['fsType'] = fsType;
      }

      if (replicationType && replicationType !== 'none') {
        out['replication-type'] = replicationType;
      }

      var zoneAutomatic = Ember.get(this, 'zoneAutomatic');

      if (!zoneAutomatic) {
        var zones = (Ember.get(this, 'zones') || '').trim();

        if (zones) {
          out['zones'] = zones;
        }
      }

      Ember.set(this, 'parameters', out);
    }
  });

  _exports.default = _default;
});