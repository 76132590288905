define("shared/components/marked-down/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K+k8JVOy",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"parsedMarkdown\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/marked-down/template.hbs"
    }
  });

  _exports.default = _default;
});