define("shared/components/modal-confirm-yaml-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xAWo826z",
    "block": "{\"symbols\":[\"row\"],\"statements\":[[7,\"div\"],[11,\"class\",\"container-header-text\"],[9],[0,\"\\n  \"],[7,\"h3\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"modalConfirmYaml2Form.header\"],null],false],[0,\":\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"protip\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"modalConfirmYaml2Form.helpText\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"hr\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"display-name\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"mb-5\"],[9],[0,\"\\n        \"],[7,\"code\"],[9],[0,\"\\n          \"],[1,[22,1,[\"lostKey\"]],false],[0,\": \"],[1,[22,1,[\"lostValue\"]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"footer-actions\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn bg-error\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"modalConfirmYaml2Form.confirm\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"confirm\"]],[10],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn bg-transparent\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"generic.cancel\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/modal-confirm-yaml-switch/template.hbs"
    }
  });

  _exports.default = _default;
});