define("global-admin/components/modal-add-cloud-credential/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3c73fhTI",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"cru-cloud-credential\",null,[[\"cancelAdd\",\"doneSavingCloudCredential\",\"mode\",\"originalModel\",\"parseAndCollectErrors\"],[[27,\"action\",[[22,0,[]],\"cancel\"],null],[27,\"action\",[[22,0,[]],\"close\"],null],[23,[\"mode\"]],[23,[\"modalOpts\",\"cloudCredential\"]],[27,\"action\",[[22,0,[]],\"errorHandler\"],null]]]],false],[0,\"\\n\"],[1,[27,\"top-errors\",null,[[\"errors\"],[[23,[\"errors\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/components/modal-add-cloud-credential/template.hbs"
    }
  });

  _exports.default = _default;
});