define("shared/components/storage-class/provisioner-quobyte/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cmWKgsZv",
    "block": "{\"symbols\":[\"fields\",\"field\"],\"statements\":[[4,\"each\",[[23,[\"fieldsGroup\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"row mb-20\"],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"col span-4\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"class\",\"acc-label\"],[9],[1,[27,\"t\",[[27,\"concat\",[\"cruStorageClass.\",[23,[\"provisioner\"]],\".\",[22,2,[]],\".label\"],null]],null],false],[10],[0,\"\\n\"],[4,\"input-or-display\",null,[[\"editable\",\"value\"],[[23,[\"editing\"]],[27,\"get\",[[23,[\"model\"]],[22,2,[]]],null]]],{\"statements\":[[0,\"        \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"classNames\",\"placeholder\"],[\"text\",[27,\"get\",[[23,[\"model\"]],[22,2,[]]],null],\"form-control\",[27,\"t\",[[27,\"concat\",[\"cruStorageClass.\",[23,[\"provisioner\"]],\".\",[22,2,[]],\".placeholder\"],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/storage-class/provisioner-quobyte/template.hbs"
    }
  });

  _exports.default = _default;
});