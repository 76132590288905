define("global-admin/security/authentication/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NZm4yUoW",
    "block": "{\"symbols\":[\"driver\"],\"statements\":[[7,\"section\"],[11,\"class\",\"header clearfix\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pull-left\"],[9],[0,\"\\n    \"],[7,\"h1\"],[11,\"class\",\"vertical-middle\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"nav.admin.security.authentication\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"section\"],[11,\"class\",\"mb-20\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row nav nav-boxes checked-active\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"filteredDrivers\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"available\"]]],null,{\"statements\":[[4,\"link-to\",[[22,1,[\"route\"]]],[[\"alt\",\"classNames\",\"href\"],[[22,1,[\"label\"]],\"col span-2 nav-box-item driver\",false]],{\"statements\":[[4,\"if\",[[22,1,[\"providerType\"]]],null,{\"statements\":[[0,\"            \"],[7,\"span\"],[11,\"class\",\"badge bg-info driver-badge\"],[9],[0,\"\\n              \"],[1,[22,1,[\"providerType\"]],false],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[7,\"div\"],[12,\"class\",[28,[\"auth-driver \",[22,1,[\"css\"]]]]],[9],[10],[0,\"\\n          \"],[7,\"p\"],[9],[0,\"\\n            \"],[1,[22,1,[\"label\"]],false],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/security/authentication/template.hbs"
    }
  });

  _exports.default = _default;
});