define("global-admin/components/form-google-auth/component", ["exports", "global-admin/components/form-google-auth/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    buttonDisabled: Ember.computed('authConfig.hostname', 'authConfig.adminEmail', 'authConfig.serviceAccountCredential', 'authConfig.oauthCredential', function () {
      var authConfig = Ember.get(this, 'authConfig');
      var isHostnameEmpty = Ember.isEmpty(authConfig.get('hostname'));
      var isAdminEmailEmpty = Ember.isEmpty(authConfig.get('adminEmail'));

      if (!Ember.get(this, 'authConfig.oauthCredential') || !Ember.get(this, 'authConfig.serviceAccountCredential') || isHostnameEmpty || isAdminEmailEmpty) {
        return true;
      }

      return false;
    }),
    destinationUrl: Ember.computed(function () {
      return "".concat(window.location.origin, "/");
    }),
    destinationDomain: Ember.computed(function () {
      return "".concat(window.location.hostname);
    }),
    redirectURI: Ember.computed(function () {
      return "".concat(window.location.origin, "/verify-auth");
    }),
    authorizedJavascriptOrigin: Ember.computed(function () {
      return "".concat(window.location.origin);
    })
  });

  _exports.default = _default;
});