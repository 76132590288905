define("shared/components/form-count/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dE9q4GYV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"pb-5 acc-label\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"formCount.label\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row gutless\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col span-2 m-0\"],[9],[0,\"\\n    \"],[1,[27,\"input-integer\",null,[[\"value\",\"min\",\"max\"],[[23,[\"userInput\"]],[23,[\"min\"]],[23,[\"max\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col offset-1 span-9 hidden-md\"],[9],[0,\"\\n    \"],[1,[27,\"input-slider\",null,[[\"value\",\"valueMin\",\"scaleMin\",\"valueMax\"],[[23,[\"userInput\"]],1,0,[23,[\"sliderMax\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/form-count/template.hbs"
    }
  });

  _exports.default = _default;
});