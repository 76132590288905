define("shared/components/schema/input-storageclass/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EvWcCmZ+",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"searchable-select\",null,[[\"content\",\"class\",\"value\",\"prompt\",\"optionValuePath\",\"optionLabelPath\",\"placeholder\"],[[23,[\"storageClassesOptions\"]],\"form-control\",[23,[\"selected\"]],[27,\"t\",[\"schema.inputStorageClass.prompt\"],null],\"id\",\"displayName\",[27,\"t\",[\"schema.inputStorageClass.prompt\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/schema/input-storageclass/template.hbs"
    }
  });

  _exports.default = _default;
});