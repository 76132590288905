define("shared/mixins/catalog-upgrade", ["exports", "shared/mixins/upgrade-component", "ui/utils/parse-version"], function (_exports, _upgradeComponent, _parseVersion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PREFIX = 'cattle-global-data';
  var AVAILABLE = 'available';

  var _default = Ember.Mixin.create(_upgradeComponent.default, {
    scope: Ember.inject.service(),
    catalog: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    apps: null,
    latestVersion: null,
    templateId: null,
    templateVersion: null,
    checkUpdateStatus: Ember.on('init', Ember.observer('apps', function () {
      var _this = this;

      Ember.get(this, 'catalog').fetchTemplate("".concat(PREFIX, ":").concat(Ember.get(this, 'templateId'))).then(function (template) {
        var toUpgrade = [];
        (Ember.get(_this, 'apps') || []).forEach(function (app) {
          Ember.set(_this, 'model', app);

          _this.updateStatus();

          if (AVAILABLE === Ember.get(_this, 'upgradeStatus')) {
            toUpgrade.push(app);
          }
        });
        Ember.set(_this, 'toUpgrade', toUpgrade);

        if (template.labels) {
          Ember.set(_this, 'templateLables', template.labels);
        }

        _this.initAvailableVersions(template);
      });
    })),
    appVersion: Ember.computed('templateVersion', 'templateName', function () {
      var templateVersion = this.templateVersion,
          templateName = this.templateName;
      return "catalog://?catalog=system-library&template=".concat(templateName, "&version=").concat(templateVersion);
    }),
    initAvailableVersions: function initAvailableVersions(template) {
      var apps = Ember.get(this, 'apps') || [];
      var links = Ember.get(template, 'versionLinks');
      var versions = Object.keys(links).filter(function (key) {
        return !!links[key];
      }).map(function (key) {
        return {
          label: key,
          value: key
        };
      }).sort(function (a, b) {
        return (0, _parseVersion.compare)(a.value, b.value);
      });

      if (Ember.get(versions, 'length') === 0) {
        Ember.set(this, 'availableVersions', []);
        return;
      }

      if (Ember.get(apps, 'length')) {
        var currentVersion = Ember.get(apps, 'firstObject.externalIdInfo.version');
        var availableVersions = versions.filter(function (v) {
          return (0, _parseVersion.compare)(v.value, currentVersion) > 0;
        });
        availableVersions.unshift({
          value: currentVersion,
          label: currentVersion
        });
        Ember.setProperties(this, {
          availableVersions: availableVersions,
          templateVersion: currentVersion
        });
      } else {
        Ember.setProperties(this, {
          availableVersions: versions,
          templateVersion: Ember.get(versions, 'lastObject.value')
        });
      }
    },
    upgradeAvailable: Ember.computed('toUpgrade', function () {
      var toUpgrade = Ember.get(this, 'toUpgrade') || [];
      return Ember.get(toUpgrade, 'length') > 0;
    }),
    actions: {
      upgrade: function upgrade() {
        var _this2 = this;

        var currentVersion = Ember.get(this, 'apps.firstObject.externalIdInfo.version');
        var templateVersion = Ember.get(this, 'templateVersion');

        if (!templateVersion || !currentVersion || templateVersion === currentVersion) {
          return;
        }

        Ember.set(this, 'availableVersions', Ember.get(this, 'availableVersions').slice(Ember.get(this, 'availableVersions').findIndex(function (v) {
          return v.value === templateVersion;
        })));
        var requests = [];
        var apps = Ember.get(this, 'toUpgrade') || [];
        apps.forEach(function (app) {
          var externalInfo = Ember.get(app, 'externalIdInfo');
          requests.push(Ember.get(_this2, 'globalStore').rawRequest({
            url: "/v3/project/".concat(Ember.get(app, 'projectId'), "/apps/").concat(Ember.get(app, 'id')),
            method: 'PUT',
            data: {
              targetNamespace: Ember.get(app, 'targetNamespace'),
              externalId: Ember.get(app, 'externalId').replace("version=".concat(Ember.get(externalInfo, 'version')), "version=".concat(templateVersion))
            }
          }));
        });
        Ember.set(this, 'toUpgrade', []);
        return Ember.RSVP.all(requests);
      }
    }
  });

  _exports.default = _default;
});