define("shared/components/schema/input-int/component", ["exports", "shared/components/schema/input-int/template", "shared/components/input-integer/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    classNames: ['form-control'],
    max: Ember.computed.alias('field.max'),
    min: Ember.computed.alias('field.min')
  });

  _exports.default = _default;
});