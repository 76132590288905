define("global-admin/security/authentication/localauth/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ITsMWLYi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[1,[21,\"headerText\"],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"authPage.localAuth.subtext.enabled.text\"],[[\"appName\"],[[23,[\"settings\",\"appName\"]]]]],false],[0,\" \"],[4,\"link-to\",[\"accounts\"],null,{\"statements\":[[1,[27,\"t\",[\"authPage.localAuth.subtext.enabled.linkText\"],null],false]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[1,[27,\"t\",[\"authPage.localAuth.subtext.enabled.alwaysOn\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/security/authentication/localauth/template.hbs"
    }
  });

  _exports.default = _default;
});