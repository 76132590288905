define("global-admin/global-dns/entries/new/controller", ["exports", "shared/mixins/view-new-edit"], function (_exports, _viewNewEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var HEADERS = [{
    translationKey: 'newMultiClusterApp.overrides.table.scope',
    name: 'scope',
    sort: ['scope']
  }, {
    translationKey: 'newMultiClusterApp.overrides.table.target',
    name: 'target',
    sort: ['target']
  }];

  var _default = Ember.Controller.extend(_viewNewEdit.default, {
    router: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    queryParams: ['id'],
    memberAccessTypes: ['owner'],
    id: null,
    errros: null,
    saveDisabled: false,
    allTargets: null,
    projectsToAddOnUpgrade: null,
    projectsToRemoveOnUpgrade: null,
    errors: null,
    originalModel: null,
    mode: 'new',
    recordType: 'multi',
    headers: HEADERS,
    config: Ember.computed.alias('model.globaldns'),
    primaryResource: Ember.computed.alias('config'),
    actions: {
      addTarget: function addTarget(targetIn) {
        var _this = this;

        var isNew = this.mode === 'new';
        var current = Array.prototype.slice.call(Ember.get(this, 'primaryResource.projectIds') || [], 0);
        var projectsToRemoveOnUpgrade = Ember.get(this, 'projectsToRemoveOnUpgrade') || [];
        var projectsToAddOnUpgrade = Ember.get(this, 'projectsToAddOnUpgrade') || [];

        if (targetIn && !Ember.get(targetIn, 'type')) {
          var projectId = Ember.get(targetIn, 'value');
          Ember.run.next(function () {
            if (isNew) {
              current.pushObject(projectId);
              Ember.set(_this, 'primaryResource.projectIds', current);
            } else {
              var toRemoveMatch = projectsToRemoveOnUpgrade.find(function (removeId) {
                return removeId === projectId;
              });

              if (toRemoveMatch) {
                // a project was remove then re-added
                Ember.set(_this, 'projectsToRemoveOnUpgrade', projectsToRemoveOnUpgrade.removeObject(projectId));
              } else {
                projectsToAddOnUpgrade.pushObject(projectId);
                Ember.set(_this, 'projectsToAddOnUpgrade', projectsToAddOnUpgrade);
              }
            }
          });
        }
      },
      removeTarget: function removeTarget(target) {
        var _this2 = this;

        var isNew = this.mode === 'new';
        var current = Array.prototype.slice.call(Ember.get(this, 'primaryResource.projectIds') || [], 0);
        var projectsToAddOnUpgrade = Ember.get(this, 'projectsToAddOnUpgrade') || [];
        var projectsToRemoveOnUpgrade = Ember.get(this, 'projectsToRemoveOnUpgrade') || [];
        var projectId = Ember.get(target, 'projectId');
        Ember.run.next(function () {
          if (isNew) {
            current.removeObject(projectId);
            Ember.set(_this2, 'primaryResource.projectIds', current);
          } else {
            var targetToAddMatch = projectsToAddOnUpgrade.find(function (addId) {
              return addId === projectId;
            });

            if (targetToAddMatch) {
              Ember.set(_this2, 'projectsToAddOnUpgrade', projectsToAddOnUpgrade.removeObject(projectId));
            } else {
              projectsToRemoveOnUpgrade.pushObject(projectId);
              Ember.set(_this2, 'projectsToRemoveOnUpgrade', projectsToRemoveOnUpgrade);
            }
          }
        });
      },
      cancel: function cancel() {
        Ember.set(this, 'id', null);
        this.transitionToRoute('global-dns.entries.index');
      },
      addAuthorizedPrincipal: function addAuthorizedPrincipal(principal) {
        if (principal) {
          var _this$model$globaldns = this.model.globaldns.members,
              members = _this$model$globaldns === void 0 ? [] : _this$model$globaldns;

          if (!members) {
            members = [];
          }

          Ember.set(principal, 'accessType', 'owner');
          members.pushObject(this.globalStore.createRecord(principal));
          Ember.set(this, 'model.globaldns.members', members);
        }
      },
      removeAuthorizedPrincipal: function removeAuthorizedPrincipal(member) {
        var _this$model$globaldns2 = this.model.globaldns.members,
            members = _this$model$globaldns2 === void 0 ? [] : _this$model$globaldns2;
        members.removeObject(member);
      }
    },
    recordTypeChanged: Ember.observer('recordType', function () {
      var recordType = this.recordType,
          _this$primaryResource = this.primaryResource,
          primaryResource = _this$primaryResource === void 0 ? {} : _this$primaryResource;
      var multiClusterAppId = primaryResource.multiClusterAppId,
          projectIds = primaryResource.projectIds;
      var projectsToRemoveOnUpgrade = this.projectsToRemoveOnUpgrade;

      if (!projectsToRemoveOnUpgrade) {
        projectsToRemoveOnUpgrade = [];
      }

      switch (recordType) {
        case 'multi':
          if (projectIds && projectIds.length) {
            Ember.set(primaryResource, 'projectIds', null);
            Ember.set(this, 'projectsToRemoveOnUpgrade', projectsToRemoveOnUpgrade.pushObjects(projectIds));
          }

          break;

        case 'project':
          if (multiClusterAppId) {
            Ember.set(primaryResource, 'multiClusterAppId', null);
          }

          break;

        default:
          break;
      }
    }),
    combinedProjectIds: Ember.computed('primaryResource.projectIds', 'projectsToRemoveOnUpgrade.[]', 'projectsToAddOnUpgrade.[]', function () {
      var projectsToRemoveOnUpgrade = Ember.get(this, 'projectsToRemoveOnUpgrade') || [];
      var projectsToAddOnUpgrade = Ember.get(this, 'projectsToAddOnUpgrade') || [];
      var projectIds = Ember.get(this, 'primaryResource.projectIds') || [];
      return [].concat(_toConsumableArray(projectsToAddOnUpgrade), _toConsumableArray(projectIds)).filter(function (projectId) {
        return !projectsToRemoveOnUpgrade.includes(projectId);
      });
    }),
    providers: Ember.computed('model.providers.[]', function () {
      var providerId = this.primaryResource.providerId;
      var providers = this.model.providers;

      if (providers.length) {
        if (providerId) {
          var match = providers.findBy('id', providerId);

          if (!match) {
            // you don't have access
            providers.pushObject({
              id: providerId,
              name: providerId
            });
          }
        }
      } else {
        providers = [];

        if (providerId) {
          providers.pushObject({
            id: providerId,
            name: providerId
          });
        }
      }

      return providers.map(function (p) {
        return {
          id: p.id,
          displayName: "".concat(p.name)
        };
      }).sortBy('name');
    }),
    multiClusterApps: Ember.computed('model.multiClusterApps.[]', function () {
      var multiClusterApps = this.model.multiClusterApps;
      var multiClusterAppId = this.primaryResource.multiClusterAppId;

      if (multiClusterApps.length) {
        if (multiClusterAppId) {
          var match = multiClusterApps.findBy('id', multiClusterAppId);

          if (!match) {
            // you don't have access
            multiClusterApps.pushObject({
              id: multiClusterAppId,
              displayName: "".concat(multiClusterAppId, " (no access)")
            });
          }
        }
      } else {
        multiClusterApps = [];

        if (multiClusterAppId) {
          multiClusterApps.pushObject({
            id: multiClusterAppId,
            displayName: "".concat(multiClusterAppId, " (no access)")
          });
        }
      }

      return multiClusterApps.map(function (p) {
        return {
          id: p.id,
          displayName: "".concat(p.displayName)
        };
      }).sortBy('name');
    }),
    projectTargets: Ember.computed('combinedProjectIds.[]', 'projectsToRemoveOnUpgrade.[]', function () {
      var allProjects = Ember.get(this, 'model.allProjects');
      var projectIds = Ember.get(this, 'combinedProjectIds');
      var out = [];
      projectIds.forEach(function (projectId) {
        var projectMatch = allProjects.findBy('id', projectId);

        if (projectMatch) {
          out.pushObject({
            projectId: projectId,
            project: projectMatch,
            projectName: Ember.get(projectMatch, 'displayName'),
            clusterName: Ember.get(projectMatch, 'cluster.displayName'),
            clusterId: projectId.split(':')[0]
          });
        } else {
          out.pushObject({
            projectId: projectId,
            clusterId: projectId.split(':')[0]
          });
        }
      });
      return out;
    }),
    doProjectActions: function doProjectActions() {
      var primaryResource = this.primaryResource;
      var projectsToAddOnUpgrade = this.projectsToAddOnUpgrade,
          projectsToRemoveOnUpgrade = this.projectsToRemoveOnUpgrade;
      var promises = [];

      if (projectsToAddOnUpgrade && projectsToAddOnUpgrade.length > 0) {
        promises.push(primaryResource.doAction('addProjects', {
          projectIds: projectsToAddOnUpgrade
        }));
      }

      if (projectsToRemoveOnUpgrade && projectsToRemoveOnUpgrade.length > 0) {
        promises.push(primaryResource.doAction('removeProjects', {
          projectIds: projectsToRemoveOnUpgrade
        }));
      }

      if (promises.length > 0) {
        return Ember.RSVP.all(promises);
      } else {
        return true;
      }
    },
    didSave: function didSave(neu) {
      var isEdit = this.isEdit;

      if (isEdit) {
        return this.doProjectActions();
      } else {
        return neu;
      }
    },
    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });

  _exports.default = _default;
});