define("shared/components/link-to-cluster-driver/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tm/apqxj",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"linkHovered\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"btn btn-sm bg-transparent pull-right\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"icon icon-minus-circle icon-lg\"],[11,\"aria-label\",\"Remove Recent\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"removeRecent\"],null]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\"],[12,\"class\",[28,[\"machine-driver \",[27,\"if\",[[23,[\"genericIcon\"]],\"generic\"],null],\" \",[21,\"clusterName\"]]]],[9],[10],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"driver-name\"],[9],[0,\"\\n  \"],[1,[27,\"driver-name\",[[23,[\"clusterName\"]]],null],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/link-to-cluster-driver/template.hbs"
    }
  });

  _exports.default = _default;
});