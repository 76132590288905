define("shared/components/form-match-expressions/component", ["exports", "shared/components/form-match-expressions/template", "shared/utils/constants"], function (_exports, _template, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EXISTS = 'Exists';
  var DOES_NOT_EXISTS = 'DoesNotExist';

  var _default = Ember.Component.extend({
    layout: _template.default,
    // Inputs
    initialArray: null,
    editing: true,
    addActionLabel: 'formMatchExpressions.addAction',
    keyLabel: 'formMatchExpressions.key.label',
    valueLabel: 'formMatchExpressions.value.label',
    keyPlaceholder: 'formMatchExpressions.key.placeholder',
    valuePlaceholder: 'formMatchExpressions.value.placeholder',
    init: function init() {
      this._super.apply(this, arguments);

      var ary = [];

      if (Ember.get(this, 'initialArray')) {
        Ember.get(this, 'initialArray').forEach(function (line) {
          ary.push({
            key: Ember.get(line, 'key'),
            operator: Ember.get(line, 'operator'),
            values: (Ember.get(line, 'values') || []).join(',')
          });
        });
      }

      Ember.set(this, 'ary', ary);
    },
    actions: {
      add: function add() {
        var _this = this;

        var ary = Ember.get(this, 'ary');
        ary.pushObject({
          key: '',
          operator: EXISTS
        });
        Ember.run.next(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          var elem = Ember.$('INPUT.key').last()[0];

          if (elem) {
            elem.focus();
          }
        });
      },
      remove: function remove(obj) {
        Ember.get(this, 'ary').removeObject(obj);
      }
    },
    aryObserver: Ember.on('init', Ember.observer('ary.@each.{key,operator,values}', function () {
      Ember.run.debounce(this, 'fireChanged', 100);
    })),
    operatorChoices: _constants.default.VOLUME_NODE_SELECTOR_OPERATOR,
    fireChanged: function fireChanged() {
      var _this2 = this;

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      var arr = [];
      Ember.get(this, 'ary').forEach(function (row) {
        if ([EXISTS, DOES_NOT_EXISTS].indexOf(Ember.get(row, 'operator')) > -1) {
          arr.pushObject({
            key: Ember.get(row, 'key'),
            operator: Ember.get(row, 'operator')
          });
        } else {
          if (Ember.get(row, 'values')) {
            arr.pushObject({
              key: Ember.get(row, 'key'),
              operator: Ember.get(row, 'operator'),
              values: Ember.get(row, 'values').split(',') || []
            });
          }
        }
      });
      Ember.run.next(function () {
        _this2.sendAction('changed', arr);
      });
    }
  });

  _exports.default = _default;
});