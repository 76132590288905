define("shared/components/cru-cluster-template-questions/component", ["exports", "shared/components/cru-cluster-template-questions/template", "shared/utils/constants"], function (_exports, _template, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HEADERS = [{
    translationKey: 'clusterTemplateQuestions.table.question',
    name: 'question',
    sort: ['question'],
    searchField: 'variable'
  }, {
    translationKey: 'clusterTemplateQuestions.table.type',
    name: 'type',
    sort: ['type'],
    width: '150px'
  }, {
    translationKey: 'clusterTemplateQuestions.table.answer',
    name: 'answer',
    sort: ['answer'],
    searchField: 'default',
    width: '250px'
  }, {
    translationKey: 'clusterTemplateQuestions.table.required',
    name: 'required',
    width: '70px'
  }];
  var clusterTemplateTranslationMap = [{
    key: 'defaultClusterRoleForProjectMembers',
    translationKey: 'clusterTemplateQuestions.schemaLabels.defaultClusterRoleForProjectMembers'
  }, {
    key: 'defaultPodSecurityPolicyTemplateId',
    translationKey: 'clusterTemplateQuestions.schemaLabels.defaultPodSecurityPolicyTemplateId'
  }, {
    key: 'desiredAgentImage',
    translationKey: 'clusterTemplateQuestions.schemaLabels.desiredAgentImage'
  }, {
    key: 'desiredAuthImage',
    translationKey: 'clusterTemplateQuestions.schemaLabels.desiredAuthImage'
  }, {
    key: 'dockerRootDir',
    translationKey: 'clusterTemplateQuestions.schemaLabels.dockerRootDir'
  }, {
    key: 'enableClusterAlerting',
    translationKey: 'clusterTemplateQuestions.schemaLabels.enableClusterAlerting'
  }, {
    key: 'enableClusterMonitoring',
    translationKey: 'clusterTemplateQuestions.schemaLabels.enableClusterMonitoring'
  }, {
    key: 'enableNetworkPolicy',
    translationKey: 'clusterTemplateQuestions.schemaLabels.enableNetworkPolicy'
  }, {
    key: 'localClusterAuthEndpoint',
    translationKey: 'clusterTemplateQuestions.schemaLabels.localClusterAuthEndpoint'
  }, {
    key: 'caCerts',
    translationKey: 'clusterTemplateQuestions.schemaLabels.caCerts'
  }, {
    key: 'fqdn',
    translationKey: 'clusterTemplateQuestions.schemaLabels.fqdn'
  }, {
    key: 'addonJobTimeout',
    translationKey: 'clusterTemplateQuestions.schemaLabels.addonJobTimeout'
  }, {
    key: 'addons',
    translationKey: 'clusterTemplateQuestions.schemaLabels.addons'
  }, {
    key: 'addonsInclude',
    translationKey: 'clusterTemplateQuestions.schemaLabels.addonsInclude'
  }, {
    key: 'authentication',
    translationKey: 'clusterTemplateQuestions.schemaLabels.authentication'
  }, {
    key: 'authorization',
    translationKey: 'clusterTemplateQuestions.schemaLabels.authorization'
  }, {
    key: 'bastionHost',
    translationKey: 'clusterTemplateQuestions.schemaLabels.bastionHost'
  }, {
    key: 'cloudProvider',
    translationKey: 'clusterTemplateQuestions.schemaLabels.cloudProvider'
  }, {
    key: 'clusterName',
    translationKey: 'clusterTemplateQuestions.schemaLabels.clusterName'
  }, {
    key: 'dns',
    translationKey: 'clusterTemplateQuestions.schemaLabels.dns'
  }, {
    key: 'ignoreDockerVersion',
    translationKey: 'clusterTemplateQuestions.schemaLabels.ignoreDockerVersion'
  }, {
    key: 'ingress',
    translationKey: 'clusterTemplateQuestions.schemaLabels.ingress'
  }, {
    key: 'kubernetesVersion',
    translationKey: 'clusterTemplateQuestions.schemaLabels.kubernetesVersion'
  }, {
    key: 'monitoring',
    translationKey: 'clusterTemplateQuestions.schemaLabels.monitoring'
  }, {
    key: 'network',
    translationKey: 'clusterTemplateQuestions.schemaLabels.network'
  }, {
    key: 'prefixPath',
    translationKey: 'clusterTemplateQuestions.schemaLabels.prefixPath'
  }, {
    key: 'privateRegistries',
    translationKey: 'clusterTemplateQuestions.schemaLabels.privateRegistries'
  }, {
    key: 'restore',
    translationKey: 'clusterTemplateQuestions.schemaLabels.restore'
  }, {
    key: 'rotateCertificates',
    translationKey: 'clusterTemplateQuestions.schemaLabels.rotateCertificates'
  }, {
    key: 'services',
    translationKey: 'clusterTemplateQuestions.schemaLabels.services'
  }, {
    key: 'sshAgentAuth',
    translationKey: 'clusterTemplateQuestions.schemaLabels.sshAgentAuth'
  }, {
    key: 'sshCertPath',
    translationKey: 'clusterTemplateQuestions.schemaLabels.sshCertPath'
  }, {
    key: 'sshKeyPath',
    translationKey: 'clusterTemplateQuestions.schemaLabels.sshKeyPath'
  }];
  var IGNORED_FIELDS = _constants.default.CLUSTER_TEMPLATE_IGNORED_OVERRIDES;

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    questionsHeaders: HEADERS,
    ignoreFields: IGNORED_FIELDS,
    translationMap: clusterTemplateTranslationMap,
    sortBy: 'name',
    searchText: '',
    applyClusterTemplate: false,
    allQuestions: null,
    schemaQuestions: null,
    descending: false,
    bulkActions: false,
    actions: {
      addQuestion: function addQuestion() {
        this.addQuestion();
      }
    },
    filteredQuestions: Ember.computed('allQuestions.[]', function () {
      var _this$allQuestions = this.allQuestions,
          allQuestions = _this$allQuestions === void 0 ? [] : _this$allQuestions,
          ignoreFields = this.ignoreFields;
      allQuestions = (allQuestions || []).slice();
      allQuestions.forEach(function (q) {
        if (ignoreFields.includes(q.variable)) {
          Ember.set(q, 'isBuiltIn', true);
        }
      });
      return allQuestions || [];
    }),
    rows: Ember.computed('filteredQuestions.[]', function () {
      var allQuestions = this.filteredQuestions,
          applyClusterTemplate = this.applyClusterTemplate,
          clusterTemplateCreate = this.clusterTemplateCreate,
          ignoreFields = this.ignoreFields;
      return (allQuestions || []).filter(function (question) {
        if (applyClusterTemplate) {
          // on apply we want to ignore all fields that have a UI componet and any UI only ovverrides like backupTarget that only have a UI componet and no property target on the cluster.
          return !ignoreFields.includes(question.variable) && !question.variable.includes('azureCloudProvider') && !question.variable.startsWith('uiOverride');
        } else {
          // hide question will only persist on the first time we create a RKE Template revision because the field is not on the question schema so we cant rely on this only
          if (question.hasOwnProperty('hideQuestion') && question.hideQuestion === false) {
            return true;
          } else if (!question.hasOwnProperty('hideQuestion')) {
            if (question.variable) {
              if (clusterTemplateCreate && question.variable.startsWith('uiOverride')) {
                return false;
              } else {
                return true;
              }
            } else {
              // this is an empty override added from add override button
              return true;
            }
          }

          return false;
        }
      });
    }),
    addQuestion: function addQuestion() {
      throw new Error('add question override is required');
    },
    removeQuestion: function removeQuestion() {
      throw new Error('remove question override is required');
    }
  });

  _exports.default = _default;
});