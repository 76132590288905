define("shared/components/cluster-template-revision-upgrade-notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uEH7Ubkr",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"cluster\",\"isClusterTemplateUpgradeAvailable\"]]],null,{\"statements\":[[4,\"tooltip-element\",null,[[\"type\",\"model\",\"tooltipTemplate\",\"inlineBlock\"],[\"tooltip-basic\",[27,\"t\",[\"clusterTemplateRevisionUpgradeNotification.tooltip\"],[[\"revision\"],[[23,[\"cluster\",\"clusterTemplate\",\"latestRevision\",\"displayName\"]]]]],\"tooltip-static\",true]],{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"icon-stack rke-template-revision-upgrade-notification\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"icon icon-circle icon-stack-1x\"],[9],[10],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"icon icon-circle-o icon-stack-1x\"],[9],[10],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"icon icon-notification icon-stack-1x\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/cluster-template-revision-upgrade-notification/template.hbs"
    }
  });

  _exports.default = _default;
});