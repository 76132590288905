define('ember-math-helpers/helpers/log10', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.log10 = log10;
  function log10(params) {
    return Math.log10(params[0]);
  }

  exports.default = Ember.Helper.helper(log10);
});