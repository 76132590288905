define("shared/utils/subscribe-cluster", ["exports", "shared/mixins/subscribe"], function (_exports, _subscribe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(_subscribe.default, {
    updateProjectStore: false,
    scope: Ember.inject.service(),
    watchState: true,
    watchStateOf: Ember.computed.alias('scope.pendingCluster'),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'endpoint', Ember.get(this, 'app.clusterSubscribeEndpoint'));
    },
    validate: function validate() {
      var socket = Ember.get(this, 'subscribeSocket');
      var metadata = socket.getMetadata();
      var socketClusterId = Ember.get(metadata, 'clusterId');
      var currentClusterId = Ember.get(this, 'scope.currentCluster.id');

      if (!currentClusterId || currentClusterId === socketClusterId) {
        return true;
      } else {
        console.error("".concat(this.label, " Subscribe ignoring message, current=").concat(currentClusterId, " socket=").concat(socketClusterId, " ").concat(this.forStr()));
        return false;
      }
    }
  });

  _exports.default = _default;
});