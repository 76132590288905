define("global-admin/components/form-volume-policy/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9ao4rROV",
    "block": "{\"symbols\":[\"choice\",\"choice\",\"index\"],\"statements\":[[4,\"accordion-list-item\",null,[[\"title\",\"detail\",\"status\",\"statusClass\",\"expandAll\",\"expand\"],[[27,\"t\",[\"formVolumePodSecurityPolicy.title\"],null],[27,\"t\",[\"formVolumePodSecurityPolicy.detail\"],null],[23,[\"status\"]],[23,[\"statusClass\"]],[23,[\"expandAll\"]],[27,\"action\",[[22,0,[]],[23,[\"expandFn\"]]],null]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col box span-12\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"class\",\"acc-label\"],[9],[1,[27,\"t\",[\"formVolumePodSecurityPolicy.volumes\"],null],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"readOnly\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"model\",\"volumes\",\"length\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"volumes\"]]],null,{\"statements\":[[0,\"              \"],[1,[27,\"if\",[[22,3,[]],\", \"],null],false],[0,\" \"],[1,[22,2,[]],false],[0,\"\\n\"]],\"parameters\":[2,3]},null],[0,\"          \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"div\"],[9],[1,[27,\"t\",[\"generic.none\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"select\"],[11,\"class\",\"form-control\"],[11,\"multiple\",\"true\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"modifyVolumes\",\"volumes\"],null]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"volumeChoices\"]]],null,{\"statements\":[[0,\"            \"],[7,\"option\"],[12,\"value\",[22,1,[]]],[12,\"selected\",[27,\"array-includes\",[[23,[\"model\",\"volumes\"]],[22,1,[]]],null]],[9],[1,[22,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/components/form-volume-policy/template.hbs"
    }
  });

  _exports.default = _default;
});