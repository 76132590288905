define("global-admin/multi-cluster-apps/catalog/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    access: Ember.inject.service(),
    catalog: Ember.inject.service(),
    scope: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      return Ember.get(this, 'catalog').fetchUnScopedCatalogs();
    },
    model: function model() {
      // Do not use the model result
      var out = {};
      return Ember.get(this, 'catalog').fetchTemplates().then(function () {
        return out;
      });
    },
    resetController: function resetController(controller, isExiting
    /* , transition*/
    ) {
      if (isExiting) {
        Ember.set(controller, 'category', '');
      }
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});