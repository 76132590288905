define("global-admin/components/feature-flags/component", ["exports", "global-admin/components/feature-flags/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FEATURE_HEADERS = [{
    translationKey: 'featureFlags.table.state',
    name: 'state',
    sort: ['state'],
    width: '100px'
  }, {
    translationKey: 'featureFlags.table.name',
    name: 'name',
    sort: ['name'],
    searchField: 'name',
    width: '300px'
  }, {
    translationKey: 'featureFlags.table.description',
    name: 'description',
    sort: ['description'],
    searchField: 'description'
  }];
  var TEMP_FEATURE_DESCRIPTIONS = [{
    feature: 'istio-virtual-service-ui',
    descriptionTranslationKey: 'featureFlags.features.istioVirtualServiceUi'
  }, {
    feature: 'unsupported-storage-drivers',
    descriptionTranslationKey: 'featureFlags.features.unsupportedStorageDrivers'
  }];

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _template.default,
    bulkActions: false,
    descending: false,
    featuresHeaders: FEATURE_HEADERS,
    featureDescriptions: TEMP_FEATURE_DESCRIPTIONS,
    model: null,
    searchText: '',
    sortBy: 'name',
    stickyHeader: false,
    filteredFeatures: Ember.computed('model.@each.{value,description,id,name}', function () {
      var _this = this;

      var model = this.model;
      model.forEach(function (m) {
        var match = _this.featureDescriptions.findBy('feature', m.name);

        if (match) {
          Ember.set(m, 'description', _this.intl.t(match.descriptionTranslationKey));
        }
      });
      return model;
    })
  });

  _exports.default = _default;
});