define("shared/components/form-certificate-row/component", ["exports", "shared/components/form-certificate-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CUSTOM = 'custom';

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    mode: 'default',
    cert: null,
    allCertificates: null,
    editing: null,
    init: function init() {
      this._super.apply(this, arguments);

      var found = (Ember.get(this, 'allCertificates') || []).findBy('id', Ember.get(this, 'cert.certificateId'));

      if (found) {
        Ember.set(this, 'mode', CUSTOM);
      }
    },
    modeChanged: Ember.observer('mode', function () {
      var certificateId = null;
      var mode = Ember.get(this, 'mode');

      if (mode === CUSTOM) {
        certificateId = Ember.get(this, 'allCertificates.firstObject.id');
      }

      var cert = Ember.get(this, 'cert');
      Ember.setProperties(cert, {
        certificateId: certificateId,
        mode: mode
      });
    })
  });

  _exports.default = _default;
});