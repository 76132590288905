define("shared/components/tooltip-toggle-override/component", ["exports", "shared/components/tooltip-toggle-override/template", "shared/mixins/tooltip"], function (_exports, _template, _tooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tooltip.default, {
    layout: _template.default,
    allowOverride: false,
    model: Ember.computed.alias('tooltipService.tooltipOpts.model')
  });

  _exports.default = _default;
});