define("shared/components/schema/input-boolean/component", ["exports", "shared/components/schema/input-boolean/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      Ember.run.next(function () {
        if (_this.get('value') === 'true') {
          _this.set('value', true);
        } else if (_this.get('value') === 'false') {
          _this.set('value', false);
        }
      });
    }
  });

  _exports.default = _default;
});