define("global-admin/cluster-templates/detail/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    clusterTemplates: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      return this.globalStore.find('clustertemplaterevision', params.template_revision_id).then(function (revision) {
        var clusterTemplate;

        if (revision.clusterTemplate) {
          clusterTemplate = revision.clusterTemplate;
        } else {
          clusterTemplate = _this.globalStore.find('clusterTemplate', revision.clusterTemplateId);
        }

        return Ember.RSVP.hash({
          clusterTemplate: clusterTemplate,
          clusterTemplateRevision: revision,
          psps: _this.globalStore.findAll('podSecurityPolicyTemplate'),
          clusterTemplateRevisionId: revision.id
        });
      });
    },
    afterModel: function afterModel() {
      return this.clusterTemplates.fetchQuestionsSchema();
    }
  });

  _exports.default = _default;
});