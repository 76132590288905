define("shared/components/input-text-file/component", ["exports", "ui/utils/platform", "shared/components/input-text-file/template"], function (_exports, _platform, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    growl: Ember.inject.service(),
    layout: _template.default,
    label: null,
    namePlaceholder: null,
    nameRequired: false,
    name: null,
    value: null,
    placeholder: null,
    accept: 'text/*',
    multiple: false,
    minHeight: 0,
    maxHeight: 200,
    inputName: false,
    canChangeName: true,
    canUpload: true,
    showUploadLabel: true,
    concealValue: false,
    tagName: ['div'],
    shouldChangeName: true,
    actions: {
      click: function click() {
        this.$('INPUT[type=file]')[0].click();
      },
      wantsChange: function wantsChange() {
        Ember.set(this, 'shouldChangeName', true);
      }
    },
    actualAccept: Ember.computed('accept', function () {
      if (_platform.isSafari) {
        return '';
      } else {
        return Ember.get(this, 'accept');
      }
    }),
    change: function change(event) {
      var _this = this;

      var input = event.target;

      if (!input.files || !input.files.length) {
        return;
      }

      if (Ember.get(this, 'canChangeName')) {
        var firstName = input.files[0].name;

        if (Ember.get(this, 'multiple')) {
          var ext = firstName.replace(/.*\./, '');
          Ember.set(this, 'name', "multiple.".concat(ext));
        } else {
          Ember.set(this, 'name', firstName);
        }

        Ember.set(this, 'shouldChangeName', false);
      }

      var promises = [];
      var file;

      for (var i = 0; i < input.files.length; i++) {
        file = input.files[i];
        promises.push(new Ember.RSVP.Promise(function (resolve, reject) {
          var reader = new FileReader();

          reader.onload = function (res) {
            var out = res.target.result;
            resolve(out);
          };

          reader.onerror = function (err) {
            Ember.get(_this, 'growl').fromError(Ember.get(err, 'srcElement.error.message'));
            reject(err);
          };

          reader.readAsText(file);
        }));
      }

      Ember.RSVP.all(promises).then(function (res) {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        var value = res.join('\n');
        Ember.set(_this, 'value', value);

        if (value) {
          if (_this.fileChosen) {
            _this.fileChosen();
          }
        }
      }).finally(function () {
        input.value = '';
      });
    }
  });

  _exports.default = _default;
});