define("@rancher/ember-api-store/utils/apply-headers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.copyHeaders = copyHeaders;
  _exports.applyHeaders = applyHeaders;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  // Copy the headers from `more` into the object `dest`
  // including ones with a value of undefined, so they
  // can be removed later by someone calling applyHeaders.
  function copyHeaders(more, dest) {
    if (!more || _typeof(more) !== 'object') {
      return;
    }

    Object.keys(more).forEach(function (key) {
      var val = Ember.get(more, key);
      var normalizedKey = key.toLowerCase();
      dest[normalizedKey] = val;
    });
  } // Apply the headers from `more` into the object `dest`


  function applyHeaders(more, dest, copyUndefined) {
    if (!more || _typeof(more) !== 'object') {
      return;
    }

    Object.keys(more).forEach(function (key) {
      var val = Ember.get(more, key);
      var normalizedKey = key.toLowerCase();

      if (val === undefined && copyUndefined !== true) {
        delete dest[normalizedKey];
      } else {
        dest[normalizedKey] = val;
      }
    });
  }
});