define("shared/components/cru-cluster/component", ["exports", "shared/mixins/view-new-edit", "shared/mixins/child-hook", "shared/utils/load-script", "shared/components/cru-cluster/template"], function (_exports, _viewNewEdit, _childHook, _loadScript, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MEMBER_CONFIG = {
    type: 'clusterRoleTemplateBinding'
  };
  var BUILD_IN_UI = ['tencentkubernetesengine', 'aliyunkubernetescontainerservice', 'huaweicontainercloudengine'];

  var _default = Ember.Component.extend(_viewNewEdit.default, _childHook.default, {
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    access: Ember.inject.service(),
    cookies: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _template.default,
    step: 1,
    initialProvider: null,
    memberConfig: MEMBER_CONFIG,
    newCluster: false,
    needReloadSchema: false,
    reloadingSchema: false,
    schemaReloaded: false,
    applyClusterTemplate: false,
    routeLoading: false,
    showClassicLauncher: false,
    nodePoolErrors: null,
    clusterTemplateRevisionId: null,
    cluster: Ember.computed.alias('model.cluster'),
    originalCluster: Ember.computed.alias('model.originalCluster'),
    primaryResource: Ember.computed.alias('model.cluster'),
    isCustom: Ember.computed.equal('driverInfo.nodeWhich', 'custom'),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments); // On edit pass in initialProvider, for create just set provider directly


      var initialProvider = Ember.get(this, 'initialProvider');

      if (this.cookies.get('classicClusterLaunch')) {
        Ember.set(this, 'showClassicLauncher', true);
      } else {
        Ember.set(this, 'showClassicLauncher', false);
      }

      if (initialProvider) {
        Ember.set(this, 'provider', initialProvider);
      }

      if (Ember.isEmpty(Ember.get(this, 'cluster.id'))) {
        Ember.set(this, 'newCluster', true);
      }

      this.router.on('routeWillChange', function ()
      /* transition */
      {
        if (!_this.isDestroyed || !_this.isDestroying) {
          Ember.set(_this, 'routeLoading', true);
        }
      });
      this.router.on('routeDidChange', function ()
      /* transition */
      {
        if (!_this.isDestroyed || !_this.isDestroying) {
          Ember.set(_this, 'routeLoading', false);
        }
      });
    },
    actions: {
      updateFromYaml: function updateFromYaml(newOpts) {
        if (this.isEdit) {
          this.cluster.merge(newOpts);
        } else {
          this.cluster.replaceWith(newOpts);
        }
      },
      clickNext: function clickNext() {
        this.$('BUTTON[type="submit"]').click();
      },
      close: function close(saved) {
        if (this.close) {
          this.close(saved);
        }
      },
      setNodePoolErrors: function setNodePoolErrors(errors) {
        Ember.set(this, 'nodePoolErrors', errors);
      }
    },
    reloadSchema: Ember.observer('needReloadSchema', function () {
      var _this2 = this;

      if (!Ember.get(this, 'reloadingSchema') && Ember.get(this, 'needReloadSchema')) {
        Ember.set(this, 'reloadingSchema', true);
        Ember.get(this, 'globalStore').findAll('schema', {
          url: '/v3/schemas',
          forceReload: true
        }).then(function () {
          Ember.setProperties(_this2, {
            schemaReloaded: true,
            reloadingSchema: false
          });
        });
      }
    }),
    kontainerDrivers: Ember.computed('model.kontainerDrivers.@each.{id,state}', function () {
      var nope = ['import', 'rancherkubernetesengine'];
      var kDrivers = Ember.get(this, 'model.kontainerDrivers') || [];
      var builtIn = kDrivers.filter(function (d) {
        return d.state === 'active' && (d.builtIn || BUILD_IN_UI.indexOf(d.id) > -1) && !nope.includes(d.id);
      });
      var custom = kDrivers.filter(function (d) {
        return d.state === 'active' && !d.builtIn && d.hasUi;
      });
      return {
        builtIn: builtIn,
        custom: custom
      };
    }),
    providerChoices: Ember.computed('isEdit', 'cluster.rancherKubernetesEngineConfig', 'nodeDrivers.[]', 'schemaReloaded', 'intl.locale', 'kontainerDrivers.[]', function () {
      var _this3 = this;

      var kontainerDrivers = this.kontainerDrivers,
          intl = this.intl;
      var builtIn = kontainerDrivers.builtIn,
          custom = kontainerDrivers.custom;
      var out = [{
        name: 'googlegke',
        driver: 'googlegke',
        kontainerId: 'googlekubernetesengine'
      }, {
        name: 'amazoneks',
        driver: 'amazoneks',
        kontainerId: 'amazonelasticcontainerservice'
      }, {
        name: 'azureaks',
        driver: 'azureaks',
        kontainerId: 'azurekubernetesservice'
      }, {
        name: 'tencenttke',
        driver: 'tencenttke',
        kontainerId: 'tencentkubernetesengine'
      }, {
        name: 'aliyunkcs',
        driver: 'aliyunkcs',
        kontainerId: 'aliyunkubernetescontainerservice'
      }, {
        name: 'huaweicce',
        driver: 'huaweicce',
        kontainerId: 'huaweicontainercloudengine'
      }];
      out = out.filter(function (o) {
        return builtIn.findBy('id', o.kontainerId);
      });

      if (custom.length > 0) {
        custom.forEach(function (c) {
          var name = c.name;
          var configName = "".concat(name, "EngineConfig"); // need the hyph name

          var driverEngineSchema = Ember.get(_this3, 'globalStore').getById('schema', configName.toLowerCase());

          if (driverEngineSchema) {
            out.pushObject({
              displayName: Ember.get(c, 'displayName'),
              driver: Ember.get(c, 'name'),
              kontainerId: Ember.get(c, 'id'),
              name: Ember.get(c, 'name'),
              genericIcon: true // @TODO should have a way for drivers to provide an icon

            });
          } else {
            Ember.set(_this3, 'needReloadSchema', true);
          }
        });
      }

      Ember.get(this, 'model.nodeDrivers').filterBy('state', 'active').sortBy('name').forEach(function (driver) {
        var name = Ember.get(driver, 'name');
        var hasUi = Ember.get(driver, 'hasUi');
        var hasIcon = Ember.get(driver, 'hasBuiltinIconOnly');
        var uiUrl = Ember.get(driver, 'uiUrl');
        var configName = "".concat(name, "Config");
        var driverSchema = Ember.get(_this3, 'globalStore').getById('schema', configName.toLowerCase());

        if (uiUrl) {
          var cssUrl = (0, _loadScript.proxifyUrl)(uiUrl.replace(/\.js$/, '.css'), Ember.get(_this3, 'app.proxyEndpoint'));
          (0, _loadScript.loadStylesheet)(cssUrl, "driver-ui-css-".concat(name));
        }

        if (driverSchema) {
          out.push({
            name: name,
            driver: 'rke',
            genericIcon: !hasUi && !hasIcon,
            nodeComponent: hasUi ? name : 'generic',
            nodeWhich: name,
            nodePool: true
          });
        } else {
          Ember.set(_this3, 'needReloadSchema', true);
        }
      }), out.push({
        name: 'custom',
        driver: 'rke',
        nodeWhich: 'custom',
        nodePool: true,
        preSave: true
      });
      out.push({
        name: 'import',
        driver: 'import',
        preSave: true
      });
      out.forEach(function (driver) {
        var key = "clusterNew.".concat(driver.name, ".label");

        if (!Ember.get(driver, 'displayName') && intl.exists(key)) {
          Ember.set(driver, 'displayName', intl.t(key));
        }
      });

      if (Ember.get(this, 'isEdit') && Ember.get(this, 'cluster.rancherKubernetesEngineConfig')) {
        out = out.filterBy('driver', 'rke');
      }

      out.sortBy('name');
      return out;
    }),
    providerGroups: Ember.computed('providerChoices.@each.{name,driver,nodeComponent,nodeWhich,preSave}', function () {
      var choices = Ember.get(this, 'providerChoices');
      var rkeGroup = [];
      var cloudGroup = [];
      var customGroup = [];
      var importGroup = [];
      choices.forEach(function (item) {
        if (Ember.get(item, 'driver') === 'rke' && Ember.get(item, 'name') !== 'custom') {
          rkeGroup.pushObject(item);
        } else if (Ember.get(item, 'driver') === 'import' && Ember.get(item, 'name') !== 'custom') {
          importGroup.pushObject(item);
        } else if (Ember.get(item, 'name') === 'custom') {
          customGroup.pushObject(item);
        } else {
          cloudGroup.pushObject(item);
        }
      });
      return {
        cloudGroup: cloudGroup,
        customGroup: customGroup,
        importGroup: importGroup,
        rkeGroup: rkeGroup
      };
    }),
    driverInfo: Ember.computed('provider', function () {
      var name = Ember.get(this, 'provider');
      var choices = Ember.get(this, 'providerChoices');
      var entry = choices.findBy('name', name);

      if (entry) {
        return {
          name: entry.name,
          displayName: entry.displayName,
          driverComponent: "cluster-driver/driver-".concat(entry.driver),
          nodeWhich: entry.nodeWhich,
          preSave: !!entry.preSave,
          nodePool: entry.nodePool || false
        };
      }
    }),
    doSave: function doSave(opt) {
      opt = opt || {};
      opt.qp = {
        '_replace': 'true'
      };
      return this._super(opt);
    },
    didSave: function didSave() {
      var _this4 = this;

      var originalCluster = Ember.get(this, 'cluster');
      return originalCluster.waitForCondition('InitialRolesPopulated').then(function () {
        return _this4.applyHooks().then(function () {
          var clone = originalCluster.clone();
          Ember.setProperties(_this4, {
            cluster: clone,
            originalCluster: originalCluster
          });
          return clone;
        });
      });
    },
    doneSaving: function doneSaving(saved) {
      if (Ember.get(this, 'step') === 1 && Ember.get(this, 'driverInfo.preSave')) {
        Ember.setProperties(this, {
          step: 2,
          initialProvider: Ember.get(this, 'provider')
        });
      } else {
        if (this.close) {
          this.close(saved);
        }
      }
    },
    errorSaving: function errorSaving()
    /* err */
    {
      if (this.applyClusterTemplate && this.primaryResource._cachedConfig) {
        var _this$primaryResource = this.primaryResource._cachedConfig,
            localClusterAuthEndpoint = _this$primaryResource.localClusterAuthEndpoint,
            rancherKubernetesEngineConfig = _this$primaryResource.rancherKubernetesEngineConfig,
            enableNetworkPolicy = _this$primaryResource.enableNetworkPolicy,
            defaultClusterRoleForProjectMembers = _this$primaryResource.defaultClusterRoleForProjectMembers,
            defaultPodSecurityPolicyTemplateId = _this$primaryResource.defaultPodSecurityPolicyTemplateId;
        Ember.setProperties(this.primaryResource, {
          localClusterAuthEndpoint: localClusterAuthEndpoint,
          rancherKubernetesEngineConfig: rancherKubernetesEngineConfig,
          enableNetworkPolicy: enableNetworkPolicy,
          defaultClusterRoleForProjectMembers: defaultClusterRoleForProjectMembers,
          defaultPodSecurityPolicyTemplateId: defaultPodSecurityPolicyTemplateId
        });
      }
    }
  });

  _exports.default = _default;
});