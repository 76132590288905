define("shared/components/metrics-graph/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jXmNf9Rx",
    "block": "{\"symbols\":[\"row\",\"item\"],\"statements\":[[4,\"each\",[[23,[\"rows\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"col span-4\"],[9],[0,\"\\n        \"],[7,\"h2\"],[11,\"class\",\"text-left pl-20\"],[9],[1,[27,\"t\",[[27,\"concat\",[\"metrics.\",[22,2,[\"graph\",\"title\"]]],null]],null],false],[10],[0,\"\\n        \"],[1,[27,\"graph-area\",null,[[\"series\",\"loading\",\"formatter\"],[[22,2,[\"series\"]],[23,[\"loading\"]],[22,2,[\"graph\",\"unit\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"noGraphs\"]],[27,\"not\",[[23,[\"loading\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"row mt-20\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"text-center text-muted\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[[23,[\"noDataLabel\"]]],[[\"appName\"],[[23,[\"settings\",\"appName\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"row mt-20\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"icon icon-spinner icon-spin icon-3x\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/metrics-graph/template.hbs"
    }
  });

  _exports.default = _default;
});