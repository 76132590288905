define("shared/components/settings/danger-zone/component", ["exports", "shared/settings/service", "ui/utils/constants", "shared/components/settings/danger-zone/template"], function (_exports, _service, _constants, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    loading: false,
    show: false,
    actions: {
      showNode: function showNode(node) {
        node.toggleProperty('hide');
      },
      show: function show() {
        var _this = this;

        Ember.set(this, 'loading', true);
        Ember.get(this, 'settings').loadAll().then(function () {
          Ember.setProperties(_this, {
            loading: false,
            show: true
          });
        }).catch(function () {
          Ember.setProperties(_this, {
            loading: false,
            show: false
          });
        });
      }
    },
    allowed: Ember.computed('settings.all.@each.{name,customized}', function () {
      var out = {};
      Object.keys(_constants.default.SETTING.ALLOWED).forEach(function (key) {
        var val = Object.assign({}, _constants.default.SETTING.ALLOWED[key]);
        val.descriptionKey = "dangerZone.description.".concat(key);
        out[key] = val;
      });
      return out;
    }),
    current: Ember.computed('allowed.@each.{name,customized}', function () {
      var all = this.settings.asMap,
          allowed = this.allowed;
      var isLocalDev = window.location.host === 'localhost:8000';
      return Object.keys(allowed).filter(function (key) {
        var details = allowed[key];
        return !details['devOnly'] || isLocalDev;
      }).map(function (key) {
        var obj = all[(0, _service.normalizeName)(key)];
        var details = allowed[key];
        var out = Ember.Object.create({
          key: key,
          obj: obj
        });

        if (Ember.get(details, 'kind') === 'multiline') {
          out.set('hide', true);
        }

        if (Ember.get(details, 'kind') === 'json') {
          Ember.setProperties(out, {
            hide: true,
            parsedJSON: JSON.stringify(JSON.parse(out.get('obj.value')), undefined, 2)
          });
        }

        (Object.keys(details) || []).forEach(function (key2) {
          out.set(key2, details[key2]);
        });
        return out;
      });
    })
  });

  _exports.default = _default;
});