define("shared/access/service", ["exports", "shared/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    cookies: Ember.inject.service(),
    settings: Ember.inject.service(),
    session: Ember.inject.service(),
    shibbolethAuth: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    projectStore: Ember.inject.service('store'),
    app: Ember.inject.service(),
    me: null,
    userCode: null,
    // These are set by authenticated/route
    // Is access control enabled
    enabled: true,
    // @TODO-2.0 remove this, always enabled
    // What kind of access control
    provider: null,
    // @TODO-2.0 remove this, and how do i check?
    providers: null,
    principal: null,
    // Are you an admin
    admin: Ember.computed.alias('me.hasAdmin'),
    mustChangePassword: Ember.computed.alias('me.mustChangePassword'),
    // The identity from the session isn't an actual identity model...
    identity: null,
    identityObsvr: Ember.on('init', Ember.observer("session.".concat(_constants.default.SESSION.IDENTITY), function () {
      var obj = this.get("session.".concat(_constants.default.SESSION.IDENTITY)) || {};
      obj.type = 'identity';
      this.set('identity', this.get('globalStore').createRecord(obj));
    })),
    testAuth: function testAuth() {
      // make a call to api base because it is authenticated
      return this.get('globalStore').rawRequest({
        url: ''
      }).then(function ()
      /* xhr*/
      {
        // Auth token still good
        return Ember.RSVP.resolve('Auth Succeeded');
      }, function ()
      /* err */
      {
        // Auth token expired
        return Ember.RSVP.reject('Auth Failed');
      });
    },
    detect: function detect() {
      var _this = this;

      var globalStore = Ember.get(this, 'globalStore');
      return Ember.RSVP.hash({
        pl: globalStore.request({
          url: "settings/".concat(_constants.default.SETTING.PL)
        }),
        firstSetting: globalStore.request({
          url: "settings/".concat(_constants.default.SETTING.FIRST_LOGIN)
        }),
        providers: globalStore.request({
          url: '/v3-public/authProviders'
        })
      }).then(function (_ref) {
        var providers = _ref.providers,
            pl = _ref.pl,
            firstSetting = _ref.firstSetting;

        if (providers && Ember.get(providers, 'length')) {
          Ember.set(_this, 'providers', providers);

          if (Ember.get(providers, 'length') === 1) {
            Ember.set(_this, 'provider', Ember.get(providers, 'firstObject.id'));
          }
        } else {
          Ember.set(_this, 'providers', []);
        }

        if (pl) {
          Ember.get(_this, 'settings').notifyPropertyChange(_constants.default.SETTING.PL);
        }

        Ember.set(_this, 'firstLogin', "".concat(firstSetting.value) === 'true');
        return _this.loadMe();
      }).catch(function (err) {
        Ember.run.next(function () {
          Ember.set(_this, 'app.initError', err && err.message ? err : {
            message: 'No response received'
          });
        });
        Ember.set(_this, 'providers', []);
      });
    },
    loadMe: function loadMe() {
      var _this2 = this;

      var globalStore = Ember.get(this, 'globalStore');
      return globalStore.request({
        url: 'users?me=true'
      }).then(function (users) {
        var me = Ember.get(users, 'firstObject');
        Ember.set(_this2, 'me', me);
        return me;
      }).catch(function (err) {
        return err;
      });
    },
    shibbolethConfigured: function shibbolethConfigured(token) {
      var rv = false;

      if ((token.authProvider || '') === 'shibbolethconfig' && token.userIdentity) {
        rv = true;
      }

      return rv;
    },
    login: function login(providerId, body) {
      var _this3 = this;

      body.description = _constants.default.SESSION.DESCRIPTION;
      body.responseType = 'cookie';
      body.ttl = _constants.default.SESSION.TTL;
      body.labels = {
        'ui-session': 'true'
      };
      var url;
      var provider = (Ember.get(this, 'providers') || []).findBy('id', providerId);

      if (provider) {
        url = provider.actionLinks.login;
      } else {
        return Ember.RSVP.reject({
          type: 'error',
          message: 'Provider config not found'
        });
      }

      console.log('Logging into', url);
      var req = this.get('globalStore').rawRequest({
        method: 'POST',
        url: url,
        data: body
      }).then(function () {
        return _this3.loadMe().catch(function (res) {
          var err;

          try {
            err = res.body;
          } catch (e) {
            console.log('Error loading user', e, res);
            err = {
              type: 'error',
              message: 'Error loading user'
            };
          }

          return Ember.RSVP.reject(err);
        });
      }).catch(function (res) {
        var err;

        try {
          err = res.body;
        } catch (e) {
          console.log('Error logging in', e, res);
          err = {
            type: 'error',
            message: 'Error logging in'
          };
        }

        return Ember.RSVP.reject(err);
      });
      return req;
    },
    clearToken: function clearToken() {
      return this.get('globalStore').rawRequest({
        url: 'tokens?action=logout',
        method: 'POST'
      }).then(function () {
        return true;
      });
    },
    clearSessionKeys: function clearSessionKeys(all) {
      if (all === true) {
        this.get('session').clear();
      } else {
        var values = {};

        _constants.default.TOKEN_TO_SESSION_KEYS.forEach(function (key) {
          values[key] = undefined;
        });

        this.get('session').setProperties(values);
      }

      this.get('cookies').remove(_constants.default.COOKIE.TOKEN);
    },
    allows: function allows(resource, permission, scope) {
      // console.log('rbac-allows',resource,permission,scope);
      if (!resource) {
        // console.log('rbac-result 1 false');
        return false;
      }

      scope = scope ? scope : 'global';
      permission = permission ? permission : 'list';

      if (!Ember.isArray(resource)) {
        resource = [resource];
      }

      var store = Ember.get(this, "".concat(scope, "Store"));

      if (!store) {
        // console.log('rbac-result 2 false');
        return false;
      }

      if (permission === 'list') {
        // console.log('rbac-result 3',!!resource.some(r => store.canList(r)));
        return resource.some(function (r) {
          return store.canList(r);
        });
      } else if (permission === 'create') {
        // console.log('rbac-result 4',!!resource.some(r => store.canCreate(r)));
        return resource.some(function (r) {
          return store.canCreate(r);
        });
      } // console.log('rbac-result 5 false');


      return false;
    }
  });

  _exports.default = _default;
});