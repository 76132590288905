define("shared/utils/errors", ["exports", "@rancher/ember-api-store/models/error"], function (_exports, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = {
    stringify: function stringify(err) {
      var str;

      if (typeof err === 'string') {
        str = err;
      } else if (err instanceof _error.default) {
        if (err.get('code') === 'ActionNotAvailable') {
          str = 'This action is not currently available';
        } else if (err.get('status') === 422) {
          str = 'Validation failed in API:';
          var something = false;

          if (err.get('fieldName')) {
            str += " ".concat(err.get('fieldName'));
            something = true;

            if (err.get('message')) {
              str += " ".concat(err.get('message'));
            }
          }

          if (err.get('detail')) {
            str += " (".concat(err.get('detail'), ")");
            something = true;
          }

          if (!something) {
            if (err.get('message')) {
              str += " ".concat(err.get('message'));
              something = true;
            }
          }

          if (!something) {
            str += " (".concat(err.get('code'), ")");
          }

          switch (err.get('code')) {
            case 'MissingRequired':
              str += ' is required';
              break;

            case 'NotUnique':
              str += ' is not unique';
              break;

            case 'NotNullable':
              str += ' must be set';
              break;

            case 'InvalidOption':
              str += ' is not a valid option';
              break;

            case 'InvalidCharacters':
              str += ' contains invalid characters';
              break;

            case 'MinLengthExceeded':
              str += ' is not long enough';
              break;

            case 'MaxLengthExceeded':
              str += ' is too long';
              break;

            case 'MinLimitExceeded':
              str += ' is too small';
              break;

            case 'MaxLimitExceded':
              str += ' is too big';
              break;
          }
        } else if (err.get('status') === 404) {
          str = "".concat(err.get('message'), ": ").concat(err.get('opt.url'));
        } else {
          str = err.get('message');

          if (err.get('detail')) {
            if (str) {
              str += " (".concat(err.get('detail'), ")");
            } else {
              str = err.get('detail');
            }
          }
        }
      } else if (_typeof(err) === 'object') {
        if (err.message) {
          str = err.message;

          if (err.detail) {
            if (str) {
              str += " (".concat(err.detail, ")");
            } else {
              str = err.detail;
            }
          }
        } else if (err.detail) {
          str = err.detail;
        }
      } else {
        // Good luck...
        str = err;
      }

      return str;
    }
  };
  _exports.default = _default;
});