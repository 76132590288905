define("shared/cookies/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function parseValue(value) {
    if (value.charAt(0) === '"') {
      value = value.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, '\\').replace(/\+/g, ' ');
    }

    return decodeURIComponent(value);
  }

  function getAll() {
    var cookies = document.cookie.split(/;\s*/);
    var ret = {};
    cookies.forEach(function (cookie) {
      if (!cookie) {
        return;
      }

      var idx = cookie.indexOf('=');

      if (idx === -1) {
        idx = cookie.length;
      }

      try {
        var name = decodeURIComponent(cookie.substr(0, idx));
        var val = parseValue(cookie.substr(idx + 1));
        ret[name] = val;
      } catch (e) {}
    });
    return ret;
  }

  var _default = Ember.Service.extend({
    app: Ember.inject.service(),
    unknownProperty: function unknownProperty(key) {
      var all = getAll();
      return all[key] || null;
    },
    setUnknownProperty: function setUnknownProperty(key, value) {
      if (key !== 'app') {
        this.setWithOptions(key, value);
      }

      return value;
    },
    // Opt: expire: date or number of days, path, domain, secure
    setWithOptions: function setWithOptions(name, value, opt) {
      opt = opt || {};
      opt.path = typeof opt.path === 'undefined' ? '/' : opt.path;

      if (typeof opt.secure === 'undefined' || opt.secure === 'auto') {
        opt.secure = window.location.protocol === 'https:';
      } else {
        opt.secure = !!opt.secure;
      }

      var str = "".concat(encodeURIComponent(name), "=").concat(encodeURIComponent(value));

      if (opt.expire) {
        var date;

        if (_typeof(opt.expire) === 'object') {
          date = opt.expire;
        } else {
          date = new Date(new Date().getTime() + 86400000 * opt.expire);
        }

        str += ";expires=".concat(date.toGMTString());
      }

      if (opt.path) {
        str += ";path=".concat(opt.path);
      }

      if (opt.domain) {
        str += ";domain=".concat(opt.domain);
      }

      if (opt.secure) {
        str += ';secure';
      }

      try {
        document.cookie = str;
      } catch (e) {
        return false;
      }

      this.notifyPropertyChange(name);
      return true;
    },
    remove: function remove(name, opt) {
      opt = opt || {};
      opt.expire = new Date('Wed, 24 Feb 1982 18:42:00 UTC');
      return this.setWithOptions(name, 'removed', opt);
    }
  });

  _exports.default = _default;
});