define("shared/components/tooltip-warning/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IcR7SAvC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"m-0 inline-block\"],[9],[1,[27,\"t\",[\"tooltipWarning.notConfigured\"],null],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"dismiss inline-block\"],[9],[7,\"small\"],[9],[1,[27,\"t\",[\"tooltipWarning.dismiss\"],null],false],[10],[0,\" \"],[7,\"i\"],[11,\"class\",\"icon icon-close\"],[9],[10],[0,\" \"],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"hideAccessWarning\"]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/tooltip-warning/template.hbs"
    }
  });

  _exports.default = _default;
});