define('ivy-codemirror/services/code-mirror', ['exports', 'codemirror'], function (exports, _codemirror) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this._instances = Object.create(null);
    },
    fromTextArea: function fromTextArea(id, textarea) {
      return this.registerInstance(id, _codemirror.default.fromTextArea(textarea));
    },
    instanceFor: function instanceFor(id) {
      return this._instances[id];
    },
    registerInstance: function registerInstance(id, instance) {
      this._instances[id] = instance;

      return instance;
    },
    signal: function signal(emitter, type) {
      for (var _len = arguments.length, values = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        values[_key - 2] = arguments[_key];
      }

      _codemirror.default.signal.apply(_codemirror.default, [emitter, type].concat(_toConsumableArray(values)));
    },
    unregisterInstance: function unregisterInstance(id) {
      delete this._instances[id];
    }
  });
});