define("shared/components/shortcut-key/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LXNHadTP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\"],[11,\"class\",\"m-5\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"mr-10\"],[9],[4,\"if\",[[23,[\"modifier\"]]],null,{\"statements\":[[7,\"code\"],[9],[1,[27,\"t\",[[27,\"concat\",[\"modalShortcuts.\",[23,[\"modifier\"]]],null]],[[\"key\"],[[23,[\"key\"]]]]],false],[10]],\"parameters\":[]},{\"statements\":[[7,\"code\"],[9],[1,[21,\"key\"],false],[10]],\"parameters\":[]}],[10],[0,\"\\n  \"],[1,[27,\"t\",[[23,[\"label\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/shortcut-key/template.hbs"
    }
  });

  _exports.default = _default;
});