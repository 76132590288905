define("shared/components/catalog-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tIiqUSyF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[7,\"div\"],[11,\"role\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"showIcon\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"catalog-icon\"],[9],[0,\"\\n    \"],[7,\"img\"],[12,\"src\",[28,[[23,[\"app\",\"baseAssets\"]],\"assets/images/generic-catalog.svg\"]]],[12,\"data-src\",[23,[\"model\",\"links\",\"icon\"]]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"hr\"],[11,\"class\",\"ml-20 mr-20\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"model\",\"certified\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"certified\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[1,[23,[\"model\",\"certified\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\"],[11,\"class\",\"itemwrap mt-30\"],[9],[0,\"\\n    \"],[7,\"h3\"],[11,\"class\",\"mb-0\"],[9],[0,\"\\n      \"],[1,[23,[\"model\",\"displayName\"]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],[23,[\"launchAction\"]],[23,[\"model\",\"id\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/catalog-box/template.hbs"
    }
  });

  _exports.default = _default;
});