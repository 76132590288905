define("shared/components/check-override-allowed/component", ["exports", "shared/components/check-override-allowed/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    questions: null,
    paramName: null,
    clusterTemplateRevision: null,
    applyClusterTemplate: false
  });

  _exports.default = _default;
});