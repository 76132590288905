define("global-admin/components/form-google-auth-read/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BMk1r2Yo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col span-4\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"inline-form\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"class\",\"acc-label pb-5\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"authPage.googleoauth.notAuthenticated.form.adminEmail.labelText\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[9],[0,\"\\n        \"],[1,[23,[\"authConfig\",\"adminEmail\"]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col span-4\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"inline-form\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"class\",\"acc-label pb-5\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"authPage.googleoauth.notAuthenticated.form.hostname.labelText\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[9],[0,\"\\n        \"],[1,[23,[\"authConfig\",\"hostname\"]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col span-4\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"inline-form\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"class\",\"acc-label pb-5\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"authPage.googleoauth.notAuthenticated.form.nestedGroupMembershipEnabled.labelText\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"authConfig\",\"nestedGroupMembershipEnabled\"]]],null,{\"statements\":[[0,\"          \"],[1,[27,\"t\",[\"generic.enabled\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[27,\"t\",[\"generic.disabled\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/components/form-google-auth-read/template.hbs"
    }
  });

  _exports.default = _default;
});