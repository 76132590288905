define("shared/helpers/run-time", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.runTime = runTime;
  _exports.default = void 0;

  function runTime(params) {
    var s = (0, _moment.default)(params[0]);
    var e = (0, _moment.default)(params[1]);
    var time = Math.round(e.diff(s) / 100) / 10;

    if (time) {
      if (time > 60) {
        time = Math.round(time);
      }

      return "".concat(time, " sec");
    } else {
      return '<span class="text-muted">-</span>'.htmlSafe();
    }
  }

  var _default = Ember.Helper.helper(runTime);

  _exports.default = _default;
});