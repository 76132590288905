define("global-admin/global-dns/providers/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    model: function model(params) {
      if (Ember.get(params, 'id')) {
        return this.globalStore.find('globaldnsprovider', params.id).then(function (resp) {
          if (resp) {
            return resp.clone();
          } else {
            return Ember.RSVP.reject('Global DNS Provider Not Found');
          }
        });
      } else {
        return this.initConfig(Ember.get(params, 'activeProvider') || 'route53');
      }
    },
    afterModel: function afterModel(model
    /* , transition */
    ) {
      var members = model.members;

      if (members) {
        return this.fetchMembers(model);
      }

      return model;
    },
    setupController: function setupController(controller, model) {
      if (model && Ember.get(model, 'id')) {
        controller.set('mode', 'edit');
      }

      if (Ember.get(model, 'provider')) {
        controller.set('activeProvider', Ember.get(model, 'provider'));
      }

      this._super(controller, model);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        Ember.setProperties(controller, {
          id: null,
          activeProvider: 'route53',
          mode: 'new'
        });
      }
    },
    queryParams: {
      id: {
        refreshModel: true
      },
      activeProvider: {
        refreshModel: true
      }
    },
    initConfig: function initConfig() {
      var configType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'route53';

      if (configType === 'route53') {
        return this.globalStore.createRecord({
          type: 'globaldnsprovider',
          providerName: 'route53',
          route53ProviderConfig: this.globalStore.createRecord({
            type: 'route53ProviderConfig'
          })
        });
      } else if (configType === 'cloudflare') {
        return this.globalStore.createRecord({
          type: 'globaldnsprovider',
          providerName: 'cloudflare',
          cloudflareProviderConfig: this.globalStore.createRecord({
            type: 'cloudflareProviderConfig'
          })
        });
      } else if (configType === 'alidns') {
        return this.globalStore.createRecord({
          type: 'globaldnsprovider',
          providerName: 'alidns',
          alidnsProviderConfig: this.globalStore.createRecord({
            type: 'alidnsProviderConfig'
          })
        });
      }
    },
    fetchMembers: function fetchMembers(model) {
      var _this = this;

      var members = model.members;

      if (members) {
        var membersPromises = [];
        members.forEach(function (member) {
          if (Ember.get(member, 'userPrincipalId')) {
            membersPromises.push(_this.globalStore.find('principal', member.userPrincipalId));
          } else if (Ember.get(member, 'groupPrincipalId')) {
            membersPromises.push(_this.globalStore.find('principal', member.groupPrincipalId));
          }
        });
        return Ember.RSVP.all(membersPromises);
      }
    }
  });

  _exports.default = _default;
});