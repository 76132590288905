define("shared/components/empty-table/component", ["exports", "shared/components/empty-table/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    app: Ember.inject.service(),
    scope: Ember.inject.service(),
    layout: _template.default,
    classNames: ['row border-dash'],
    showNew: true,
    disabled: false,
    ctx: 'projectId' // or clusterId

  });

  _exports.default = _default;
});