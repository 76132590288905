define("shared/role-template/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    globalStore: Ember.inject.service(),
    _allRoleTemplates: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, '_allRoleTemplates', Ember.get(this, 'globalStore').all('roleTemplate'));
      Ember.get(this, 'globalStore').find('roleTemplate');
    },
    fetchFilteredRoleTemplates: function fetchFilteredRoleTemplates() {
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
        filter: {
          locked: false,
          hidden: false
        }
      };
      var store = Ember.get(this, 'globalStore');
      return store.find('roleTemplate', id, opts);
    },
    allFilteredRoleTemplates: Ember.computed('_allRoleTemplates.@each.{locked,hidden}', function () {
      return Ember.get(this, '_allRoleTemplates').filter(function (rt) {
        return !Ember.get(rt, 'locked') && !Ember.get(rt, 'hidden');
      });
    }),
    allVisibleRoleTemplates: Ember.computed('_allRoleTemplates.@each.{locked,hidden}', function () {
      return Ember.get(this, '_allRoleTemplates').filter(function (rt) {
        return !Ember.get(rt, 'hidden');
      });
    })
  });

  _exports.default = _default;
});