define("shared/components/banner-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "psaA1LCW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[27,\"or\",[[23,[\"showIcon\"]],[23,[\"titleStr\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,[\"showIcon\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"banner-icon\"],[9],[0,\"\\n      \"],[7,\"span\"],[12,\"class\",[28,[\"icon \",[21,\"icon\"]]]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"titleStr\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"banner-title\"],[12,\"style\",[21,\"titleStyle\"]],[9],[0,\"\\n      \"],[1,[21,\"titleStr\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[7,\"div\"],[11,\"class\",\"banner-message\"],[9],[0,\"\\n\"],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[1,[21,\"message\"],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"showClose\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"banner-close\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"icon icon-sm icon-close\"],[9],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/banner-message/template.hbs"
    }
  });

  _exports.default = _default;
});