define("shared/components/node-taints/component", ["exports", "shared/components/node-taints/template", "ui/utils/constants"], function (_exports, _template, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NO_SCHEDULE = 'NoSchedule';
  var NO_EXECUTE = 'NoExecute';
  var PREFER_NO_SCHEDULE = 'PreferNoSchedule';
  var EFFECTS = [{
    label: NO_SCHEDULE,
    value: NO_SCHEDULE
  }, {
    label: NO_EXECUTE,
    value: NO_EXECUTE
  }, {
    label: PREFER_NO_SCHEDULE,
    value: PREFER_NO_SCHEDULE
  }];

  var _default = Ember.Component.extend({
    layout: _template.default,
    setTaints: null,
    isNode: false,
    effects: EFFECTS,
    taints: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.initTaints();
    },
    actions: {
      addTaint: function addTaint() {
        var taint = Ember.Object.create({
          key: '',
          value: '',
          effect: NO_SCHEDULE
        });
        Ember.get(this, 'taints').pushObject(taint);
      },
      removeTaint: function removeTaint(taint) {
        Ember.get(this, 'taints').removeObject(taint);
      }
    },
    taintsDidChange: Ember.observer('taints.@each.{key,value,effect}', function () {
      var out = [];
      Ember.get(this, 'taints').filter(function (taint) {
        return Ember.get(taint, 'key');
      }).forEach(function (taint) {
        var existing = out.find(function (t) {
          return Ember.get(t, 'key') === Ember.get(taint, 'key') && Ember.get(t, 'effect') === Ember.get(taint, 'effect');
        });

        if (existing) {
          Ember.set(existing, 'value', Ember.get(taint, 'value'));
        } else {
          out.push({
            key: Ember.get(taint, 'key'),
            value: Ember.get(taint, 'value'),
            effect: Ember.get(taint, 'effect')
          });
        }
      });

      if (Ember.get(this, 'setTaints')) {
        this.setTaints(out);
      } else if (Ember.get(this, 'isNode')) {
        Ember.set(this, 'model.taints', out);
      } else {
        Ember.set(this, 'model.nodeTaints', out);
      }
    }),
    initTaints: function initTaints() {
      Ember.set(this, 'taints', (Ember.get(this, 'model.nodeTaints') || Ember.get(this, 'model.taints') || []).map(function (taint) {
        return {
          key: Ember.get(taint, 'key'),
          value: Ember.get(taint, 'value'),
          effect: Ember.get(taint, 'effect'),
          readonly: _constants.default.LABEL_PREFIX_TO_IGNORE.find(function (L) {
            return Ember.get(taint, 'key').startsWith(L);
          })
        };
      }));
    }
  });

  _exports.default = _default;
});