define("shared/mixins/upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    growl: Ember.inject.service(),
    actions: {
      upload: function upload() {
        this.$('INPUT[type=file]')[0].click();
      }
    },
    change: function change(event) {
      var _this = this;

      var input = event.target;

      if (input.files && input.files[0]) {
        var file = input.files[0];
        var reader = new FileReader();

        reader.onerror = function (err) {
          Ember.get(_this, 'growl').fromError(Ember.get(err, 'srcElement.error.message'));
        };

        reader.onload = function (event2) {
          var out = event2.target.result;

          _this.send(Ember.get(_this, 'uploadAction'), out);

          input.value = '';
        };

        reader.readAsText(file);
      }
    }
  });

  _exports.default = _default;
});