define("global-admin/security/policies/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    model: function model() {
      var policy = this.get('globalStore').createRecord({
        type: 'podSecurityPolicyTemplate',
        name: '',
        allowPrivilegeEscalation: false,
        defaultAllowPrivilegeEscalation: false,
        hostIPC: false,
        hostNetwork: false,
        hostPID: false,
        privileged: false,
        readOnlyRootFilesystem: false
      });
      return {
        policy: policy
      };
    }
  });

  _exports.default = _default;
});