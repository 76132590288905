define("shared/mixins/metrics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SINGLE_METRICS = ['etcd-leader-change', 'etcd-server-leader-sum', 'etcd-server-failed-proposal', 'ingresscontroller-upstream-response-seconds'];
  var GRAPHS = {
    'apiserver-request-count': {
      'priority': 301,
      'unit': 'number'
    },
    'apiserver-request-latency': {
      'priority': 300,
      'unit': 'ms'
    },
    'cluster-cpu-load': {
      'priority': 101,
      'unit': 'number'
    },
    'cluster-cpu-usage': {
      'priority': 100,
      'unit': 'percent'
    },
    'cluster-disk-io': {
      'priority': 104,
      'unit': 'bps'
    },
    'cluster-fs-usage-percent': {
      'priority': 103,
      'unit': 'percent'
    },
    'cluster-memory-usage': {
      'priority': 102,
      'unit': 'percent'
    },
    'cluster-network-io': {
      'priority': 106,
      'unit': 'bps'
    },
    'cluster-network-packet': {
      'priority': 105,
      'unit': 'pps'
    },
    'controllermanager-queue-depth': {
      'priority': 310,
      'unit': 'number'
    },
    'etcd-db-bytes-sum': {
      'priority': 204,
      'unit': 'byte'
    },
    'etcd-disk-operate': {
      'priority': 209,
      'unit': 'seconds'
    },
    'etcd-grpc-client': {
      'priority': 203,
      'unit': 'bps'
    },
    'etcd-leader-change': {
      'priority': 202,
      'unit': 'number'
    },
    'etcd-peer-traffic': {
      'priority': 206,
      'unit': 'bps'
    },
    'etcd-raft-proposals': {
      'priority': 207,
      'unit': 'number'
    },
    'etcd-rpc-rate': {
      'priority': 208,
      'unit': 'ops'
    },
    'etcd-server-failed-proposal': {
      'priority': 201,
      'unit': 'number'
    },
    'etcd-server-leader-sum': {
      'priority': 200,
      'unit': 'number'
    },
    'etcd-stream': {
      'priority': 205,
      'unit': 'number'
    },
    'etcd-sync-duration': {
      'priority': 210,
      'unit': 'seconds'
    },
    'fluentd-buffer-queue-length': {
      'priority': 300,
      'unit': 'number'
    },
    'fluentd-input-record-number': {
      'priority': 301,
      'unit': 'number'
    },
    'fluentd-output-errors': {
      'priority': 302,
      'unit': 'number'
    },
    'fluentd-output-record-number': {
      'priority': 303,
      'unit': 'number'
    },
    'ingresscontroller-nginx-connection': {
      'priority': 330,
      'unit': 'number'
    },
    'ingresscontroller-request-process-time': {
      'priority': 331,
      'unit': 'seconds'
    },
    'ingresscontroller-upstream-response-seconds': {
      'priority': 332,
      'unit': 'seconds'
    },
    'node-cpu-load': {
      'priority': 501,
      'unit': 'number'
    },
    'node-cpu-usage': {
      'priority': 500,
      'unit': 'percent'
    },
    'node-disk-io': {
      'priority': 504,
      'unit': 'bps'
    },
    'node-fs-usage-percent': {
      'priority': 503,
      'unit': 'percent'
    },
    'node-memory-usage': {
      'priority': 502,
      'unit': 'percent'
    },
    'node-network-io': {
      'priority': 506,
      'unit': 'bps'
    },
    'node-network-packet': {
      'priority': 505,
      'unit': 'pps'
    },
    'scheduler-e-2-e-scheduling-latency-seconds-quantile': {
      'priority': 320,
      'unit': 'seconds'
    },
    'scheduler-pod-unscheduler': {
      'priority': 322,
      'unit': 'number'
    },
    'scheduler-total-preemption-attempts': {
      'priority': 321,
      'unit': 'number'
    },
    'container-cpu-usage': {
      'priority': 800,
      'unit': 'mcpu'
    },
    'container-disk-io': {
      'priority': 804,
      'unit': 'bps'
    },
    'container-memory-usage-bytes-sum': {
      'priority': 801,
      'unit': 'byte'
    },
    'container-network-io': {
      'priority': 803,
      'unit': 'bps'
    },
    'container-network-packet': {
      'priority': 802,
      'unit': 'pps'
    },
    'pod-cpu-usage': {
      'priority': 700,
      'unit': 'mcpu'
    },
    'pod-disk-io': {
      'priority': 704,
      'unit': 'bps'
    },
    'pod-memory-usage-bytes-sum': {
      'priority': 701,
      'unit': 'byte'
    },
    'pod-network-io': {
      'priority': 703,
      'unit': 'bps'
    },
    'pod-network-packet': {
      'priority': 702,
      'unit': 'pps'
    },
    'workload-cpu-usage': {
      'priority': 600,
      'unit': 'mcpu'
    },
    'workload-disk-io': {
      'priority': 604,
      'unit': 'bps'
    },
    'workload-memory-usage-bytes-sum': {
      'priority': 601,
      'unit': 'byte'
    },
    'workload-network-io': {
      'priority': 603,
      'unit': 'bps'
    },
    'workload-network-packet': {
      'priority': 602,
      'unit': 'pps'
    },
    'istio-global-request-volume': {
      'priority': 1001,
      'unit': 'ops'
    },
    'istio-global-success-rate': {
      'priority': 1002,
      'unit': 'percent'
    },
    'istio-4xxs': {
      'priority': 1003,
      'unit': 'number'
    },
    'istio-5xxs': {
      'priority': 1004,
      'unit': 'number'
    },
    'istio-global-request-volume-top10': {
      'priority': 1005,
      'unit': 'ops'
    },
    'istio-4xxs-top10': {
      'priority': 1006,
      'unit': 'number'
    },
    'istio-5xxs-top10': {
      'priority': 1007,
      'unit': 'number'
    },
    'istio-request-duration-p99-top10': {
      'priority': 1008,
      'unit': 'seconds'
    },
    'istio-request-duration-p90-top10': {
      'priority': 1009,
      'unit': 'seconds'
    },
    'istio-request-duration-p50-top10': {
      'priority': 1010,
      'unit': 'seconds'
    }
  };

  var _default = Ember.Mixin.create({
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    growl: Ember.inject.service(),
    filters: null,
    graphs: null,
    state: null,
    projectScope: false,
    metricParams: null,
    timeOutAnchor: null,
    largeScale: false,
    istio: false,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'state', {
        loading: false,
        detail: !Ember.get(this, 'largeScale'),
        noGraphs: false,
        isCustom: false,
        from: null,
        to: null,
        interval: null
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.clearTimeOut();

      this._super();
    },
    updateData: function updateData(out) {
      var _this = this;

      var single = [];
      var graphs = [];
      out.sortBy('graph.priority').forEach(function (item) {
        if (SINGLE_METRICS.indexOf(Ember.get(item, 'graph.title')) > -1) {
          single.push(item);
        } else if ((Ember.get(item, 'series') || []).find(function (serie) {
          return Ember.get(serie, 'points.length') > 1;
        })) {
          graphs.push(item);
        }
      });
      Ember.setProperties(this, {
        'state.noGraphs': Ember.get(graphs, 'length') === 0,
        graphs: graphs,
        single: single
      });

      if (!Ember.get(this, 'state.isCustom')) {
        var interval = Ember.get(this, 'state.interval');
        var timeout = parseInt(interval.substr(0, Ember.get(interval, 'length') - 1), 10);

        if (Ember.get(this, 'istio')) {
          timeout = timeout * 5;
        }

        timeout = timeout > 5 ? timeout : 5;
        var timeOutAnchor = setTimeout(function () {
          _this.send('query', false);
        }, timeout * 1000);
        Ember.set(this, 'timeOutAnchor', timeOutAnchor);
      }
    },
    clearTimeOut: function clearTimeOut() {
      var timeOutAnchor = Ember.get(this, 'timeOutAnchor');

      if (timeOutAnchor) {
        clearTimeout(timeOutAnchor);
        Ember.set(this, 'timeOutAnchor', timeOutAnchor);
      }
    },
    actions: {
      query: function query() {
        var _this2 = this;

        var showLoading = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
        this.clearTimeOut();
        var gs = Ember.get(this, 'globalStore');

        if (showLoading) {
          Ember.set(this, 'state.loading', true);
        }

        var metricParams = {};

        if (Ember.get(this, 'resourceId')) {
          if (Ember.get(this, 'metricParams')) {
            metricParams = Ember.get(this, 'metricParams');
          } else {
            Ember.set(metricParams, 'instance', Ember.get(this, 'resourceId'));
          }
        }

        var url;

        if (Ember.get(this, 'projectScope')) {
          url = '/v3/projectmonitorgraphs?action=query';
        } else {
          url = '/v3/clustermonitorgraphs?action=query';
        }

        var filters = Ember.get(this, 'filters') || {};
        var cluster = Ember.get(this, 'scope.currentCluster.id');
        var project = Ember.get(this, 'scope.currentProject.id');

        if (project) {
          Ember.set(filters, 'projectId', project);
        } else if (cluster) {
          Ember.set(filters, 'clusterId', cluster);
        }

        gs.rawRequest({
          url: url,
          method: 'POST',
          data: {
            filters: filters,
            metricParams: metricParams,
            interval: Ember.get(this, 'state.interval'),
            isDetails: Ember.get(this, 'state.detail'),
            from: Ember.get(this, 'state.from'),
            to: Ember.get(this, 'state.to')
          }
        }).then(function (metrics) {
          if (_this2.isDestroyed || _this2.isDestroying) {
            return;
          }

          var metricsBody = Ember.get(JSON.parse(Ember.get(metrics, 'body')) || {}, 'data') || [];
          var out = metricsBody.map(function (metric) {
            var title = Ember.get(metric, 'graphID').split(':')[1];
            var graph = GRAPHS[title];

            if (graph) {
              Ember.set(graph, 'title', title);
            }

            return {
              graph: graph,
              series: (Ember.get(metric, 'series') || []).sortBy('name')
            };
          });

          _this2.updateData(out);
        }).catch(function (err) {
          if (_this2.isDestroyed || _this2.isDestroying) {
            return;
          }

          Ember.setProperties(_this2, {
            'state.noGraphs': true,
            graphs: [],
            single: []
          });

          if (Ember.get(_this2, 'scope.currentCluster.isMonitoringReady')) {
            Ember.get(_this2, 'growl').fromError(Ember.get(err, 'body.message') || Ember.get(err, 'message') || err);
          }
        }).finally(function () {
          if (_this2.isDestroyed || _this2.isDestroying) {
            return;
          }

          if (showLoading) {
            Ember.set(_this2, 'state.loading', false);
          }
        });
      }
    }
  });

  _exports.default = _default;
});