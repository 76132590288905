define("global-admin/clusters/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    model: function model() {
      var store = this.get('globalStore');
      return store.findAll('cluster').then(function ()
      /* resp */
      {
        var clusters = store.all('cluster');

        if (clusters.length > 0) {
          clusters.forEach(function (cluster) {
            cluster.store.findAll('etcdbackup');
          });
        }

        return {
          clusters: clusters
        };
      });
    }
  });

  _exports.default = _default;
});