define("shared/components/storage-class/provisioner-scaleio/component", ["exports", "shared/components/storage-class/provisioner-scaleio/template", "shared/mixins/storage-class-provisioner"], function (_exports, _template, _storageClassProvisioner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FIELDS = ['gateway', 'system', 'protectionDomain', 'storagePool', {
    id: 'storageMode',
    options: [{
      value: 'ThinProvisioned',
      translationKey: 'cruStorageClass.scaleio.storageMode.ThinProvisioned'
    }, {
      value: 'ThickProvisioned',
      translationKey: 'cruStorageClass.scaleio.storageMode.ThickProvisioned'
    }]
  }, 'secretRef', {
    id: 'readOnly',
    options: [{
      value: 'false',
      translationKey: 'generic.no'
    }, {
      value: 'true',
      translationKey: 'generic.yes'
    }]
  }, 'fsType'];

  var _default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,
    provisioner: 'scaleio',
    fields: FIELDS
  });

  _exports.default = _default;
});