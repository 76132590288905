define("shared/components/form-global-resource-roles/component", ["exports", "shared/components/form-global-resource-roles/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['row'],
    multiClusterApp: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var roles = this.multiClusterApp.roles;

      if (!roles || roles.length === 0) {
        Ember.run.next(function () {
          _this.addRole('project-member', '');
        });
      }
    },
    role: Ember.computed('multiClusterApp.roles.[]', {
      get: function get() {
        var _this$multiClusterApp = this.multiClusterApp.roles,
            roles = _this$multiClusterApp === void 0 ? [] : _this$multiClusterApp;
        return roles.find(function (role) {
          return role === 'project-member' || role === 'cluster-owner';
        });
      },
      set: function set(key, value) {
        this.addRole(value, this.role);
        return value;
      }
    }),
    otherRoles: Ember.computed('multiClusterApp.roles.[]', function () {
      var _this$multiClusterApp2 = this.multiClusterApp.roles,
          roles = _this$multiClusterApp2 === void 0 ? [] : _this$multiClusterApp2;
      return roles.filter(function (role) {
        return role !== 'cluster-owner' && role !== 'project-member';
      });
    })
  });

  _exports.default = _default;
});