define("global-admin/cluster-templates/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZnpMRsK1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"header clearfix\"],[9],[0,\"\\n  \"],[7,\"h1\"],[11,\"class\",\"pull-left\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"clusterTemplatesPage.new.header\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[5,\"cru-cluster-template\",[],[[\"@clusterTemplate\",\"@clusterTemplateRevision\",\"@users\",\"@psps\",\"@mode\",\"@forceExpandOnInit\",\"@cancel\",\"@done\"],[[23,[\"model\",\"clusterTemplate\"]],[23,[\"model\",\"clusterTemplateRevision\"]],[23,[\"model\",\"users\"]],[23,[\"model\",\"psps\"]],\"new\",false,[27,\"action\",[[22,0,[]],\"cancel\"],null],[27,\"action\",[[22,0,[]],\"done\"],null]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/cluster-templates/new/template.hbs"
    }
  });

  _exports.default = _default;
});