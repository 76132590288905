define("@rancher/ember-api-store/instance-initializers/initialize-store-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = initializeStoreService;

  function initializeStoreService(application) {
    var container = application.lookup ? application : application.container;
    container.lookup('service:store');
  }
});