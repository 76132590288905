define("shared/components/cluster-template-override-toggle/component", ["exports", "shared/components/cluster-template-override-toggle/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    path: null,
    configVariable: null,
    questions: null,
    btnToggled: false,
    tagName: '',
    didReceiveAttrs: function didReceiveAttrs() {
      var path = this.path,
          questions = this.questions;

      if (path && questions && questions.length) {
        var match = questions.findBy('variable', path);

        if (match && !match.hideQuestion) {
          Ember.set(this, 'btnToggled', true);
        }
      }
    },
    actions: {
      addOverride: function addOverride(enabeld) {
        this.addOverride(enabeld, this.tooltipModel);
      }
    },
    tooltipModel: Ember.computed('path', 'configVariable', function () {
      var path = this.path,
          configVariable = this.configVariable;
      return {
        path: path,
        configVariable: configVariable
      };
    }),
    addOverride: function addOverride() {
      throw new Error('addOverride action is required!');
    }
  });

  _exports.default = _default;
});