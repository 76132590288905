define("shared/components/input-or-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0npUFna1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"editable\"]]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"hasValue\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"obfuscate\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[12,\"class\",[21,\"classesForDisplay\"]],[9],[0,\"\\n        \"],[1,[21,\"obfuscatedValue\"],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[12,\"class\",[21,\"classesForDisplay\"]],[9],[0,\"\\n        \"],[1,[21,\"value\"],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[[21,\"classesForDisplay\"],\" text-muted\"]]],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"generic.na\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/input-or-display/template.hbs"
    }
  });

  _exports.default = _default;
});