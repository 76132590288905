define("global-admin/security/authentication/shibboleth/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    model: function model() {
      return this.get('gloablStore').find('config', null, {
        forceReload: true
      }).then(function (collection) {
        return collection;
      });
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting && transition.targetName !== 'error') {
        controller.set('editing', false);
      }
    }
  });

  _exports.default = _default;
});