define("shared/components/modal-confirm-deactivate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "388Gn+0s",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"protip\"],[9],[1,[27,\"t\",[\"modalConfirmDeactivate.protip\"],[[\"key\",\"isServiceButton\"],[[23,[\"alternateLabel\"]],[23,[\"isService\",\"button\"]]]]],false],[10],[0,\"\\n\"],[7,\"hr\"],[9],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"isClusterDriver\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"mb-20 mt-20\"],[9],[0,\"\\n\"],[4,\"banner-message\",null,[[\"color\"],[\"bg-warning m-0\"]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"modalConfirmDeactivate.clusterDriver\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"isNodeDriver\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"mb-20 mt-20\"],[9],[0,\"\\n\"],[4,\"banner-message\",null,[[\"color\"],[\"bg-warning m-0\"]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"modalConfirmDeactivate.nodeDriver\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"container-header-text\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"modalConfirmDeactivate.header\"],null],false],[0,\" \"],[1,[23,[\"isService\",\"message\"]],false],[0,\":\\n  \"],[7,\"div\"],[11,\"class\",\"display-name\"],[9],[0,\"\\n    \"],[1,[23,[\"originalModel\",\"displayName\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"],[7,\"div\"],[11,\"class\",\"footer-actions\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn bg-error\"],[9],[1,[23,[\"isService\",\"button\"]],false],[3,\"action\",[[22,0,[]],\"confirm\"]],[10],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn bg-transparent\"],[9],[1,[27,\"t\",[\"modalConfirmDeactivate.cancel\"],null],false],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/modal-confirm-deactivate/template.hbs"
    }
  });

  _exports.default = _default;
});