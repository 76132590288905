define("global-admin/components/form-selinux-policy/component", ["exports", "global-admin/components/form-selinux-policy/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,
    classNames: ['accordion-wrapper'],
    model: null,
    readOnly: false,
    statusClass: null,
    status: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('model.seLinux', this.get('model.seLinux') || this.get('globalStore').createRecord({
        type: 'seLinuxStrategyOptions',
        rule: 'RunAsAny'
      }));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('expandFn')) {
        this.set('expandFn', function (item) {
          item.toggleProperty('expanded');
        });
      }
    },
    ruleDidChange: function () {
      var rule = this.get('model.seLinux.rule');

      if (rule === 'RunAsAny') {
        this.set('model.seLinux.seLinuxOptions', null);
      } else {
        if (!this.get('model.seLinux.seLinuxOptions')) {
          this.set('model.seLinux.seLinuxOptions', this.get('globalStore').createRecord({
            type: 'seLinuxOptions',
            level: '',
            role: '',
            user: ''
          }));
        }
      }
    }.observes('model.seLinux.rule')
  });

  _exports.default = _default;
});