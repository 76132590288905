define("@rancher/ember-api-store/initializers/store", ["exports", "@rancher/ember-api-store/services/store", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/models/collection", "@rancher/ember-api-store/models/error", "@rancher/ember-api-store/models/schema"], function (_exports, _store, _resource, _collection, _error, _schema) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    var serviceName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'store';
    var injectAs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    if (!injectAs) {
      injectAs = serviceName;
    }

    return function (app) {
      app.register('service:' + serviceName, _store.default);

      if (!app.hasRegistration('model:resource')) {
        app.register('model:resource', _resource.default);
        app.register('model:collection', _collection.default);
        app.register('model:schema', _schema.default);
        app.register('model:error', _error.default);
      }

      app.inject('controller', injectAs, 'service:' + serviceName);
      app.inject('route', injectAs, 'service:' + serviceName);
      app.inject('component', injectAs, 'service:' + serviceName);
    };
  }
});