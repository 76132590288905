define('ivy-codemirror/components/ivy-codemirror', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    tagName: 'textarea',

    codeMirror: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._codeMirror = this.get('codeMirror').fromTextArea(this.get('elementId'), this.get('element'));

      // Send a "valueUpdated" action when CodeMirror triggers a "change" event.
      this.setupCodeMirrorEventHandler('change', this, this.scheduleValueUpdatedAction);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.updateCodeMirrorOptions();
      this.updateCodeMirrorValue();
    },


    isVisibleDidChange: Ember.observer('isVisible', function () {
      if (this._wasVisible === this.get('isVisible')) {
        return;
      }

      Ember.run.scheduleOnce('render', this, this.toggleVisibility);
    }),

    scheduleValueUpdatedAction: function scheduleValueUpdatedAction(codeMirror, changeObj) {
      Ember.run.once(this, this.sendValueUpdatedAction, codeMirror.getValue(), codeMirror, changeObj);
    },
    setupCodeMirrorEventHandler: function setupCodeMirrorEventHandler(event, target, method) {
      var callback = Ember.run.bind(target, method);

      this._codeMirror.on(event, callback);

      this.one('willDestroyElement', this, function () {
        this._codeMirror.off(event, callback);
      });
    },
    sendValueUpdatedAction: function sendValueUpdatedAction() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this.sendAction.apply(this, ['valueUpdated'].concat(_toConsumableArray(args)));
    },
    toggleVisibility: function toggleVisibility() {
      var isVisible = this.get('isVisible');

      if (this._wasVisible === isVisible) {
        return;
      }

      this._wasVisible = isVisible;

      if (isVisible) {
        // Force a refresh when becoming visible, since CodeMirror won't render
        // itself onto a hidden element.
        this._codeMirror.refresh();
      }
    },
    updateCodeMirrorOption: function updateCodeMirrorOption(option, value) {
      if (this._codeMirror.getOption(option) !== value) {
        this._codeMirror.setOption(option, value);
      }
    },
    updateCodeMirrorOptions: function updateCodeMirrorOptions() {
      var options = this.get('options');

      if (options) {
        Object.keys(options).forEach(function (option) {
          this.updateCodeMirrorOption(option, options[option]);
        }, this);
      }
    },
    updateCodeMirrorValue: function updateCodeMirrorValue() {
      var value = this.get('value');

      if (value !== this._codeMirror.getValue()) {
        this._codeMirror.setValue(value || '');
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      // Remove the editor and restore the original textarea.
      this._codeMirror.toTextArea();

      this.get('codeMirror').unregisterInstance(this.get('elementId'));

      delete this._codeMirror;
    }
  });
});