define("global-admin/security/authentication/azuread/controller", ["exports", "ui/utils/constants", "global-admin/mixins/authentication"], function (_exports, _constants, _authentication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_authentication.default, {
    access: Ember.inject.service(),
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    azureAd: Ember.inject.service(),
    confirmDisable: false,
    testing: false,
    editing: false,
    errors: null,
    error: null,
    region: null,
    _boundSucceed: null,
    authConfig: Ember.computed.alias('model.azureADConfig'),
    actions: {
      test: function test() {
        var _this = this;

        this.send('clearError');
        var model = Ember.get(this, 'authConfig');
        var am = Ember.get(this, 'authConfig.accessMode') || 'unrestricted';
        Ember.setProperties(model, {
          accessMode: am,
          rancherUrl: "".concat(window.location.origin, "/verify-auth-azure")
        });
        var errors = model.validationErrors();

        if (errors.get('length')) {
          Ember.set(model, 'enabled', false);
          Ember.setProperties(this, {
            errors: errors,
            testing: false
          });
        } else {
          Ember.set(this, 'testing', true);
          Ember.get(this, 'azureAd').testConfig(model).then(function (resp) {
            var redirectUrl = Ember.get(resp, 'redirectUrl');

            if (redirectUrl) {
              Ember.set(_this, '_boundSucceed', _this.authenticationApplied.bind(_this));
              Ember.get(_this, 'azureAd').test(model, redirectUrl, Ember.get(_this, '_boundSucceed'));
            }
          }).catch(function (err) {
            Ember.set(model, 'enabled', false);

            _this.send('gotError', err);
          });
        }
      }
    },
    numUsers: Ember.computed('authConfig.allowedPrincipalIds.[]', 'userType', 'groupType', function () {
      return (Ember.get(this, 'authConfig.allowedPrincipalIds') || []).filter(function (principal) {
        return principal.includes(_constants.default.PROJECT.TYPE_AZURE_USER);
      }).get('length');
    }),
    numGroups: Ember.computed('authConfig.allowedPrincipalIds.[]', 'userType', 'groupType', function () {
      return (Ember.get(this, 'authConfig.allowedPrincipalIds') || []).filter(function (principal) {
        return principal.includes(_constants.default.PROJECT.TYPE_AZURE_GROUP);
      }).get('length');
    }),
    replyUrl: Ember.computed(function () {
      return "".concat(window.location.origin, "/verify-auth-azure");
    }),
    authenticationApplied: function authenticationApplied(err) {
      Ember.set(this, 'saving', false);

      if (err) {
        Ember.set(this, 'isEnabled', false);
        this.send('gotError', err);
        return;
      }

      this.send('clearError');
    }
  });

  _exports.default = _default;
});