define("shared/components/sortable-thead/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/+oJk5mE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"a\"],[11,\"class\",\"btn bg-transparent\"],[9],[0,\"\\n\"],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"header\",\"icon\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\"],[12,\"class\",[28,[[23,[\"header\",\"icon\"]]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"header\",\"translationKey\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"t\",[[23,[\"header\",\"translationKey\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"header\",\"label\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,[\"header\",\"label\"]],false],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[4,\"if\",[[23,[\"header\",\"sort\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"icon-stack\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"icon icon-sort icon-stack-1x faded\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"activeAscending\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"icon icon-sort-down icon-stack-1x\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"activeDescending\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"icon icon-sort-up icon-stack-1x\"],[9],[10],[0,\"\\n      \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/sortable-thead/template.hbs"
    }
  });

  _exports.default = _default;
});