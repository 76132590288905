define("shared/components/namespace-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2CI9qGBV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"tr\"],[11,\"class\",\"group-row\"],[9],[0,\"\\n  \"],[7,\"td\"],[12,\"colspan\",[27,\"sub\",[[23,[\"fullColspan\"]],1],null]],[11,\"class\",\"pl-10\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"displayName\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"namespaceGroup.label\"],[[\"name\"],[[23,[\"model\",\"displayName\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"t\",[[23,[\"noGroup\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"td\"],[12,\"data-title\",[28,[[23,[\"dt\",\"actions\"]]]]],[11,\"class\",\"actions\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"action-menu\",null,[[\"model\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"showTransitioningMessage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"tr\"],[11,\"class\",\"group-row group-row-error\"],[9],[0,\"\\n    \"],[7,\"td\"],[12,\"colspan\",[28,[[21,\"fullColspan\"]]]],[12,\"class\",[28,[\"text-small \",[23,[\"model\",\"stateColor\"]]]]],[9],[0,\"\\n      \"],[1,[27,\"uc-first\",[[23,[\"model\",\"transitioningMessage\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/namespace-group/template.hbs"
    }
  });

  _exports.default = _default;
});