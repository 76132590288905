define("shared/utils/search-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.matches = matches;
  _exports.filter = filter;
  var SEARCH_FIELDS = ['displayName', 'id:prefix', 'displayState'];

  function matches(fields, token, item) {
    var tokenMayBeIp = /^[0-9a-f\.:]+$/i.test(token);

    for (var i = 0; i < fields.length; i++) {
      var field = fields[i];

      if (field) {
        // Modifiers:
        //  id: The token must match id format (i.e. 1i123)
        var idx = field.indexOf(':');
        var modifier = null;

        if (idx > 0) {
          modifier = field.substr(idx + 1);
          field = field.substr(0, idx);
        }

        var val = Ember.get(item, field);

        if (val === undefined) {
          continue;
        }

        val = "".concat(val).toLowerCase();

        if (!val) {
          continue;
        }

        switch (modifier) {
          case 'exact':
            if (val === token) {
              return true;
            }

            break;

          case 'ip':
            if (tokenMayBeIp) {
              var re = new RegExp("(?:^|.)".concat(token, "(?:.|$)"));

              if (re.test(val)) {
                return true;
              }
            }

            break;

          case 'prefix':
            if (val.indexOf(token) === 0) {
              return true;
            }

            break;

          default:
            if (val.indexOf(token) >= 0) {
              return true;
            }

            break;
        }
      }
    }

    return false;
  }

  function filter(out, searchText) {
    var searchFields = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : SEARCH_FIELDS;
    var subFields = arguments.length > 3 ? arguments[3] : undefined;
    var subSearchField = arguments.length > 4 ? arguments[4] : undefined;
    var subMatches = null;
    searchText = (searchText || '').trim().toLowerCase();

    if (searchText.length) {
      subMatches = {};
      var searchTokens = searchText.split(/\s*[, ]\s*/);

      for (var i = out.length - 1; i >= 0; i--) {
        var row = out[i];
        var hits = 0;
        var mainFound = true;

        for (var j = 0; j < searchTokens.length; j++) {
          var expect = true;
          var token = searchTokens[j];

          if (token.substr(0, 1) === '!') {
            expect = false;
            token = token.substr(1);
          }

          if (token && matches(searchFields, token, row) !== expect) {
            mainFound = false;
            break;
          }
        }

        if (subFields && subSearchField) {
          var subRows = row.get(subSearchField) || [];

          for (var k = subRows.length - 1; k >= 0; k--) {
            var subFound = true;

            for (var l = 0; l < searchTokens.length; l++) {
              var _expect = true;
              var _token = searchTokens[l];

              if (_token.substr(0, 1) === '!') {
                _expect = false;
                _token = _token.substr(1);
              }

              if (matches(subFields, _token, subRows[k]) !== _expect) {
                subFound = false;
                break;
              }
            }

            if (subFound) {
              hits++;
            }
          }

          subMatches[row.get('id')] = hits;
        }

        if (!mainFound && hits === 0) {
          out.removeAt(i);
        }
      }
    }

    return {
      matches: out,
      subMatches: subMatches
    };
  }
});