define("shared/cluster-templates/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    globalStore: Ember.inject.service(),
    questionsSchemas: null,
    fetchQuestionsSchema: function fetchQuestionsSchema() {
      var _this = this;

      if (!this.questionsSchemas) {
        return this.globalStore.rawRequest({
          url: 'clusterTemplateRevisions?action=listquestions',
          method: 'POST'
        }).then(function (resp) {
          if (resp.body) {
            var parsed = JSON.parse(resp.body);
            Ember.set(_this, 'questionsSchemas', Ember.get(parsed, 'questions'));
          }
        });
      }
    },
    cloneAndPopulateClusterConfig: function cloneAndPopulateClusterConfig(cluster, clusterTemplateRevision) {
      var clusterConfig = clusterTemplateRevision.clusterConfig.cloneForNew();
      delete clusterConfig.type;
      Ember.setProperties(cluster, clusterConfig);
    }
  });

  _exports.default = _default;
});