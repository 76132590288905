define("shared/components/cluster-ribbon-nav/component", ["exports", "shared/components/cluster-ribbon-nav/template", "ui/utils/constants"], function (_exports, _template, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    prefs: Ember.inject.service(),
    layout: _template.default,
    tagName: 'section',
    classNames: ['recent-clusters'],
    activeClusterProvider: null,
    allActiveClusterProviders: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.initRecentClusters();
    },
    actions: {
      removeRecent: function removeRecent(clusterName) {
        var recentClusters = Ember.get(this, "prefs.".concat(_constants.default.PREFS.RECENT_CLUSTERS)) || [];
        recentClusters.removeObject(clusterName);
        Ember.set(this, "prefs.".concat(_constants.default.PREFS.RECENT_CLUSTERS), recentClusters);
      }
    },
    recentClusterProviders: Ember.computed("prefs.".concat(_constants.default.PREFS.RECENT_CLUSTERS), function () {
      var recentProviders = Ember.get(this, "prefs.".concat(_constants.default.PREFS.RECENT_CLUSTERS)) || [];
      var allActiveClusterProviders = this.allActiveClusterProviders;
      var out = recentProviders.map(function (provider) {
        return allActiveClusterProviders.findBy('name', provider);
      });
      return out;
    }),
    clusterDriverError: function clusterDriverError() {
      throw new Error('clusterDriverError action is required!');
    },
    initRecentClusters: function initRecentClusters() {
      var allActiveClusterProviders = this.allActiveClusterProviders;
      var recentClusters = Ember.get(this, "prefs.".concat(_constants.default.PREFS.RECENT_CLUSTERS)) ? Ember.get(this, "prefs.".concat(_constants.default.PREFS.RECENT_CLUSTERS)).slice() : [];
      var activeClusterProvider = Ember.get(this, 'activeClusterProvider');
      var providerName = activeClusterProvider ? Ember.get(activeClusterProvider, 'name') : null;
      var activeRecentClusters = recentClusters.filter(function (rc) {
        if (allActiveClusterProviders.findBy('name', rc)) {
          return true;
        }

        return false;
      });

      if (providerName) {
        if (activeRecentClusters.includes(providerName)) {
          activeRecentClusters = activeRecentClusters.removeObject(providerName);
          activeRecentClusters.unshiftObject(providerName);
        } else {
          if (activeRecentClusters.length === 5) {
            activeRecentClusters.pop();
            activeRecentClusters.unshiftObject(providerName);
          } else {
            activeRecentClusters.unshiftObject(providerName);
          }
        }
      } else {
        this.clusterDriverError();
      }

      if (activeRecentClusters.uniq().length > 5) {
        activeRecentClusters = activeRecentClusters.slice(0, 5);
      }

      Ember.set(this, "prefs.".concat(_constants.default.PREFS.RECENT_CLUSTERS), activeRecentClusters.uniq());
    }
  });

  _exports.default = _default;
});