enifed("ember-template-compiler/tests/plugins/assert-splattribute-expression-test", ["internal-test-helpers", "ember-template-compiler/index"], function (_internalTestHelpers, _index) {
  "use strict";

  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: assert-splattribute-expression', class extends _internalTestHelpers.AbstractTestCase {
    expectedMessage(locInfo) {
      return true
      /* EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION */
      ? `Using "...attributes" can only be used in the element position e.g. <div ...attributes />. It cannot be used as a path. (${locInfo}) ` : `...attributes is an invalid path (${locInfo}) `;
    }

    '@test ...attributes is in element space'(assert) {
      if (true
      /* EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION */
      ) {
          assert.expect(0);
          (0, _index.compile)('<div ...attributes>Foo</div>');
        } else {
        expectAssertion(() => {
          (0, _index.compile)('<div ...attributes>Foo</div>');
        }, this.expectedMessage('L1:C5'));
      }
    }

    '@test {{...attributes}} is not valid'() {
      expectAssertion(() => {
        (0, _index.compile)('<div>{{...attributes}}</div>', {
          moduleName: 'foo-bar'
        });
      }, this.expectedMessage(`'foo-bar' @ L1:C7`));
    }

    '@test {{...attributes}} is not valid path expression'() {
      expectAssertion(() => {
        (0, _index.compile)('<div>{{...attributes}}</div>', {
          moduleName: 'foo-bar'
        });
      }, this.expectedMessage(`'foo-bar' @ L1:C7`));
    }

    '@test {{...attributes}} is not valid modifier'() {
      expectAssertion(() => {
        (0, _index.compile)('<div {{...attributes}}>Wat</div>', {
          moduleName: 'foo-bar'
        });
      }, this.expectedMessage(`'foo-bar' @ L1:C7`));
    }

    '@test {{...attributes}} is not valid attribute'() {
      expectAssertion(() => {
        (0, _index.compile)('<div class={{...attributes}}>Wat</div>', {
          moduleName: 'foo-bar'
        });
      }, this.expectedMessage(`'foo-bar' @ L1:C13`));
    }

  });
});