define("shared/mixins/child-hook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_KEY = '_childHooks';
  var NEXT_ID = 1;

  var _default = Ember.Mixin.create({
    actions: {
      // sendAction('registerHook', fn);
      // sendAction('registerHook', fn, 'name');
      // sendAction('registerHook', fn, {name: 'name', priority: 99, key: 'alternateKey'})
      registerHook: function registerHook(boundFn, opt) {
        if (typeof opt === 'string') {
          opt = {
            name: opt
          };
        } else if (!opt) {
          opt = {};
        }

        if (!opt.name) {
          opt.name = "hook_".concat(NEXT_ID);
          NEXT_ID++;
        }

        var key = opt.key || DEFAULT_KEY;
        var hooks = Ember.get(this, key);

        if (!hooks) {
          hooks = [];
          Ember.set(this, key, hooks);
        }

        var entry = hooks.findBy('name', opt.name);

        if (!entry) {
          entry = opt;
          hooks.push(entry);
        }

        entry.priority = opt.priority || 99;
        entry.fn = boundFn;
      }
    },
    applyHooks: function applyHooks() {
      var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_KEY;
      var hooks = (Ember.get(this, key) || []).sortBy('priority', 'name');
      return Ember.RSVP.all(hooks.map(function (x) {
        return x.fn();
      }));
    }
  });

  _exports.default = _default;
});