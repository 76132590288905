define("shared/components/input-custom-answers/component", ["exports", "ui/utils/platform", "shared/components/input-custom-answers/template", "js-yaml", "shared/mixins/input-answers", "shared/utils/convert-yaml"], function (_exports, _platform, _template, _jsYaml, _inputAnswers, _convertYaml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_inputAnswers.default, {
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    layout: _template.default,
    pasteOrUpload: false,
    accept: '.yml, .yaml',
    app: null,
    isMultiClusterApp: false,
    intialAnswerMap: null,
    valuesYaml: Ember.computed.alias('selectedTemplate.valuesYaml'),
    init: function init() {
      this._super.apply(this, arguments);

      this.initPasteOrUpload();
    },
    // shared actions exist in mixin
    actions: {
      updateAnswers: function updateAnswers(answers) {
        var isMultiClusterApp = this.isMultiClusterApp;

        if (isMultiClusterApp) {
          Ember.set(this, 'app.answers.firstObject.values', answers);
        } else {
          Ember.set(this, 'app.answers', answers);
        }
      }
    },
    applicationAnswers: Ember.computed('app.answers', 'app.answers.firstObject.values', function () {
      var answers = Ember.isArray(Ember.get(this, 'app.answers')) ? Ember.get(this, 'app.answers.firstObject.values') : Ember.get(this, 'app.answers');
      return answers;
    }),
    actualAccept: Ember.computed('accept', function () {
      if (_platform.isSafari) {
        return '';
      } else {
        return Ember.get(this, 'accept');
      }
    }),
    pastedAnswers: Ember.computed('pasteOrUpload', {
      get: function get()
      /* key */
      {
        var valuesYaml;
        var isMultiClusterApp = this.isMultiClusterApp;

        if (isMultiClusterApp) {
          valuesYaml = Ember.get(this, 'app.answers.firstObject.valuesYaml') || '';
        } else {
          valuesYaml = Ember.get(this, 'app.valuesYaml') || '';
        }

        var yaml;

        if (valuesYaml) {
          yaml = valuesYaml;
        } else {
          var input = {};
          var questions = Ember.get(this, 'applicationAnswers');
          Object.keys(questions).forEach(function (q) {
            if (questions[q] !== undefined && questions[q] !== null) {
              input[q] = questions[q];
            } else {
              input[q.variable] = '';
            }
          });
          yaml = (0, _convertYaml.default)(input);
        }

        Ember.set(this, 'valuesYaml', yaml);
        return yaml;
      },
      set: function set(key, value) {
        try {
          _jsYaml.default.safeLoad(value);
        } catch (err) {
          Ember.set(this, 'yamlErrors', ["YAML Parse Error: ".concat(err.snippet, " - ").concat(err.message)]);
          return value;
        }

        Ember.setProperties(this, {
          yamlErrors: [],
          valuesYaml: value
        });
        return value;
      }
    }),
    change: function change(event) {
      var _this = this;

      if (Ember.get(this, 'pasteOrUpload')) {
        return;
      }

      var input = event.target;

      if (input.files && input.files[0]) {
        var file = input.files[0];
        var reader = new FileReader();

        reader.onerror = function (err) {
          Ember.get(_this, 'growl').fromError(Ember.get(err, 'srcElement.error.message'));
        };

        reader.onload = function (event2) {
          var out = event2.target.result;
          Ember.set(_this, 'pastedAnswers', out);
          input.value = '';
        };

        reader.readAsText(file);
      }
    },
    initPasteOrUpload: function initPasteOrUpload() {
      var isMultiClusterApp = this.isMultiClusterApp;

      if (isMultiClusterApp) {
        if (Ember.get(this, 'app.answers.firstObject.valuesYaml')) {
          Ember.set(this, 'pasteOrUpload', true);
        }
      } else {
        if (Ember.get(this, 'app.valuesYaml')) {
          Ember.set(this, 'pasteOrUpload', true);
        }
      }
    }
  });

  _exports.default = _default;
});