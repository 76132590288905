enifed("ember-template-compiler/lib/plugins/deprecate-send-action", ["exports", "@ember/debug", "@ember/deprecated-features", "ember-template-compiler/lib/system/calculate-location-display"], function (_exports, _debug, _deprecatedFeatures, _calculateLocationDisplay) {
  "use strict";

  _exports.default = deprecateSendAction;
  const EVENTS = ['insert-newline', 'enter', 'escape-press', 'focus-in', 'focus-out', 'key-press', 'key-up', 'key-down'];

  function deprecateSendAction(env) {
    if (_deprecatedFeatures.SEND_ACTION) {
      let {
        moduleName
      } = env.meta;

      let deprecationMessage = (node, evName, action) => {
        let sourceInformation = (0, _calculateLocationDisplay.default)(moduleName, node.loc);
        return `Please refactor \`{{input ${evName}="${action}"}}\` to \`{{input ${evName}=(action "${action}")}}\. ${sourceInformation}`;
      };

      return {
        name: 'deprecate-send-action',
        visitor: {
          MustacheStatement(node) {
            if (node.path.original !== 'input') {
              return;
            }

            node.hash.pairs.forEach(pair => {
              if (EVENTS.indexOf(pair.key) > -1 && pair.value.type === 'StringLiteral') {
                true && !false && (0, _debug.deprecate)(deprecationMessage(node, pair.key, pair.value.original), false, {
                  id: 'ember-component.send-action',
                  until: '4.0.0',
                  url: 'https://emberjs.com/deprecations/v3.x#toc_ember-component-send-action'
                });
              }
            });
          }

        }
      };
    }

    return;
  }
});