define("shared/components/schema/input-config-map/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ahk4MdiG",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"new-select\",null,[[\"classNames\",\"content\",\"prompt\",\"localizedPrompt\",\"value\"],[\"form-control\",[23,[\"filtered\"]],\"schema.inputConfigMap.prompt\",true,[23,[\"selected\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/schema/input-config-map/template.hbs"
    }
  });

  _exports.default = _default;
});