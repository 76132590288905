define("global-admin/security/authentication/ping/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    resourceType: 'pingconfig',
    model: function model() {
      var gs = Ember.get(this, 'globalStore');
      return Ember.RSVP.hash({
        authConfig: gs.find('authconfig', 'ping', {
          forceReload: true
        }),
        principals: gs.all('principal'),
        serverUrl: gs.find('setting', _constants.default.SETTING.SERVER_URL)
      });
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting && transition.targetName !== 'error') {
        controller.set('editing', false);
      }
    }
  });

  _exports.default = _default;
});