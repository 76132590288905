define("global-admin/components/azuread-endpoints/component", ["exports", "global-admin/components/azuread-endpoints/template", "ui/utils/constants"], function (_exports, _template, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    authConfig: null,
    isEnabled: null,
    region: null,
    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'isEnabled')) {
        var endpoint = Ember.get(this, 'authConfig.endpoint');

        if (_constants.default.AZURE_AD.STANDARD.ENDPOINT.startsWith(endpoint)) {
          Ember.set(this, 'region', _constants.default.AZURE_AD.STANDARD.KEY);
        } else if (_constants.default.AZURE_AD.CHINA.ENDPOINT.startsWith(endpoint)) {
          Ember.set(this, 'region', _constants.default.AZURE_AD.CHINA.KEY);
        } else {
          Ember.set(this, 'region', _constants.default.AZURE_AD.CUSTOM.KEY);
        }
      } else {
        Ember.set(this, 'region', _constants.default.AZURE_AD.STANDARD.KEY);
        this.regionDidChange();
      }
    },
    regionDidChange: Ember.observer('region', 'authConfig.tenantId', function () {
      var config = Ember.get(this, 'authConfig');
      var tenantId = Ember.get(this, 'authConfig.tenantId') || '';
      var region = Ember.get(this, 'region');

      switch (region) {
        case _constants.default.AZURE_AD.STANDARD.KEY:
          Ember.setProperties(config, {
            endpoint: _constants.default.AZURE_AD.STANDARD.ENDPOINT,
            graphEndpoint: _constants.default.AZURE_AD.STANDARD.GRAPH_ENDPOINT,
            tokenEndpoint: "".concat(_constants.default.AZURE_AD.STANDARD.ENDPOINT).concat(tenantId, "/oauth2/token"),
            authEndpoint: "".concat(_constants.default.AZURE_AD.STANDARD.ENDPOINT).concat(tenantId, "/oauth2/authorize")
          });
          break;

        case _constants.default.AZURE_AD.CHINA.KEY:
          Ember.setProperties(config, {
            endpoint: _constants.default.AZURE_AD.CHINA.ENDPOINT,
            graphEndpoint: _constants.default.AZURE_AD.CHINA.GRAPH_ENDPOINT,
            tokenEndpoint: "".concat(_constants.default.AZURE_AD.CHINA.ENDPOINT).concat(tenantId, "/oauth2/token"),
            authEndpoint: "".concat(_constants.default.AZURE_AD.CHINA.ENDPOINT).concat(tenantId, "/oauth2/authorize")
          });
          break;

        case _constants.default.AZURE_AD.CUSTOM.KEY:
          Ember.setProperties(config, {
            endpoint: _constants.default.AZURE_AD.STANDARD.ENDPOINT,
            graphEndpoint: '',
            tokenEndpoint: '',
            authEndpoint: ''
          });
          break;
      }
    })
  });

  _exports.default = _default;
});