define("shared/components/input-answers/component", ["exports", "ui/utils/platform", "shared/utils/evaluate", "shared/components/input-answers/template", "js-yaml", "shared/utils/convert-yaml", "shared/mixins/input-answers"], function (_exports, _platform, _evaluate, _template, _jsYaml, _convertYaml, _inputAnswers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HIDDEN = 'Hidden';

  var _default = Ember.Component.extend(_inputAnswers.default, {
    growl: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    pasteOrUpload: false,
    isMultiClusterApp: false,
    accept: '.yml, .yaml',
    showHeader: true,
    answerSections: null,
    questions: Ember.computed.alias('selectedTemplate.allQuestions'),
    valuesYaml: Ember.computed.alias('selectedTemplate.valuesYaml'),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments); // don't show yaml editor before the component is finished rendering or we get an empty yaml editor until user clicks in yaml form.
      // reason being code-mirror doesn't have an element to initialize on


      Ember.run.next(function () {
        var isMultiClusterApp = _this.isMultiClusterApp;
        var pasteOrUpload = isMultiClusterApp ? !!Ember.get(_this, 'app.answers.firstObject.valuesYaml') : !!Ember.get(_this, 'app.valuesYaml');
        Ember.set(_this, 'pasteOrUpload', pasteOrUpload);
      });
    },
    // shared actions exist in mixin
    // actions: {},
    pastedAnswers: Ember.computed('pasteOrUpload', {
      get: function get()
      /* key */
      {
        var valuesYaml;
        var isMultiClusterApp = this.isMultiClusterApp;

        if (isMultiClusterApp) {
          valuesYaml = Ember.get(this, 'app.answers.firstObject.valuesYaml') || '';
        } else {
          valuesYaml = Ember.get(this, 'app.valuesYaml') || '';
        }

        var yaml;

        if (valuesYaml) {
          yaml = valuesYaml;
        } else {
          var questions = Ember.get(this, 'questions');
          var input = {};
          questions.forEach(function (q) {
            if (q.answer !== undefined && q.answer !== null) {
              input[q.variable] = q.answer;
            } else if (q.default !== undefined && q.default !== null) {
              input[q.variable] = q.default;
            } else {
              input[q.variable] = '';
            }
          });
          yaml = (0, _convertYaml.default)(input);
        }

        Ember.set(this, 'valuesYaml', yaml);
        return yaml;
      },
      set: function set(key, value) {
        try {
          _jsYaml.default.safeLoad(value);
        } catch (err) {
          Ember.set(this, 'yamlErrors', ["YAML Parse Error: ".concat(err.snippet, " - ").concat(err.message)]);
          return value;
        }

        Ember.setProperties(this, {
          yamlErrors: [],
          valuesYaml: value
        });
        return value;
      }
    }),
    actualAccept: Ember.computed('accept', function () {
      if (_platform.isSafari) {
        return '';
      } else {
        return Ember.get(this, 'accept');
      }
    }),
    answerDidChange: Ember.on('init', Ember.observer('questions.@each.answer', function () {
      var questions = Ember.get(this, 'questions') || [];
      var oldQuestions = [];
      var newQuestions = [];
      (Ember.get(this, 'answerSections') || []).forEach(function (section) {
        section.data.forEach(function (group) {
          group.forEach(function (q) {
            oldQuestions.push(q.variable);
          });
        });
      });
      oldQuestions = oldQuestions.sort();
      var filteredQuestions = questions.filter(function (q) {
        return (0, _evaluate.evaluate)(q, questions);
      });
      newQuestions = filteredQuestions.map(function (q) {
        return q.variable;
      }).sort();
      var sections = {};
      var notInAnySection = [];
      filteredQuestions.forEach(function (item) {
        if (item.group) {
          if (item.group === HIDDEN) {
            return;
          }

          if (!sections[item.group]) {
            sections[item.group] = [];
          }

          sections[item.group].push(item);
        } else {
          notInAnySection.push(item);
        }
      });
      var allSections = [];
      Object.keys(sections).forEach(function (key) {
        allSections.push({
          title: key,
          data: sections[key]
        });
      });

      if (notInAnySection.length) {
        allSections.push({
          data: notInAnySection
        });
      }

      var out = [];
      allSections.forEach(function (section) {
        var data = [];
        var dataIndex = 0;
        out.push({
          title: section.title,
          data: data
        });
        section.data.forEach(function (item, index) {
          if (index % 2 === 0) {
            data.push([item]);
            dataIndex++;
          } else {
            data[dataIndex - 1].push(item);
          }
        });
      });

      if (newQuestions.toString() !== oldQuestions.toString()) {
        Ember.set(this, 'answerSections', out);
      }
    })),
    change: function change(event) {
      var _this2 = this;

      if (Ember.get(this, 'pasteOrUpload')) {
        return;
      }

      var input = event.target;

      if (input.files && input.files[0]) {
        var file = input.files[0];
        var reader = new FileReader();

        reader.onload = function (event2) {
          var out = event2.target.result;
          Ember.set(_this2, 'pastedAnswers', out);
          input.value = '';
        };

        reader.onerror = function (err) {
          Ember.get(_this2, 'growl').fromError(Ember.get(err, 'srcElement.error.message'));
        };

        reader.readAsText(file);
      }
    }
  });

  _exports.default = _default;
});