define("shared/components/accordion-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6O81v4ja",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"showExpandAll\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-sm bg-transparent pull-right\"],[9],[1,[27,\"t\",[[27,\"if\",[[23,[\"expandAll\"]],\"generic.collapseAll\",\"generic.expandAll\"],null]],null],false],[3,\"action\",[[22,0,[]],\"expandAll\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[14,1,[[22,0,[]],[23,[\"expand\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/accordion-list/template.hbs"
    }
  });

  _exports.default = _default;
});