define("shared/components/api-field/component", ["exports", "shared/components/api-field/template", "@rancher/ember-api-store/models/resource"], function (_exports, _template, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    resource: null,
    // The object that is being edited
    resourceType: null,
    // The schema type the object is
    field: null,
    // The field on resource that this input is for
    schema: null,
    // All the schemas for all the types
    typeDocs: null,
    // Type docs for all the types
    value: null,
    tagName: 'div',
    classNames: ['vertical-middle', 'span-6', 'api-field', 'box', 'mb-20'],
    valueChanged: Ember.observer('value', function () {
      var _this = this;

      Ember.run.schedule('afterRender', function () {
        Ember.get(_this, 'resource').set(Ember.get(_this, 'field'), Ember.get(_this, 'value'));
      });
    }),
    fieldDef: Ember.computed('field', 'resourceType', 'schema', function () {
      var fieldName = Ember.get(this, 'field');
      var schema = Ember.get(this, 'schema');

      if (!schema) {
        return;
      }

      var orig = schema.resourceFields[fieldName];

      if (!orig) {
        return;
      }

      var out;

      if (_resource.default.detectInstance(orig)) {
        // the "type" field makes the store turn these into resources...
        out = orig.serialize();
      } else {
        out = JSON.parse(JSON.stringify(orig));
      }

      out.name = fieldName;
      return out;
    }),
    fieldType: Ember.computed('fieldDef', function () {
      return Ember.get(this, 'fieldDef.type');
    }),
    specialField: Ember.computed('typeDocs', function () {
      var typeDocs = this.typeDocs,
          fieldName = this.field;

      if (typeDocs) {
        return !!typeDocs[fieldName];
      } else {
        return false;
      }
    })
  });

  _exports.default = _default;
});