define("shared/components/table-labels/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d6LH1+bl",
    "block": "{\"symbols\":[\"sortable\",\"kind\",\"label\"],\"statements\":[[4,\"if\",[[23,[\"header\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"clearfix\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"acc-label\"],[9],[1,[21,\"header\"],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"sortable-table\",null,[[\"classNames\",\"bulkActions\",\"rowActions\",\"paging\",\"search\",\"sortBy\",\"stickyHeader\",\"descending\",\"headers\",\"body\"],[\"grid fixed mb-0 sortable-table\",false,false,false,[23,[\"search\"]],[23,[\"sortBy\"]],false,[23,[\"descending\"]],[23,[\"headers\"]],[23,[\"labelArray\"]]]],{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,2,[]],\"row\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"td\"],[12,\"data-title\",[28,[[27,\"t\",[[27,\"concat\",[[23,[\"labelIntlSec\"]],\".key\"],null]],null],\":\"]]],[11,\"class\",\"force-wrap\"],[9],[1,[22,3,[\"key\"]],false],[10],[0,\"\\n      \"],[7,\"td\"],[12,\"data-title\",[28,[[27,\"t\",[[27,\"concat\",[[23,[\"labelIntlSec\"]],\".value\"],null]],null],\":\"]]],[11,\"class\",\"force-wrap\"],[9],[1,[27,\"pretty-json\",null,[[\"value\"],[[22,3,[\"value\"]]]]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,2,[]],\"norows\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"td\"],[12,\"colspan\",[28,[[22,1,[\"fullColspan\"]]]]],[11,\"class\",\"text-center text-muted pt-20 pb-20\"],[9],[1,[27,\"t\",[[27,\"concat\",[[23,[\"labelIntlSec\"]],\".noData\"],null]],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[1,2,3]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/table-labels/template.hbs"
    }
  });

  _exports.default = _default;
});