define("shared/components/form-global-resource-roles/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kyAGUIlY",
    "block": "{\"symbols\":[\"role\"],\"statements\":[[7,\"div\"],[11,\"class\",\"col span-6\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"acc-label\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"newMultiClusterApp.roles.label\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"radio\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"acc-label\"],[11,\"id\",\"mc-app-role-project-member\"],[9],[0,\"\\n      \"],[1,[27,\"radio-button\",null,[[\"selection\",\"value\",\"id\"],[[23,[\"role\"]],\"project-member\",\"mc-app-role-project-member\"]]],false],[0,\"\\n      \"],[1,[27,\"t\",[\"newMultiClusterApp.roles.radios.project\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"radio\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"acc-label\"],[11,\"id\",\"mc-app-role-cluster-owner\"],[9],[0,\"\\n      \"],[1,[27,\"radio-button\",null,[[\"selection\",\"value\",\"id\"],[[23,[\"role\"]],\"cluster-owner\",\"mc-app-role-cluster-owner\"]]],false],[0,\"\\n      \"],[1,[27,\"t\",[\"newMultiClusterApp.roles.radios.cluster\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"otherRoles\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"col span-6\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"acc-label\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"newMultiClusterApp.roles.additionalRoles\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"table\"],[11,\"class\",\"table fixed no-lines mt-5 mb-10\"],[9],[0,\"\\n      \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"otherRoles\"]]],null,{\"statements\":[[0,\"          \"],[7,\"tr\"],[9],[0,\"\\n            \"],[7,\"td\"],[9],[1,[22,1,[]],false],[10],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/form-global-resource-roles/template.hbs"
    }
  });

  _exports.default = _default;
});