define("shared/components/schema/input-istiohost/component", ["exports", "shared/components/schema/input-istiohost/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['form-control'],
    namespaceId: null,
    fiedl: null,
    value: null,
    hosts: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'hosts', Ember.get(this, 'store').all('service'));
    },
    hostChoices: Ember.computed('hosts.@each.name', 'namespaceId', function () {
      return Ember.get(this, 'hosts').filter(function (host) {
        return Ember.get(host, 'selector.app');
      }).filterBy('namespaceId', Ember.get(this, 'namespaceId')).map(function (v) {
        var name = Ember.get(v, 'name');
        return {
          label: name,
          value: name
        };
      }).sortBy('label');
    })
  });

  _exports.default = _default;
});