enifed("@ember/-internals/routing/lib/system/query_params", ["exports"], function (_exports) {
  "use strict";

  _exports.default = void 0;

  var QueryParams = function QueryParams() {
    var values = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    this.isQueryParams = true;
    this.values = values;
  };

  _exports.default = QueryParams;
});