define("shared/components/upgrade-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ecJTjb3p",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[22,0,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"currentVersion\"]]],null,{\"statements\":[[4,\"tooltip-element\",null,[[\"type\",\"model\",\"tooltipTemplate\",\"aria-describedby\",\"tooltipFor\"],[\"tooltip-basic\",[23,[\"currentVersion\"]],\"tooltip-static\",\"tooltip-base\",\"upgrade\"]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[[27,\"concat\",[\"upgradeBtn.status.\",[23,[\"upgradeStatus\"]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"t\",[[27,\"concat\",[\"upgradeBtn.status.\",[23,[\"upgradeStatus\"]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/upgrade-btn/template.hbs"
    }
  });

  _exports.default = _default;
});