define("ember-basic-dropdown/templates/components/basic-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H4xcgj2l",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"uniqueId\",\"isOpen\",\"disabled\",\"actions\",\"trigger\",\"content\"],[[23,[\"publicAPI\",\"uniqueId\"]],[23,[\"publicAPI\",\"isOpen\"]],[23,[\"publicAPI\",\"disabled\"]],[23,[\"publicAPI\",\"actions\"]],[27,\"component\",[[23,[\"triggerComponent\"]]],[[\"dropdown\",\"hPosition\",\"onFocus\",\"renderInPlace\",\"vPosition\"],[[27,\"readonly\",[[23,[\"publicAPI\"]]],null],[27,\"readonly\",[[23,[\"hPosition\"]]],null],[27,\"action\",[[22,0,[]],\"handleFocus\"],null],[27,\"readonly\",[[23,[\"renderInPlace\"]]],null],[27,\"readonly\",[[23,[\"vPosition\"]]],null]]]],[27,\"component\",[[23,[\"contentComponent\"]]],[[\"dropdown\",\"hPosition\",\"renderInPlace\",\"preventScroll\",\"rootEventType\",\"vPosition\",\"destination\",\"top\",\"left\",\"right\",\"width\",\"height\",\"otherStyles\"],[[27,\"readonly\",[[23,[\"publicAPI\"]]],null],[27,\"readonly\",[[23,[\"hPosition\"]]],null],[27,\"readonly\",[[23,[\"renderInPlace\"]]],null],[27,\"readonly\",[[23,[\"preventScroll\"]]],null],[27,\"readonly\",[[23,[\"rootEventType\"]]],null],[27,\"readonly\",[[23,[\"vPosition\"]]],null],[27,\"readonly\",[[23,[\"destination\"]]],null],[27,\"readonly\",[[23,[\"top\"]]],null],[27,\"readonly\",[[23,[\"left\"]]],null],[27,\"readonly\",[[23,[\"right\"]]],null],[27,\"readonly\",[[23,[\"width\"]]],null],[27,\"readonly\",[[23,[\"height\"]]],null],[27,\"readonly\",[[23,[\"otherStyles\"]]],null]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-basic-dropdown/templates/components/basic-dropdown.hbs"
    }
  });

  _exports.default = _default;
});