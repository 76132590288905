define('ember-diff-attrs/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (keys, hook) {
    var oldValuesMap = new WeakMap();
    var isEqualFunc = isEqual;

    if ((typeof keys === 'undefined' ? 'undefined' : _typeof(keys)) === 'object') {
      var options = keys;
      keys = options.keys;

      if (options.isEqual) {
        isEqualFunc = options.isEqual;
      }
      if (options.hook) {
        hook = options.hook;
      }
    } else if (arguments.length > 1) {
      keys = [].slice.call(arguments);
      hook = keys.pop();
    } else {
      throw new Error('Invalid `diffAttrs` argument. Expected either one or more strings and a function, or an options hash.');
    }

    return function () {
      var _this = this;

      var changedAttrs = {};
      var oldValues = void 0;
      var isFirstCall = false;

      if (!oldValuesMap.has(this)) {
        isFirstCall = true;
        oldValuesMap.set(this, {});
      }

      oldValues = oldValuesMap.get(this);

      keys.forEach(function (key) {
        var value = _this.get(key);
        if (!isEqualFunc(key, oldValues[key], value)) {
          changedAttrs[key] = [oldValues[key], value];
          oldValues[key] = value;
        }
      });

      hook.apply(this, [isFirstCall ? null : changedAttrs].concat(Array.prototype.slice.call(arguments)));
    };
  };

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function isEqual(key, a, b) {
    return a === b;
  }
});