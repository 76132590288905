define("shared/components/labels-section/component", ["exports", "shared/mixins/manage-labels", "shared/components/labels-section/template"], function (_exports, _manageLabels, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HEADERS_WITH_KIND = [{
    name: 'kind',
    sort: ['type', 'key'],
    translationKey: 'labelsSection.kind',
    width: '90'
  }, {
    name: 'key',
    sort: ['key'],
    translationKey: 'labelsSection.key',
    width: '350'
  }, {
    name: 'value',
    sort: ['value', 'key'],
    translationKey: 'labelsSection.value'
  }];
  var HEADERS = [{
    name: 'key',
    sort: ['key'],
    translationKey: 'labelsSection.key',
    width: '350'
  }, {
    name: 'value',
    sort: ['value', 'key'],
    translationKey: 'labelsSection.value'
  }];

  var _default = Ember.Component.extend(_manageLabels.default, {
    layout: _template.default,
    model: null,
    sortBy: 'kind',
    translationDetail: 'labelsSection.detail',
    showKind: true,
    descending: true,
    labelSource: Ember.computed.alias('model.labels'),
    didReceiveAttrs: function didReceiveAttrs() {
      this.initLabels(this.get('labelSource'));
    },
    labelsObserver: Ember.observer('model.labels', function () {
      this.initLabels(this.get('labelSource'));
    }),
    headers: Ember.computed('showKind', function () {
      return Ember.get(this, 'showKind') ? HEADERS_WITH_KIND : HEADERS;
    })
  });

  _exports.default = _default;
});