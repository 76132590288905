define("shared/components/form-gke-taints/component", ["exports", "shared/components/form-gke-taints/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EFFECT = ['NO_SCHEDULE', 'PREFER_NO_SCHEDULE', 'NO_EXECUTE'];

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),
    layout: _template.default,
    editing: false,
    showWarning: false,
    taints: null,
    actions: {
      addTaint: function addTaint() {
        Ember.get(this, 'taints').pushObject({
          effect: 'NO_SCHEDULE',
          key: '',
          value: ''
        });
      },
      removeTaint: function removeTaint(obj) {
        Ember.get(this, 'taints').removeObject(obj);
      }
    },
    effectContent: Ember.computed(function () {
      return EFFECT.map(function (e) {
        return {
          label: e,
          value: e
        };
      });
    })
  });

  _exports.default = _default;
});