define("shared/helpers/get-card-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getCardClass = getCardClass;
  _exports.default = void 0;
  // this list matchse the card types that stripe uses
  var CARDS = {
    'Visa': 'card-visa',
    'American Express': 'card-amex',
    'MasterCard': 'card-mastercard',
    'Discover': 'card-discover',
    'Diners Club': 'card-diners',
    'JCB': 'card-jcb'
  };

  function getCardClass(key
  /* , hash*/
  ) {
    return CARDS[key];
  }

  var _default = Ember.Helper.helper(getCardClass);

  _exports.default = _default;
});