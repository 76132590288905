define("shared/mixins/throttled-resize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    boundResize: null,
    throttleTimer: null,
    resizeInterval: 200,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('boundResize', this.triggerResize.bind(this));
      Ember.$(window).on('resize', this.get('boundResize'));
      Ember.$(window).on('focus', this.get('boundResize'));
      Ember.run.next(this, 'onResize');
    },
    triggerResize: function triggerResize() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      var timer = Ember.run.throttle(this, 'onResize', this.get('resizeInterval'), false);
      this.set('throttleTimer', timer);
    },
    onResize: function onResize() {// Override me with resize logic
    },
    willDestroyElement: function willDestroyElement() {
      this.stopResize();
    },
    stopResize: function stopResize() {
      Ember.run.cancel(this.get('throttleTimer'));
      Ember.$(window).off('resize', this.get('boundResize'));
      Ember.$(window).off('focus', this.get('boundResize'));
    }
  });

  _exports.default = _default;
});