define('ember-copy/index', ['exports', 'ember-copy/copy', 'ember-copy/copyable'], function (exports, _copy, _copyable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'copy', {
    enumerable: true,
    get: function () {
      return _copy.default;
    }
  });
  Object.defineProperty(exports, 'Copyable', {
    enumerable: true,
    get: function () {
      return _copyable.default;
    }
  });
});