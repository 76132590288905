define("shared/components/action-menu-item/component", ["exports", "shared/components/action-menu-item/template", "shared/utils/constants", "shared/utils/platform"], function (_exports, _template, _constants, _platform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    resourceActions: Ember.inject.service('resource-actions'),
    layout: _template.default,
    icon: 'icon-help',
    label: '',
    prefix: null,
    enabled: true,
    actionArg: null,
    altActionArg: null,
    tagName: 'a',
    classNameBindings: ['enabled::hide'],
    attributeBindings: ['tabindex'],
    tabindex: 0,
    willRender: function willRender() {
      var icon = this.get('icon');

      if (icon.indexOf('icon-') === -1) {
        this.set('prefix', 'icon icon-fw');
      }
    },
    iconChanged: function () {
      this.rerender();
    }.observes('icon'),
    click: function click(event) {
      var actionArg = null;

      if ((0, _platform.isAlternate)(event) && this.get('altActionArg')) {
        actionArg = this.get('altActionArg');
      } else {
        actionArg = this.get('actionArg');
      }

      if (this.action) {
        this.action(actionArg);
      }
    },
    keyPress: function keyPress(event) {
      if ([_constants.default.KEY.CR, _constants.default.KEY.LF].indexOf(event.which) >= 0) {
        this.click(event);
        this.get('resourceActions').hide();
      }
    }
  });

  _exports.default = _default;
});