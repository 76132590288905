define("global-admin/multi-cluster-apps/catalog/launch/route", ["exports", "shared/utils/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    modalService: Ember.inject.service('modal'),
    catalog: Ember.inject.service(),
    scope: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    store: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    parentRoute: 'multi-cluster-apps.catalog',
    model: function model(params
    /* , transition*/
    ) {
      var _this = this;

      var store = Ember.get(this, 'globalStore');
      var dependencies = {
        tpl: Ember.get(this, 'catalog').fetchTemplate(params.template),
        projects: this.scope.getAllProjects(),
        clusters: this.scope.getAllClusters()
      };

      if (params.upgrade) {
        dependencies.upgrade = Ember.get(this, 'catalog').fetchTemplate("".concat(params.template, "-").concat(params.upgrade), true);
      }

      if (params.appId) {
        dependencies.app = store.find('multiclusterapp', params.appId);
      }

      return Ember.RSVP.hash(dependencies, 'Load dependencies').then(function (results) {
        var kind = 'helm';
        var neuApp = null;
        var links; // get diff versions

        links = results.tpl.versionLinks;
        var verArr = Object.keys(links).filter(function (key) {
          return !!links[key];
        }).map(function (key) {
          return {
            version: key,
            sortVersion: key,
            link: links[key]
          };
        });

        if (results.app) {
          if (Ember.get(params, 'clone')) {
            neuApp = results.app.cloneForNew();
            Ember.set(neuApp, 'name', _this.dedupeName(results.app.name));

            if (neuApp.targets.length > 0) {
              var neuTargets = [];
              neuApp.targets.forEach(function (t) {
                neuTargets.pushObject(t.cloneForNew());
              });
              Ember.set(neuApp, 'targets', neuTargets);
            }
          } else {
            neuApp = results.app.clone();
          }
        } else {
          var defaultAnswwer = store.createRecord({
            clusterId: null,
            projectId: null,
            type: 'answer',
            values: []
          });
          neuApp = store.createRecord({
            type: 'multiclusterapp',
            answers: [defaultAnswwer],
            catalogId: results.tpl.catalogId,
            targets: [],
            members: [],
            roles: ['project-member']
          });
        }

        if (neuApp.id) {
          verArr.filter(function (ver) {
            return ver.version === Ember.get(neuApp, 'externalIdInfo.version');
          }).forEach(function (ver) {
            Ember.set(ver, 'version', "".concat(ver.version, " (current)"));
          });
        }

        return {
          multiClusterApp: neuApp,
          projects: results.projects,
          clusters: results.clusters,
          tpl: results.tpl,
          tplKind: kind,
          upgradeTemplate: results.upgrade,
          versionLinks: links,
          versionsArray: verArr,
          isClone: Ember.get(params, 'clone') ? true : false
        };
      });
    },
    afterModel: function afterModel(model
    /* , transition */
    ) {
      var _this2 = this;

      if (Ember.get(model, 'multiClusterApp.id') && Ember.get(model, 'multiClusterApp.members.length')) {
        var membersPromises = [];
        Ember.get(model, 'multiClusterApp.members').forEach(function (member) {
          var id = Ember.get(member, 'userPrincipalId') || Ember.get(member, 'groupPrincipalId');
          membersPromises.push(_this2.globalStore.find('principal', id));
        });
        return Ember.RSVP.all(membersPromises).catch(function ()
        /* err */
        {
          // we fail here when we can't look up a principal (e.g. logged in as local but its an external auth provider principal)
          return;
        });
      }

      return;
    },
    resetController: function resetController(controller, isExiting
    /* , transition*/
    ) {
      if (isExiting) {
        Ember.setProperties(controller, {
          appId: null,
          catalog: null,
          template: null,
          upgrade: null,
          clone: null
        });
      }
    },
    dedupeName: function dedupeName(name) {
      var suffix = (0, _util.randomStr)(5, 5, 'novowels');
      var newName = null;
      newName = "".concat(name, "-").concat(suffix);
      return newName;
    }
  });

  _exports.default = _default;
});