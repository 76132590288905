define("shared/components/gke-access-scope/component", ["exports", "shared/components/gke-access-scope/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var userInfo = [{
    label: 'generic.none',
    value: 'none'
  }, {
    label: 'generic.enabled',
    value: 'userinfo.email'
  }];
  var computeEngine = [{
    label: 'generic.none',
    value: 'none'
  }, {
    label: 'generic.readOnly',
    value: 'compute.readonly'
  }, {
    label: 'generic.readWrite',
    value: 'compute'
  }];
  var storage = [{
    label: 'generic.none',
    value: 'none'
  }, {
    label: 'generic.readOnly',
    value: 'devstorage.read_only'
  }, {
    label: 'generic.writeOnly',
    value: 'devstorage.write_only'
  }, {
    label: 'generic.readWrite',
    value: 'devstorage'
  }, {
    label: 'generic.full',
    value: 'devstorage.full_control'
  }];
  var taskQueue = [{
    label: 'generic.none',
    value: 'none'
  }, {
    label: 'generic.enabled',
    value: 'taskqueue'
  }];
  var bigQuery = [{
    label: 'generic.none',
    value: 'none'
  }, {
    label: 'generic.enabled',
    value: 'bigquery'
  }];
  var cloudSQL = [{
    label: 'generic.none',
    value: 'none'
  }, {
    label: 'generic.enabled',
    value: 'sqlservice.admin'
  }];
  var cloudDatastore = [{
    label: 'generic.none',
    value: 'none'
  }, {
    label: 'generic.enabled',
    value: 'clouddatastore'
  }];
  var stackdriverLoggingAPI = [{
    label: 'generic.none',
    value: 'none'
  }, {
    label: 'generic.writeOnly',
    value: 'logging.write'
  }, {
    label: 'generic.readOnly',
    value: 'logging.read'
  }, {
    label: 'generic.full',
    value: 'logging.admin'
  }];
  var stackdriverMonitoringAPI = [{
    label: 'generic.none',
    value: 'none'
  }, {
    label: 'generic.writeOnly',
    value: 'monitoring.write'
  }, {
    label: 'generic.readOnly',
    value: 'monitoring.read'
  }, {
    label: 'generic.full',
    value: 'monitoring'
  }];
  var cloudPlatform = [{
    label: 'generic.none',
    value: 'none'
  }, {
    label: 'generic.enabled',
    value: 'cloud-platform'
  }];
  var bigtableData = [{
    label: 'generic.none',
    value: 'none'
  }, {
    label: 'generic.readOnly',
    value: 'bigtable.data.readonly'
  }, {
    label: 'generic.readWrite',
    value: 'bigtable.data.readonly'
  }];
  var bigtableAdmin = [{
    label: 'generic.none',
    value: 'none'
  }, {
    label: 'clusterNew.googlegke.tablesOnly',
    value: 'bigtable.admin.table'
  }, {
    label: 'generic.full',
    value: 'bigtable.admin'
  }];
  var cloudPub = [{
    label: 'generic.none',
    value: 'none'
  }, {
    label: 'generic.enabled',
    value: 'pubsub'
  }];
  var serviceControl = [{
    label: 'generic.none',
    value: 'none'
  }, {
    label: 'generic.enabled',
    value: 'servicecontrol'
  }];
  var serviceManagement = [{
    label: 'generic.none',
    value: 'none'
  }, {
    label: 'generic.readOnly',
    value: 'service.management.readonly'
  }, {
    label: 'generic.readWrite',
    value: 'service.management'
  }];
  var stackdriverTrace = [{
    label: 'generic.none',
    value: 'none'
  }, {
    label: 'generic.readOnly',
    value: 'trace.readonly'
  }, {
    label: 'generic.writeOnly',
    value: 'trace.append'
  }];
  var cloudSourceRepositories = [{
    label: 'generic.none',
    value: 'none'
  }, {
    label: 'generic.readOnly',
    value: 'source.read_only'
  }, {
    label: 'generic.readWrite',
    value: 'source.read_write'
  }, {
    label: 'generic.full',
    value: 'source.full_control'
  }];
  var cloudDebugger = [{
    label: 'generic.none',
    value: 'none'
  }, {
    label: 'generic.enabled',
    value: 'cloud_debugger'
  }];

  var _default = Ember.Component.extend({
    layout: _template.default,
    userInfoContent: userInfo,
    computeEngineContent: computeEngine,
    storageContent: storage,
    taskQueueContent: taskQueue,
    bigQueryContent: bigQuery,
    cloudSQLContent: cloudSQL,
    cloudDatastoreContent: cloudDatastore,
    stackdriverLoggingAPIContent: stackdriverLoggingAPI,
    stackdriverMonitoringAPIContent: stackdriverMonitoringAPI,
    cloudPlatformContent: cloudPlatform,
    bigtableDataContent: bigtableData,
    bigtableAdminContent: bigtableAdmin,
    cloudPubContent: cloudPub,
    serviceControlContent: serviceControl,
    serviceManagementContent: serviceManagement,
    stackdriverTraceContent: stackdriverTrace,
    cloudSourceRepositoriesContent: cloudSourceRepositories,
    cloudDebuggerContent: cloudDebugger
  });

  _exports.default = _default;
});