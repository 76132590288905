define("global-admin/components/multi-cluster-app/component", ["exports", "global-admin/components/multi-cluster-app/template", "shared/mixins/lazy-icon"], function (_exports, _template, _lazyIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_lazyIcon.default, {
    scope: Ember.inject.service(),
    layout: _template.default,
    classNames: ['namespace-app'],
    srcSet: false,
    latestVersion: null
  });

  _exports.default = _default;
});