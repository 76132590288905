define('ember-math-helpers/helpers/add', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.add = add;
  function add(params) {
    return params.reduce(function (a, b) {
      return Number(a) + Number(b);
    });
  }

  exports.default = Ember.Helper.helper(add);
});