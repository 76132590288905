define("global-admin/components/form-capability-policy/component", ["exports", "global-admin/components/form-capability-policy/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @TODO-2.0 This api doesn't work. this.get('store').getById('schema','container').get('resourceFields.capAdd').options.sort();
  var choices = ['AUDIT_CONTROL', 'AUDIT_WRITE', 'BLOCK_SUSPEND', 'CHOWN', 'DAC_OVERRIDE', 'DAC_READ_SEARCH', 'FOWNER', 'FSETID', 'IPC_LOCK', 'IPC_OWNER', 'KILL', 'LEASE', 'LINUX_IMMUTABLE', 'MAC_ADMIN', 'MAC_OVERRIDE', 'MKNOD', 'NET_ADMIN', 'NET_BIND_SERVICE', 'NET_BROADCAST', 'NET_RAW', 'SETFCAP', 'SETGID', 'SETPCAP', 'SETUID', 'SYSLOG', 'SYS_ADMIN', 'SYS_BOOT', 'SYS_CHROOT', 'SYS_MODULE', 'SYS_NICE', 'SYS_PACCT', 'SYS_PTRACE', 'SYS_RAWIO', 'SYS_RESOURCE', 'SYS_TIME', 'SYS_TTY_CONFIG', 'WAKE_ALARM'];

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['accordion-wrapper'],
    model: null,
    basicPolicies: null,
    readOnly: false,
    capabilityChoices: null,
    statusClass: null,
    status: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.initCapability();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('expandFn')) {
        this.set('expandFn', function (item) {
          item.toggleProperty('expanded');
        });
      }
    },
    actions: {
      modifyCapabilities: function modifyCapabilities(type, select) {
        var options = Array.prototype.slice.call(select.target.options, 0);
        var selectedOptions = [];
        options.filterBy('selected', true).forEach(function (cap) {
          return selectedOptions.push(cap.value);
        });
        this.set("model.".concat(type), selectedOptions);
      }
    },
    initCapability: function initCapability() {
      this.set('model.allowedCapabilities', this.get('model.allowedCapabilities') || []);
      this.set('model.defaultAddCapabilities', this.get('model.defaultAddCapabilities') || []);
      this.set('model.requiredDropCapabilities', this.get('model.requiredDropCapabilities') || []);
      this.set('capabilityChoices', choices);
    }
  });

  _exports.default = _default;
});