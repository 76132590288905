define("shared/components/copy-inline/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "78Sf6NG/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"isSupported\"]]],null,{\"statements\":[[4,\"tooltip-element\",null,[[\"type\",\"model\",\"tooltipTemplate\",\"class\",\"textChangedEvent\",\"baseClass\",\"isCopyTo\",\"tooltipFor\"],[\"tooltip-basic\",[27,\"t\",[[27,\"if\",[[23,[\"model\"]],[23,[\"model\",\"tooltipText\"]],\"generic.emptyString\"],null]],null],\"tooltip-static\",\"copy-button-container\",[23,[\"textChangedEvent\"]],\"copy-clipboard\",true,\"copyToClipboard\"]],{\"statements\":[[4,\"copy-button\",null,[[\"tagName\",\"class\",\"clipboardTarget\",\"clipboardText\",\"success\"],[\"span\",\"hand bg-transparent\",[23,[\"target\"]],[23,[\"clipboardText\"]],\"alertSuccess\"]],{\"statements\":[[0,\"      \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/copy-inline/template.hbs"
    }
  });

  _exports.default = _default;
});