define("shared/components/modal-save-rke-template/component", ["exports", "shared/mixins/modal-base", "shared/components/modal-save-rke-template/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_REV_NAME = 'v1';

  var _default = Ember.Component.extend(_modalBase.default, {
    globalStore: Ember.inject.service(),
    growl: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    router: Ember.inject.service(),
    layout: _template.default,
    classNames: ['modal-container', 'medium-modal'],
    clusterTemplateName: null,
    clusterTemplateRevisionName: null,
    cluster: Ember.computed.alias('modalService.modalOpts.cluster'),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'clusterTemplateRevisionName', DEFAULT_REV_NAME);
    },
    actions: {
      save: function save() {
        var _this = this;

        var cluster = this.cluster,
            clusterTemplateName = this.clusterTemplateName,
            clusterTemplateRevisionName = this.clusterTemplateRevisionName;
        return cluster.doAction('saveAsTemplate', {
          clusterTemplateName: clusterTemplateName,
          clusterTemplateRevisionName: clusterTemplateRevisionName
        }).then(function () {
          return _this.cluster.waitForClusterTemplateToBeAttached().then(function () {
            return _this.router.transitionTo('global-admin.cluster-templates.detail', _this.cluster.clusterTemplateRevisionId);
          });
        }).catch(function (err) {
          return _this.growl.fromError(err);
        });
      }
    },
    saveDisabled: Ember.computed('clusterTemplateName', 'clusterTemplateRevisionName', function () {
      var clusterTemplateName = this.clusterTemplateName,
          clusterTemplateRevisionName = this.clusterTemplateRevisionName;

      if (Ember.isEmpty(clusterTemplateName) && Ember.isEmpty(clusterTemplateRevisionName)) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});