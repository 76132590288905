define("global-admin/security/authentication/googleoauth/controller", ["exports", "global-admin/mixins/authentication"], function (_exports, _authentication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_authentication.default, {
    oauth: Ember.inject.service(),
    endpoint: Ember.inject.service(),
    access: Ember.inject.service(),
    settings: Ember.inject.service(),
    confirmDisable: false,
    errors: null,
    testing: false,
    error: null,
    saved: false,
    saving: false,
    haveToken: false,
    organizations: null,
    secure: true,
    editing: false,
    authConfig: Ember.computed.alias('model.googleConfig'),
    actions: {
      save: function save() {
        this.send('clearError');
        Ember.set(this, 'saving', true);
        var authConfig = Ember.get(this, 'authConfig');
        var am = Ember.get(authConfig, 'accessMode') || 'unrestricted';
        Ember.setProperties(authConfig, {
          'oauthCredential': (Ember.get(authConfig, 'oauthCredential') || '').trim(),
          'serviceAccountCredential': (Ember.get(authConfig, 'serviceAccountCredential') || '').trim(),
          'adminEmail': (Ember.get(authConfig, 'adminEmail') || '').trim(),
          'hostname': (Ember.get(authConfig, 'hostname') || '').trim(),
          'enabled': false,
          'accessMode': am,
          'tls': true,
          'allowedPrincipalIds': Ember.get(authConfig, 'allowedPrincipalIds') || []
        });
        Ember.set(this, '_boundSucceed', this.authenticationApplied.bind(this));
        Ember.get(this, 'oauth').test(authConfig, Ember.get(this, '_boundSucceed'));
      }
    },
    destinationUrl: Ember.computed(function () {
      return "".concat(window.location.origin, "/");
    }),
    destinationDomain: Ember.computed(function () {
      return "".concat(window.location.hostname);
    }),
    redirectURI: Ember.computed(function () {
      return "".concat(window.location.origin, "/verify-auth");
    }),
    authorizedJavascriptOrigin: Ember.computed(function () {
      return "".concat(window.location.origin);
    }),
    buttonDisabled: Ember.computed('authConfig.hostname', 'authConfig.adminEmail', 'authConfig.serviceAccountCredential', 'authConfig.oauthCredential', function () {
      var authConfig = Ember.get(this, 'authConfig');
      var isHostnameEmpty = Ember.isEmpty(authConfig.get('hostname'));
      var isAdminEmailEmpty = Ember.isEmpty(authConfig.get('adminEmail'));

      if (!Ember.get(this, 'authConfig.oauthCredential') || !Ember.get(this, 'authConfig.serviceAccountCredential') || isHostnameEmpty || isAdminEmailEmpty) {
        return true;
      }

      return false;
    }),
    authenticationApplied: function authenticationApplied(err) {
      Ember.set(this, 'saving', false);

      if (err) {
        if (!Ember.get(this, 'model.originalConfig.enabled')) {
          Ember.set(this, 'isEnabled', false);
        } else {
          Ember.set(this, 'isEnabled', true);
        }

        this.send('gotError', err);
        return;
      }

      this.send('clearError');
    }
  });

  _exports.default = _default;
});