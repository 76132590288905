define("shared/components/node-ip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lsnVNSNQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[[27,\"if\",[[23,[\"textMuted\"]],\"text-muted\"],null],\" clip\"]]],[9],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[23,[\"model\",\"externalIpAddress\"]],[27,\"not-eq\",[[23,[\"model\",\"externalIpAddress\"]],[23,[\"model\",\"ipAddress\"]]],null]],null]],null,{\"statements\":[[4,\"copy-to-clipboard\",null,[[\"clipboardText\",\"size\"],[[23,[\"model\",\"externalIpAddress\"]],\"small\"]],{\"statements\":[[0,\"      \"],[1,[27,\"format-ip\",[[23,[\"model\",\"externalIpAddress\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"     / \\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"model\",\"ipAddress\"]]],null,{\"statements\":[[4,\"copy-to-clipboard\",null,[[\"clipboardText\",\"size\"],[[23,[\"model\",\"ipAddress\"]],\"small\"]],{\"statements\":[[0,\"      \"],[1,[27,\"format-ip\",[[23,[\"model\",\"ipAddress\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/node-ip/template.hbs"
    }
  });

  _exports.default = _default;
});