define("shared/mixins/stripped-name", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    stripStack: true,
    prefixLength: function () {
      var name = this.get('model.displayName');

      var stackName = (this.get('model.labels') || {})[_constants.default.LABEL.STACK_NAME];

      if (stackName && name.indexOf(stackName) === 0) {
        return stackName.length + 1;
      }

      return 0;
    }.property('name'),
    showEllipsis: Ember.computed.and('stripStack', 'prefixLength'),
    displayName: function () {
      var name = this.get('model.displayName') || '';

      if (this.get('stripStack')) {
        var len = this.get('prefixLength');
        return name.substr(len);
      } else {
        return name;
      }
    }.property('stripStack', 'prefixLength', 'model.displayName')
  });

  _exports.default = _default;
});