define("shared/components/form-ingress-rule/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TQyXxWKj",
    "block": "{\"symbols\":[\"rule\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"label\"],[9],[1,[27,\"t\",[\"formIngress.label\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"hr\"],[9],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"rules\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"form-ingress-rows\",null,[[\"editing\",\"ingress\",\"namespace\",\"removeRule\",\"rule\",\"rules\"],[[23,[\"editing\"]],[23,[\"ingress\"]],[23,[\"namespace\"]],[27,\"action\",[[22,0,[]],\"removeRule\"],null],[22,1,[]],[23,[\"rules\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"p-20\"],[9],[1,[27,\"t\",[\"formIngress.noRules\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,[\"editing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn bg-link icon-btn pull-right\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"darken\"],[9],[7,\"i\"],[11,\"class\",\"icon icon-plus text-small\"],[9],[10],[10],[0,\"\\n      \"],[7,\"span\"],[9],[1,[27,\"t\",[\"formIngress.addRuleLabel\"],null],false],[10],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"addRule\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/form-ingress-rule/template.hbs"
    }
  });

  _exports.default = _default;
});