define("global-admin/multi-cluster-apps/catalog/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cPJ+qbia",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"catalog-index\",null,[[\"application\",\"catalogId\",\"category\",\"categoryAction\",\"launch\",\"launchRoute\",\"model\",\"parentRoute\",\"refresh\",\"showHeader\"],[[23,[\"application\"]],[23,[\"catalogId\"]],[23,[\"category\"]],[27,\"action\",[[22,0,[]],\"categoryAction\"],null],[27,\"action\",[[22,0,[]],\"launch\"],null],[23,[\"launchRoute\"]],[23,[\"model\"]],[23,[\"parentRoute\"]],[27,\"action\",[[22,0,[]],\"refresh\"],null],true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/multi-cluster-apps/catalog/index/template.hbs"
    }
  });

  _exports.default = _default;
});