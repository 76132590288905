define("shared/utils/calculate-position", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  _exports.calculateWormholedPosition = calculateWormholedPosition;
  _exports.calculateInPlacePosition = calculateInPlacePosition;

  function _default(_, _2, _destination, _ref) {
    var renderInPlace = _ref.renderInPlace;

    if (renderInPlace) {
      return calculateInPlacePosition.apply(void 0, arguments);
    } else {
      return calculateWormholedPosition.apply(void 0, arguments);
    }
  }

  function calculateWormholedPosition(trigger, content, destination, _ref2) {
    var horizontalPosition = _ref2.horizontalPosition,
        verticalPosition = _ref2.verticalPosition,
        matchTriggerWidth = _ref2.matchTriggerWidth,
        previousHorizontalPosition = _ref2.previousHorizontalPosition,
        previousVerticalPosition = _ref2.previousVerticalPosition;
    // Re-implement calculateWormholePosition
    // https://git.io/vhf7p
    // See WJW comment below
    var scroll = {
      left: window.pageXOffset,
      top: window.pageYOffset
    };

    var _trigger$getBoundingC = trigger.getBoundingClientRect(),
        triggerLeft = _trigger$getBoundingC.left,
        triggerTop = _trigger$getBoundingC.top,
        triggerWidth = _trigger$getBoundingC.width,
        triggerHeight = _trigger$getBoundingC.height;

    var _content$getBoundingC = content.getBoundingClientRect(),
        dropdownHeight = _content$getBoundingC.height,
        dropdownWidth = _content$getBoundingC.width;

    var viewportWidth = document.body.clientWidth || window.innerWidth;
    var style = {}; // Apply containers' offset

    var anchorElement = destination.parentNode;
    var anchorPosition = window.getComputedStyle(anchorElement).position;

    while (anchorPosition !== 'relative' && anchorPosition !== 'absolute' && anchorElement.tagName.toUpperCase() !== 'BODY') {
      anchorElement = anchorElement.parentNode;
      anchorPosition = window.getComputedStyle(anchorElement).position;
    }

    if (anchorPosition === 'relative' || anchorPosition === 'absolute') {
      var rect = anchorElement.getBoundingClientRect();
      triggerLeft = triggerLeft - rect.left;
      triggerTop = triggerTop - rect.top;
      var _anchorElement = anchorElement,
          offsetParent = _anchorElement.offsetParent;

      if (offsetParent) {
        triggerLeft -= anchorElement.offsetParent.scrollLeft;
        triggerTop -= anchorElement.offsetParent.scrollTop;
      }
    } // Calculate drop down width


    dropdownWidth = matchTriggerWidth ? triggerWidth : dropdownWidth;

    if (matchTriggerWidth) {
      style.width = dropdownWidth;
    } // Calculate horizontal position


    var triggerLeftWithScroll = triggerLeft + scroll.left;

    if (horizontalPosition === 'auto' || horizontalPosition === 'auto-left') {
      // Calculate the number of visible horizontal pixels if we were to place the
      // dropdown on the left and right
      var leftVisible = Math.min(viewportWidth, triggerLeft + dropdownWidth) - Math.max(0, triggerLeft);
      var rightVisible = Math.min(viewportWidth, triggerLeft + triggerWidth) - Math.max(0, triggerLeft + triggerWidth - dropdownWidth);

      if (dropdownWidth > leftVisible && rightVisible > leftVisible) {
        // If the drop down won't fit left-aligned, and there is more space on the
        // right than on the left, then force right-aligned
        horizontalPosition = 'right';
      } else if (dropdownWidth > rightVisible && leftVisible > rightVisible) {
        // If the drop down won't fit right-aligned, and there is more space on
        // the left than on the right, then force left-aligned
        horizontalPosition = 'left';
      } else {
        // Keep same position as previous
        horizontalPosition = previousHorizontalPosition || 'left';
      }
    } else if (horizontalPosition === 'auto-right') {
      // Calculate the number of visible horizontal pixels if we were to place the
      // dropdown on the left and right
      var _leftVisible = Math.min(viewportWidth, triggerLeft + dropdownWidth) - Math.max(0, triggerLeft);

      var _rightVisible = Math.min(viewportWidth, triggerLeft + triggerWidth) - Math.max(0, triggerLeft + triggerWidth - dropdownWidth);

      if (dropdownWidth > _rightVisible && _leftVisible > _rightVisible) {
        // If the drop down won't fit right-aligned, and there is more space on the
        // left than on the right, then force left-aligned
        horizontalPosition = 'left';
      } else if (dropdownWidth > _leftVisible && _rightVisible > _leftVisible) {
        // If the drop down won't fit left-aligned, and there is more space on
        // the right than on the left, then force right-aligned
        horizontalPosition = 'right';
      } else {
        // Keep same position as previous
        horizontalPosition = previousHorizontalPosition || 'right';
      }
    }

    if (horizontalPosition === 'right') {
      style.right = viewportWidth - (triggerLeftWithScroll + triggerWidth);
    } else if (horizontalPosition === 'center') {
      style.left = triggerLeftWithScroll + (triggerWidth - dropdownWidth) / 2;
    } else {
      style.left = triggerLeftWithScroll;
    } // Calculate vertical position


    var triggerTopWithScroll = triggerTop;
    /**
     * Fixes bug where the dropdown always stays on the same position on the screen when
     * the <body> is relatively positioned
     */

    var isBodyPositionRelative = window.getComputedStyle(document.body).getPropertyValue('position') === 'relative';

    if (!isBodyPositionRelative) {
      triggerTopWithScroll += scroll.top;
    }

    if (verticalPosition === 'above') {
      style.top = triggerTopWithScroll - dropdownHeight;
    } else if (verticalPosition === 'below') {
      style.top = triggerTopWithScroll + triggerHeight;
    } else {
      var viewportBottom = scroll.top + window.innerHeight;
      var enoughRoomBelow = triggerTopWithScroll + triggerHeight + dropdownHeight < viewportBottom;
      var enoughRoomAbove = triggerTop > dropdownHeight;

      if (previousVerticalPosition === 'below' && !enoughRoomBelow && enoughRoomAbove) {
        verticalPosition = 'above';
      } else if (previousVerticalPosition === 'above' && !enoughRoomAbove && enoughRoomBelow) {
        verticalPosition = 'below';
      } else if (!previousVerticalPosition) {
        // WJW - Library code always opts for above if we can't go either way
        // I used all the lib code and opted for always going below in the event that
        // we can't go above or below.
        // other wise the lib positioning works as it does normally
        if (!enoughRoomAbove && !enoughRoomBelow) {
          verticalPosition = 'below';
        } else {
          verticalPosition = enoughRoomBelow ? 'below' : 'above';
        }
      } else {
        verticalPosition = previousVerticalPosition;
      }

      style.top = triggerTopWithScroll + (verticalPosition === 'below' ? triggerHeight : -dropdownHeight);
    }

    return {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      style: style
    };
  }

  function calculateInPlacePosition(trigger, content, destination, _ref3) {
    var horizontalPosition = _ref3.horizontalPosition,
        verticalPosition = _ref3.verticalPosition;
    var dropdownRect;
    var positionData = {};

    if (horizontalPosition === 'auto') {
      var triggerRect = trigger.getBoundingClientRect();
      var viewportRight = window.pageXOffset + window.innerWidth;
      dropdownRect = content.getBoundingClientRect();
      positionData.horizontalPosition = triggerRect.left + dropdownRect.width > viewportRight ? 'right' : 'left';
    } else if (horizontalPosition === 'center') {
      var _trigger$getBoundingC2 = trigger.getBoundingClientRect(),
          triggerWidth = _trigger$getBoundingC2.width;

      var _content$getBoundingC2 = content.getBoundingClientRect(),
          dropdownWidth = _content$getBoundingC2.width;

      positionData.style = {
        left: (triggerWidth - dropdownWidth) / 2
      };
    } else if (horizontalPosition === 'auto-right') {
      var _triggerRect = trigger.getBoundingClientRect();

      var _dropdownRect = content.getBoundingClientRect();

      positionData.horizontalPosition = _triggerRect.right > _dropdownRect.width ? 'right' : 'left';
    } else if (horizontalPosition === 'right') {
      positionData.horizontalPosition = 'right';
    }

    if (verticalPosition === 'above') {
      positionData.verticalPosition = verticalPosition;
      dropdownRect = dropdownRect || content.getBoundingClientRect();
      positionData.style = {
        top: -dropdownRect.height
      };
    } else {
      positionData.verticalPosition = 'below';
    }

    return positionData;
  }
});