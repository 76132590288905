define("shared/components/cru-private-registry/component", ["exports", "shared/components/cru-private-registry/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    layout: _template.default,
    configName: 'rancherKubernetesEngineConfig',
    cluster: null,
    config: null,
    editing: true,
    urlInvalid: null,
    urlWarning: null,
    urlError: null,
    clusterTemplateCreate: false,
    enablePrivateRegistry: false,
    privateRegistry: null,
    clusterTemplateRevision: null,
    applyClusterTemplate: null,
    privateRegistries: Ember.computed.alias('config.privateRegistries'),
    multipleRegistries: Ember.computed.gt('privateRegistries.length', 1),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'config', Ember.get(this, "cluster.".concat(Ember.get(this, 'configName'))));

      if (this.config.privateRegistries) {
        if (this.config.privateRegistries.length >= 1) {
          Ember.setProperties(this, {
            privateRegistry: Ember.get(this, 'config.privateRegistries.firstObject'),
            enablePrivateRegistry: true
          });
        }
      }
    },
    actions: {
      addRegistry: function addRegistry() {
        var isDefault = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
        this.addRegistry(Ember.set(this, 'privateRegistry', this.newPrivateRegistry('privateRegistry', isDefault)));
      },
      removeRegistry: function removeRegistry(registry) {
        var match = null;

        if (this.multipleRegistries) {
          var prWithout = this.privateRegistries.without(registry);
          match = prWithout.length >= 1 ? prWithout.firstObject : null;
        }

        Ember.set(this, 'privateRegistry', match);
        this.removeRegistry(registry);
      }
    },
    defaultSet: Ember.observer('privateRegistries.@each.{isDefault}', function () {
      var privateRegistries = this.privateRegistries;

      if (privateRegistries && privateRegistries.findBy('isDefault', true)) {
        Ember.set(this, 'hasDefault', true);
      } else {
        Ember.set(this, 'hasDefault', false);
      }
    }),
    enablePrivateRegistryChanged: Ember.observer('enablePrivateRegistry', function () {
      if (this.enablePrivateRegistry) {
        this.send('addRegistry');
      } else {
        this.send('removeRegistry', this.privateRegistry);
      }
    }),
    newPrivateRegistry: function newPrivateRegistry() {
      var registryType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'privateRegistry';
      var isDefault = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      return Ember.get(this, 'globalStore').createRecord({
        isDefault: isDefault,
        type: registryType
      });
    },
    addRegistry: function addRegistry() {
      throw new Error('addRegistry action is required!');
    },
    removeRegistry: function removeRegistry() {
      throw new Error('removeRegistry action is required!');
    }
  });

  _exports.default = _default;
});