define("shared/components/resource-event-list/component", ["exports", "shared/components/resource-event-list/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NON_NAMESPACED_RESOURCES = ['PersistentVolume'];
  var CLUSTER_ONLY = ['namespace', 'object'];
  var HEADERS = [{
    name: 'namespace',
    sort: ['involvedObject.namespace'],
    translationKey: 'resourceEventList.table.namespace',
    width: 120
  }, {
    name: 'type',
    sort: ['type'],
    translationKey: 'resourceEventList.table.type',
    width: 100
  }, {
    name: 'reason',
    sort: ['reason'],
    translationKey: 'resourceEventList.table.reason',
    width: 200
  }, {
    name: 'object',
    sort: ['involvedObject.name'],
    translationKey: 'resourceEventList.table.object',
    width: 200
  }, {
    name: 'message',
    sort: ['message'],
    translationKey: 'resourceEventList.table.message'
  }, {
    name: 'lastTimestamp',
    sort: ['lastTimestamp'],
    translationKey: 'resourceEventList.table.lastUpdate',
    width: 200
  }];

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    layout: _template.default,
    events: null,
    sortBy: 'lastTimestamp',
    descending: true,
    name: null,
    namespaceId: null,
    resourceType: null,
    expanded: false,
    timeOutAnchor: null,
    loading: false,
    clusterEvents: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.expanedDidChange();
    },
    willDestroyElement: function willDestroyElement() {
      this.clearTimeOut();

      this._super();
    },
    expanedDidChange: Ember.observer('expanded', 'expandAll', function () {
      if (Ember.get(this, 'expanded') || Ember.get(this, 'expandAll')) {
        Ember.set(this, 'loading', true);
        this.fetchEvents();
      } else {
        this.clearTimeOut();
      }
    }),
    headers: Ember.computed('clusterEvents', function () {
      return Ember.get(this, 'clusterEvents') ? HEADERS : HEADERS.filter(function (h) {
        return CLUSTER_ONLY.indexOf(h.name) === -1;
      });
    }),
    fetchEvents: function fetchEvents() {
      var _this = this;

      var url = "/k8s/clusters/".concat(Ember.get(this, 'scope.currentCluster.id'), "/api/v1/");

      if (Ember.get(this, 'clusterEvents')) {
        url += 'events';
      } else {
        var query = "fieldSelector=involvedObject.name=".concat(Ember.get(this, 'name'), ",involvedObject.kind=").concat(Ember.get(this, 'kind').capitalize());

        if (NON_NAMESPACED_RESOURCES.indexOf(Ember.get(this, 'kind')) === -1) {
          url += "namespaces/".concat(Ember.get(this, 'namespaceId'), "/");
        }

        url += "events?".concat(query);
      }

      Ember.get(this, 'globalStore').rawRequest({
        url: url,
        method: 'GET'
      }).then(function (xhr) {
        Ember.set(_this, 'events', xhr.body.items);
        Ember.set(_this, 'loading', false);
        var timeOutAnchor = setTimeout(function () {
          _this.fetchEvents();
        }, 10000);
        Ember.set(_this, 'timeOutAnchor', timeOutAnchor);
      });
    },
    clearTimeOut: function clearTimeOut() {
      var timeOutAnchor = Ember.get(this, 'timeOutAnchor');

      if (timeOutAnchor) {
        clearTimeout(timeOutAnchor);
        Ember.set(this, 'timeOutAnchor', timeOutAnchor);
      }
    }
  });

  _exports.default = _default;
});