define("shared/helpers/upper-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upperCase = upperCase;
  _exports.default = void 0;

  function upperCase(params) {
    return (params[0] || '').toUpperCase();
  }

  var _default = Ember.Helper.helper(upperCase);

  _exports.default = _default;
});