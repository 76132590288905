define("global-admin/components/other-role-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ofjo1kr4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[9],[0,\"\\n  \"],[1,[27,\"searchable-select\",null,[[\"content\",\"value\",\"readOnly\"],[[23,[\"choices\"]],[23,[\"model\",\"roleId\"]],[23,[\"readOnly\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[9],[0,\" \"],[10],[0,\"\\n\"],[4,\"unless\",[[23,[\"readOnly\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"input-group-btn\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn bg-primary btn-sm\"],[9],[7,\"i\"],[11,\"class\",\"icon icon-minus\"],[9],[10],[3,\"action\",[[22,0,[]],\"remove\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/components/other-role-row/template.hbs"
    }
  });

  _exports.default = _default;
});