enifed("ember-template-compiler/tests/plugins/transform-inline-link-to-test", ["ember-template-compiler/index", "internal-test-helpers"], function (_index, _internalTestHelpers) {
  "use strict";

  (0, _internalTestHelpers.moduleFor)('ember-template-compiler: inline-link-to', class extends _internalTestHelpers.AbstractTestCase {
    ['@test Can transform an inline {{link-to}} without error'](assert) {
      assert.expect(0);
      (0, _index.compile)(`{{link-to 'foo' 'index'}}`, {
        moduleName: 'foo/bar/baz'
      });
    }

  });
});