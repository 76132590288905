define('ember-math-helpers/helpers/exp', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.exp = exp;
  function exp(params) {
    return Math.exp(params[0]);
  }

  exports.default = Ember.Helper.helper(exp);
});