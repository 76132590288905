define("shared/components/search-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P5Tu1pdI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pull-right search-group input-group\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"value\",\"aria-title\",\"type\",\"class\",\"placeholder\"],[[23,[\"searchText\"]],[27,\"t\",[\"generic.search\"],null],\"search\",\"input-sm pull-right\",[27,\"t\",[\"generic.search\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"searchText\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"input-group-btn\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn bg-transparent text-info pl-10 pr-10\"],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"icon icon-close\"],[9],[10],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],\"clearSearch\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/search-text/template.hbs"
    }
  });

  _exports.default = _default;
});