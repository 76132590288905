enifed("ember-template-compiler/lib/system/initializer", ["require", "ember-template-compiler/lib/system/bootstrap"], function (_require, _bootstrap) {
  "use strict";

  // Globals mode template compiler
  if ((0, _require.has)('@ember/application') && (0, _require.has)('@ember/-internals/browser-environment') && (0, _require.has)('@ember/-internals/glimmer')) {
    // tslint:disable:no-require-imports
    let emberEnv = (0, _require.default)("@ember/-internals/browser-environment");
    let emberGlimmer = (0, _require.default)("@ember/-internals/glimmer");
    let emberApp = (0, _require.default)("@ember/application");
    let Application = emberApp.default;
    let {
      hasTemplate,
      setTemplate
    } = emberGlimmer;
    let {
      hasDOM
    } = emberEnv;
    Application.initializer({
      name: 'domTemplates',

      initialize() {
        let context;

        if (hasDOM) {
          context = document;
        }

        (0, _bootstrap.default)({
          context,
          hasTemplate,
          setTemplate
        });
      }

    });
  }
});