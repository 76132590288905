define("global-admin/security/authentication/shibboleth/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c5Fi4hY0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"access\",\"enabled\"]]],null,{\"statements\":[[0,\"  \"],[15,\"shibboleth-configured\",[]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[15,\"shibboleth-config\",[]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":true}",
    "meta": {
      "moduleName": "global-admin/security/authentication/shibboleth/template.hbs"
    }
  });

  _exports.default = _default;
});