define("shared/components/advanced-section/component", ["exports", "shared/components/advanced-section/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    advanced: false,
    tagName: null,
    actions: {
      toggle: function toggle() {
        this.set('advanced', !this.get('advanced'));
      }
    }
  });

  _exports.default = _default;
});