define("shared/code-mirror/service", ["exports", "codemirror", "shared/utils/pipelineStep"], function (_exports, _codemirror, _pipelineStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this._instances = Object.create(null);
    },
    fromTextArea: function fromTextArea(id, textarea) {
      return this.registerInstance(id, _codemirror.default.fromTextArea(textarea));
    },
    instanceFor: function instanceFor(id) {
      return this._instances[id];
    },
    registerInstance: function registerInstance(id, instance) {
      this._instances[id] = instance;
      return instance;
    },
    signal: function signal(emitter, type) {
      for (var _len = arguments.length, values = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        values[_key - 2] = arguments[_key];
      }

      _codemirror.default.signal.apply(_codemirror.default, [emitter, type].concat(values));
    },
    unregisterInstance: function unregisterInstance(id) {
      delete this._instances[id];
    },
    getMatchedHint: function getMatchedHint(value, editor) {
      var hintAry = _pipelineStep.singleton.hintAry;
      var cur = editor.getCursor();
      var cursorPosition = cur.ch;
      var cursorValue = editor.getLine(cur.line).slice(0, cursorPosition);
      var matched = false;

      var _$valueIndex = cursorValue.lastIndexOf('$');

      var _$value = cursorValue.slice(_$valueIndex, cursorValue.length);

      var matchedArry = [];

      if (_$value) {
        for (var i = 0; i < hintAry.length; i++) {
          var item = hintAry[i]; // if matched on end

          if (item.indexOf(_$value) === 0) {
            matched = true;
            matchedArry.push(item);
          }
        }

        if (matched) {
          return {
            matchedArry: matchedArry,
            index: cursorPosition - _$valueIndex
          };
        }
      }

      return {
        matchedArry: [],
        index: -1
      };
    }
  });

  _exports.default = _default;
});