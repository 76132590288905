define("shared/helpers/default-str", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.defaultStr = defaultStr;
  _exports.default = void 0;

  function defaultStr(params, options) {
    var out = null;
    var i = 0;

    while (!out && i < params.length) {
      out = params[i];
      i++;
    }

    if (!out && options && options.default) {
      out = options.default;
    }

    return out;
  }

  var _default = Ember.Helper.helper(defaultStr);

  _exports.default = _default;
});