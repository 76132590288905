define("shared/components/metrics-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CVHjyqG0",
    "block": "{\"symbols\":[\"parent\",\"&default\"],\"statements\":[[4,\"accordion-list-item\",null,[[\"grafanaUrl\",\"title\",\"detail\",\"expandAll\",\"expand\",\"expanded\",\"componentName\"],[[23,[\"grafanaUrl\"]],[23,[\"title\"]],[27,\"t\",[\"metricsAction.description\"],null],[23,[\"expandAll\"]],[27,\"action\",[[22,0,[]],[23,[\"expandFn\"]]],null],[23,[\"expanded\"]],\"sortable-table\"]],{\"statements\":[[4,\"if\",[[27,\"or\",[[23,[\"expanded\"]],[23,[\"expandAll\"]]],null]],null,{\"statements\":[[0,\"    \"],[14,2,[[22,0,[]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/metrics-summary/template.hbs"
    }
  });

  _exports.default = _default;
});