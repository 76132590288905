define("shared/components/schema/input-boolean/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kfNNaPY5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[11,\"class\",\"mr-20 hand\"],[9],[0,\"\\n  \"],[1,[27,\"radio-button\",null,[[\"selection\",\"value\"],[[23,[\"value\"]],true]]],false],[0,\" \"],[1,[27,\"t\",[\"schema.inputBoolean.y\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"label\"],[11,\"class\",\"hand\"],[9],[0,\"\\n  \"],[1,[27,\"radio-button\",null,[[\"selection\",\"value\"],[[23,[\"value\"]],false]]],false],[0,\" \"],[1,[27,\"t\",[\"schema.inputBoolean.n\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/schema/input-boolean/template.hbs"
    }
  });

  _exports.default = _default;
});