define("shared/components/storage-class/provisioner-storageos/component", ["exports", "shared/components/storage-class/provisioner-storageos/template", "shared/mixins/storage-class-provisioner"], function (_exports, _template, _storageClassProvisioner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FIELDS = ['pool', 'description', 'fsType', 'adminSecretNamespace', 'adminSecretName'];

  var _default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,
    provisioner: 'storageos',
    fields: FIELDS
  });

  _exports.default = _default;
});