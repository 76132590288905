define('ember-math-helpers/helpers/atanh', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.atanh = atanh;
  function atanh(params) {
    return Math.atanh(params[0]);
  }

  exports.default = Ember.Helper.helper(atanh);
});