enifed("@ember/-internals/meta/index", ["exports", "@ember/-internals/meta/lib/meta"], function (_exports, _meta) {
  "use strict";

  Object.defineProperty(_exports, "counters", {
    enumerable: true,
    get: function () {
      return _meta.counters;
    }
  });
  Object.defineProperty(_exports, "deleteMeta", {
    enumerable: true,
    get: function () {
      return _meta.deleteMeta;
    }
  });
  Object.defineProperty(_exports, "descriptorFor", {
    enumerable: true,
    get: function () {
      return _meta.descriptorFor;
    }
  });
  Object.defineProperty(_exports, "isDescriptor", {
    enumerable: true,
    get: function () {
      return _meta.isDescriptor;
    }
  });
  Object.defineProperty(_exports, "Meta", {
    enumerable: true,
    get: function () {
      return _meta.Meta;
    }
  });
  Object.defineProperty(_exports, "meta", {
    enumerable: true,
    get: function () {
      return _meta.meta;
    }
  });
  Object.defineProperty(_exports, "peekMeta", {
    enumerable: true,
    get: function () {
      return _meta.peekMeta;
    }
  });
  Object.defineProperty(_exports, "setMeta", {
    enumerable: true,
    get: function () {
      return _meta.setMeta;
    }
  });
  Object.defineProperty(_exports, "UNDEFINED", {
    enumerable: true,
    get: function () {
      return _meta.UNDEFINED;
    }
  });
});