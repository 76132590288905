define("shared/components/schema/input-string/component", ["exports", "shared/components/schema/input-string/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    layout: _template.default,
    classNames: ['form-control'],
    type: 'text'
  });

  _exports.default = _default;
});