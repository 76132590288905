define("shared/components/schema/input-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CJjg9TQT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"conceal\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"input\",null,[[\"value\",\"classNames\"],[[23,[\"value\"]],\"form-control conceal\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"classNames\"],[\"password\",[23,[\"value\"]],\"form-control\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[7,\"span\"],[11,\"class\",\"input-group-btn\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn bg-primary\"],[11,\"type\",\"button\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"inputPassword.buttonText\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"generate\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/schema/input-password/template.hbs"
    }
  });

  _exports.default = _default;
});