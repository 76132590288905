define("shared/mixins/console", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    application: Ember.inject.controller(),
    queryParams: ['podId', 'kubernetes', 'windows', 'containerName'],
    instanceId: null,
    model: null,
    bootstrap: function () {
      if (this.get('application.isPopup')) {
        Ember.$('body').css('overflow', 'hidden');
      }
    }.on('init'),
    actions: {
      cancel: function cancel() {
        window.close();
      }
    }
  });

  _exports.default = _default;
});