define("shared/components/btn-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oZ50q2/w",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[11,\"class\",\"switch\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[23,[\"checked\"]]]]],false],[0,\"\\n  \"],[7,\"div\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shared/components/btn-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});