define("shared/components/cluster-template-question-row/component", ["exports", "shared/components/cluster-template-question-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TYPES = [{
    value: 'string'
  }, {
    value: 'multiline'
  }, {
    value: 'boolean'
  }, {
    value: 'int'
  }, {
    value: 'password'
  }];

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'tr',
    classNames: ['main-row'],
    questions: null,
    question: null,
    typeOptions: TYPES,
    actions: {
      removeQuestion: function removeQuestion() {
        this.removeQuestion(this.question);
      }
    },
    removeQuestion: function removeQuestion() {
      throw new Error('remove override action is required');
    }
  });

  _exports.default = _default;
});