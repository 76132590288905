define("shared/linode/service", ["exports", "@rancher/ember-api-store/utils/fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LINODE_API = 'api.linode.com/v4';

  var _default = Ember.Service.extend({
    app: Ember.inject.service(),
    request: function request() {
      var auth = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var command = arguments.length > 1 ? arguments[1] : undefined;
      var url = "".concat(Ember.get(this, 'app.proxyEndpoint'), "/");
      var token = Ember.get(auth, 'token');
      var headers = {
        'Accept': 'application/json'
      };

      if (Ember.get(auth, 'type') === 'cloud') {
        Ember.set(headers, 'x-api-cattleauth-header', "Bearer credID=".concat(token, " passwordField=token"));
      } else {
        Ember.set(headers, 'X-Api-Auth-Header', "Bearer ".concat(token));
      }

      url += "".concat(LINODE_API, "/").concat(command);
      return (0, _fetch.default)(url, {
        headers: headers
      }).then(function (res) {
        return res.body;
      }).catch(function (err) {
        return Ember.RSVP.reject(err);
      });
    }
  });

  _exports.default = _default;
});