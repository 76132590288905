define("shared/components/storage-class/provisioner-azure-file/component", ["exports", "shared/components/storage-class/provisioner-azure-file/template", "shared/mixins/storage-class-provisioner"], function (_exports, _template, _storageClassProvisioner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FIELDS = ['skuName', 'location', 'storageAccount'];

  var _default = Ember.Component.extend(_storageClassProvisioner.default, {
    layout: _template.default,
    provisioner: 'azure-file',
    fields: FIELDS
  });

  _exports.default = _default;
});