define("shared/utils/intl/missing-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = missingMessage;

  function missingMessage(key, locale) {
    if (key) {
      locale = locale || 'unknown';

      if (locale !== 'none') {
        console.warn("Translation not found: locale='".concat(locale, "', key='").concat(key, "'."));
      }

      return "*%".concat(key, "%*");
    } else {
      return '';
    }
  }
});